(function(angular, app) {
	'use strict';

	app.service('ES', [
		function() {
			var self = this;

			self.id = 3;
			self.culture = 'es';
			self.title = 'Español';
			self.label = 'Spanish';
			self.short = 'Esp';
			self.direction = 'ltr';
			self.icon = 'https://d226b0iufwcjmj.cloudfront.net/localization/es.png';
			self.dateFormat = {
				date: 'MM/dd/yyyy',
				time: 'hh:mm a',
				dateAndTime: 'MM/dd/yyyy' + ' ' + 'hh:mm a'
			};
			self.translate = {
				'arabic': 'Arábica',
				'english': 'Inglés',
				'spanish': 'Española',
				'french': 'Francés',
				'hebrew': 'Hebreo',
				'russian': 'Ruso',
				'left': 'Izquierda',
				'right': 'Derecha',
				'up': 'Arriba',
				'down': 'Abajo',
				'search': 'Buscar',
				'add to cart': 'Añadir al carrito',
				'add to current cart': 'Añadir a este carrito',
				'create new order': 'Nueva orden',
				'cannot add to cart': 'No es posible añadir al carrito',
				'add cart products': 'Añadir productos al carrito',
				'special products': 'Productos en oferta',
				'save to shopping list': 'Agregar a una lista',
				'new list name': 'Nombre de la nueva lista',
				'home': 'Inicio',
				'powered by': 'Impulsado por',
				'remove': 'Eliminar',
				'login': 'Iniciar sesión',
				'email': 'Correo electrónico',
				'password': 'Contraseña',
				'logout': 'Cerrar sesión',
				'orders history': 'Historial de pedidos',
				'recent orders': 'Órdenes recientes',
				'checkout': 'Finalizar',
				'clear cart': 'Vaciar carrito',
				'callcustomer': 'Llamar al cliente',
				'creditcard': 'Tarjeta de crédito',
				'storecredit': 'Crédito de la tienda',
				'the delivery time is no longer available': 'este horario de entrega no esta disponible',
				'add card': 'Agregar tarjeta',
				'activate card': 'Activar tarjeta',
				'add another card': 'Agregar otra tarjeta',
				'order update': 'Actualización del pedido',
				'updated order value': 'Valor del pedido actualizado',
				'(estimated)': '(estimado)',
				'in order to complete the order update, please choose the payment method': 'Para completar la actualización del pedido, por favor, elija un método de pago',
				'confirm update': 'CONFIRMAR ACTUALIZACIÓN',
				'add another account': 'Agregar otra cuenta',
				'remove card': 'Eliminar tarjeta',
				'pay with': 'Pagar con',
				'pay with credit card': 'Pagar con tarjeta de credito',
				'remove account': 'Quitar cuenta',
				'credit card number': 'Número de tarjeta de crédito',
				'cvv': 'cvv',
				'delivery address': 'Dirección de envío',
				'city': 'Ciudad',
				'street': 'Calle',
				'phone num': 'Número de teléfono',
				'recipient\'s name': 'Nombre del destinatario',
				'floor / apartment': 'Piso / apartamento',
				'house number': 'Número de casa',
				'house no.': 'Número de casa',
				'available days': 'Días diponibles',
				'entry: spautocompleterequirematch error': 'Valor invalido',
				'house_entry_none': 'NO',
				'house_entry_a': 'a',
				'house_entry_b': 'b',
				'house_entry_c': 'c',
				'house_entry_d': 'd',
				'house_entry_e': 'e',
				'house_entry_f': 'f',
				'house_entry_g': 'g',
				'house_entry_h': 'h',
				'house_entry_i': 'i',
				'house_entry_j': 'j',
				'delivery time': 'Fecha de envío',
				'pickup time': 'Hora de recogida',
				'today': 'Hoy',
				'sunday': 'Domingo',
				'monday': 'Lunes',
				'tuesday': 'Martes',
				'wednesday': 'Miércoles',
				'thursday': 'Jueves',
				'friday': 'Viernes',
				'saturday': 'Sábado',
				'sun': 'Dom',
				'mon': 'Lun',
				'tue': 'Mar',
				'wed': 'Mie',
				'thu': 'Jue',
				'fri': 'Vie',
				'notes': 'Notas',
				'add instructions': 'Agregar instrucciones',
				'summary': 'Resumen',
				'products': 'Productos',
				'you saved': 'Usted ahorró',
				'payment': 'Pago',
				'delivery charge': 'Gastos de envío',
				'price without discount': 'Precio sin descuento',
				'price after discount': 'Precio después del descuento',
				'total': 'Total',
				'between': 'Entre',
				'order id': 'Id del pedido',
				'your order has been received': 'Su pedido ha sido recibido',
				'shopping lists': 'Lista de compras',
				'shopping list created': 'Lista de compras creada',
				'forgot password': '¿Olvidó su contraseña?',
				'register': 'Registro',
				'retailer details': 'Detalles de la tienda',
				'in all departments': 'En todos los departamentos',
				'edit details': 'Editar detalles',
				'loading': 'Cargando',
				'supervision': 'Supervision',
				'load previous orders': 'Cargar órdenes anteriores',
				'close previous orders': 'Cerrar pedidos anteriores',
				'previous orders': 'Pedidos anteriores',
				'number of provided products' : 'Número de productos proporcionados',
				'substitutions':'Sustituciones',
				'payment method ending with':'Método de pago que termina con',
				'credit card charge':'Cobrar con tarjeta de crédito',
				'order time':'Tiempo de pedido',
				'replaced with':'Sustituido por',
				'new product':'Producto nuevo',
				'payment method':'Metodo de pag',
				'call customer':'Llama al cliente',
				'received_items': 'Recibido',
				'total (estimated)': 'Total(estimado)',
				'view details': 'Ver detalles',
				'sale': 'Ofertas',
				'club': 'Cliente Frecuente',
				'club sale': 'Descuento por cliente frecuente',
				'open sale details': 'Mostrar detalles de la oferta',
				'choose brand': 'Elegir la marca',
				'specials_and_benefits_title': 'Ofertas',
				'specials': 'Ofertas',
				'go to specials': 'Ir a ofertas',
				'item': 'Artículo',
				'items': 'Artículos',
				'hide cart': 'Ocultar carrito',
				'best ingredients for best results': 'Los mejores ingredientes para el mejor resultado',
				'order items by': 'Ordenar artículos por',
				'name': 'Nombre',
				'actions': 'Acciones',
				'creation date': 'Fecha de creación',
				'delete': 'Borrar',
				'create new shopping list': 'Crear nueva lista de compras',
				'shopping list name': 'Nombre de la lista de compras',
				'create': 'Crear',
				'id': 'Id',
				'time': 'Hora',
				'date and time': 'Fecha y hora',
				'ok': 'Ok',
				'address': 'Dirección',
				'phone': 'Teléfono',
				'the site is undergoing maintenance': 'El sitio está en mantenimiento',
				'please try again later': 'Por favor intente de nuevo más tarde',
				'the site is active only for registered users, please login': 'El sitio está activo sólo para usuarios registrados, por favor inicie sesión',
				'the site is disabled': 'El sitio está desactivado',
				'comment': 'Comentario',
				'noun search': 'Buscar',
				'finish order': 'Finalizar pedido',
				'reset password': 'Restablecer contraseña',
				'mobile': 'Teléfono móvil',
				'mobile phone': 'Teléfono móvil',
				'phone number': 'Número de teléfono',
				'edit': 'Editar',
				'add phone': 'Agregar teléfono',
				'first name': 'Nombre',
				'last name': 'Apellido',
				'birth date': 'Fecha de nacimiento',
				'user name and password': 'Usuario y contraseña',
				'user name': 'Usuario',
				'gender': 'Sexo',
				'male': 'Masculino',
				'female': 'Femenino',
				'prefer not to say': 'Prefiero no decirlo',
				'personal information': 'Información personal',
				'user details': 'Detalles del usuario',
				'contact details': 'Detalles de contacto',
				'comments': 'Comentarios',
				'work': 'Trabajo',
				'allow to send promotions': 'Permitir el envío de promociones',
				'my items': 'Mis artículos',
				'image': 'Imagen',
				'price': 'Precio',
				'quantity': 'Cantidad',
				'actions on marked items': 'Acciones sobre artículos marcados',
				'create new cart': 'Crear un nuevo carrito de compras',
				'create shopping list': 'Crear lista de compras',
				'no products were selected': 'Ningún producto ha sido seleccionado',
				'total sum': 'Total',
				'order status': 'Estado del pedido',
				'new': 'Nuevo',
				'received': 'Recibido',
				'charged': 'Cobrado',
				'delivered': 'Entregado',
				'cancelled': 'Cancelado',
				'suspended': 'Suspendido',
				'processing': 'En proceso',
				'waiting_for_confirmation': 'En proceso',
				'user_confirmed': 'En proceso',
				'collected': 'Recogido',
				'registered': 'Registrado',
				'received text': 'Su orden ha sido recibida pero no cobrada',
				'charged text': 'Se ha cobrado a su tarjeta de crédito por su compra.',
				'delivered text': 'Su orden fue enviada y está en camino ',
				'cancelled text': 'La orden fue cancelada y ya no está en proceso',
				'suspended text': 'No se pudo completar la orden, la intervención del usuario es requerida',
				'processing text': 'Se está procesando su orden.',
				'collected text': 'Los artículos fueron recogidos pero no fueron registrados en el POS',
				'registered text': 'Los productos fueron recogidos y escaneados, pero la transacción no está completa todavía',
				'no products were found': 'No se encontraron productos',
				'order number': 'Número de pedido',
				'date': 'Fecha',
				'back': 'Regresar',
				'<< back': '<< Volver',
				'save': 'Guardar',
				'shopping list': 'Lista de compras',
				'address 1': 'Dirección 1',
				'address 2': 'Dirección 2',
				'i confirm that i have read the ': 'Confirmo que he leído los ',
				'terms': 'Términos',
				'similar products': 'Productos similares',
				'check all': 'Marcar todo',
				'uncheck all': 'Desmarcar todo todo',
				'all': 'Todo',
				'narrow your choice': 'Afine su elección',
				'brand': 'Marca',
				'type': 'Tipo',
				'category': 'Categoría',
				'sign in': 'Inicie sesión',
				'sign in using facebook': 'Inicie sesión usando Facebook',
				'it\'s easy': 'Es fácil',
				'please sign in to continue...': 'Por favor inicie sesión para continuar...',
				'country': 'País',
				'at': 'a',
				'let\'s start': 'Comencemos',
				'adding some items': 'Añada algunos artículos',
				'it\'s easy!': 'es fácil!',
				'forgot password?': '¿Olvidó su contraseña?',
				'confirm password': 'Confirmar contraseña',
				'i agree to the': 'Acepto los ',
				'terms & conditions': 'términos y condiciones',
				'policy_end': '',
				'please let me know about promotions': 'Por favor, notifíquese de las promociones',
				'reset': 'Restablecer',
				'please check your email for the password reset information': 'Por favor, verifique su correo electrónico para restablecer su contraseña',
				'please check your inbox and follow the instructions': 'Por favor revise su bandeja de entrada y siga las instrucciones',
				'if you haven\'t received an email, please check if you mistyped your email address or contact customer service': 'Si no ha recibido un correo electrónico, verifique si escribió mal su dirección de correo electrónico o comuníquese con el servicio al cliente',
				'open previous carts:': 'Abrir carritos de compra anteriores:',
				'save and sign in': 'Guardar e iniciara sesión',
				'save for later': 'Guardar para más tarde',
				'today\'s special deals': 'Ofertas especiales para hoy',
				'no': 'No',
				'yes': 'Si',
				'delivery': 'Envío',
				'pickup': 'Entrega',
				'pick and go': 'Recoger y llevar',
				'pick & go': 'Recoger y llevar',
				'undefined': 'Indefinido',
				'anything else': 'Algo más',
				'delivery date': 'Fecha de envío',
				'pickup date': 'Fecha de entrega',
				'estimated total': 'Total estimado',
				'estimated': 'Estimado',
				'allow substitutions': 'Permite cambios',
				'delivery date and time': 'Fecha y hora de entrega',
				'next': 'Siguiente',
				'contact details & payment': 'Detalles de contacto y pago',
				'checkout contact details': 'Verifique los detalles de contacto',
				'summary & payment': 'Resumen y pago',
				'delivery/pickup preferences': 'Preferencias de envío y entrega',
				'how do you want to get your order?': '¿Cómo desea obtener su pedido?',
				'the site is active only for registered users': 'La página está activa sólo para usuarios registrados',
				'please login': 'Por favor inicie sesión',
				'sort by': 'Ordenar por',
				'save as a shopping list': 'Guardar como una lista de la compras',
				'your shopping cart': 'Su carrito de compras',
				'save to list': 'Guardar la lista',
				'product name': 'Nombre del producto',
				'order ': 'Pedido',
				'order': 'Orden',
				'unit price': 'Precio por unidad',
				'units': 'Unidades',
				'unit': 'Unidad',
				'proceed to checkout': 'Pagar',
				'est.': 'Estimado',
				'est. total weight': 'Peso total estimado',
				'est. weight': 'Peso estimado',
				'mobile: required error': 'Por favor ingrese su número de teléfono móvil',
				'mobilephone: required error': 'Por favor ingrese su número de teléfono móvil',
				'phone: required error': 'Por favor ingrese su número de telefono',
				'phone: minlength error': 'El teléfono debe incluir por lo menos 7 digitos',
				'city: required error': 'Por favor ingrese su ciudad',
				'city: spautocompleterequirematch error': 'Ciudad no encontrada',
				'cityinput: spautocompleterequirematch error': 'Ciudad no encontrada',
				'street: required error': 'Por favor ingrese su calle',
				'street: spautocompleterequirematch error': 'Calle no encontrada',
				'streetinput: spautocompleterequirematch error': 'Calle no encontrada',
				'housenum: required error': 'Ingrese su número de casa',
				'housenum: min error': 'Ingrese su número de casa',
				'text1: required error': 'Por favor ingrese su dirección',
				'addresstext: required error': 'Por favor ingrese su dirección',
				'fullname: required error': 'Por favor ingrese su nombre completo',
				'email: required error': 'Por favor ingrese su correo electrónico',
				'email: email error': 'Por favor ingrese una dirección de correo electrónico valida',
				'firstname: required error': 'Por favor ingrese su nombre',
				'lastname: required error': 'Por favor ingrese su apellido',
				'password: required error': 'Por favor ingrese su contraseña',
				'password: minlength error': 'La contraseña debe incluir 6 caracteres',
				'password: maxlength error': 'La contraseña no debe ser mayor a 30 caracteres',
				'password: pattern error': 'La contraseña debe contener al menos un dígito numérico y un carácter',
				'confirmpassword: required error': 'Por favor reingrese su contraseña',
				'confirm_password: required error': 'Por favor reingrese su contraseña',
				'policyapproval: required error': 'Por favor acepte los términos y condiciones',
                'please confirm that you accept the terms and conditions for using this site': 'Por favor, confirme que acepta los términos y condiciones para el uso de este sitio',
				'zipcode: required error': 'Por favor ingrese su código postal',
				'message: required error': 'Por favor ingrese algún mensaje',
				'loyaltycardid: required error': 'Por favor ingreso su numero de cliente frecuente',
				'ValidationKey: required error': 'Por favor ingrese su numero de identificacion',
				'birthdate: date error': 'Por favor ingrese su fecha de nacimiento',
				'confirmpassword: passwords do not match error': 'La clave debe ser igual',
				'confirm_password: Passwords do not match error': 'La clave debe ser igual',
				'confirmPassword: pattern error': 'Clave incorrecta',
				'confirm_password: pattern error': 'Clave incorrecta',
				'floor: step error': 'Ingrese un numero',
				'please enter an amount': 'Por favor, introduzca un monto',
				'select order': 'Seleccione su pedido',
				'create new list': 'Crear nueva lista',
				'my account': 'Mi cuenta',
				'continue shopping': 'Seguir comprando',
				'homepagenavigation': 'Página principal',
				'add comments': 'Agregar comentarios',
				'add comment': 'Agregar comentario',
				'what happens if something i ordered is not available?': '¿Qué ocurre si algo de lo solicitado no está disponible?',
				'read more': 'Leer más',
				'read less': 'Leer menos',
				'substitute_preference_1_label': 'Permitir',
				'substitute_preference_1_description': 'Remplazar el producto que falta sin contactarle',
				'substitute_preference_2_label': 'Llamame',
				'substitute_preference_2_description': 'Lo vamos a llamar antes de replazar el producto que falta',
				'substitute_preference_3_label': 'No permitir',
				'substitute_preference_3_description': 'No vamos a remplazar el producto faltante',
				'substitute_preference_4_label': 'Enviar mensaje',
				'substitute_preference_4_description': 'Le enviaremos un mensaje antes de reemplazar un producto',
				'substitute_preference_5_label': 'Enviar WhatsApp',
				'substitute_preference_5_description': 'Le enviaremos un WhatsApp mensaje antes de reemplazar un producto',
				'substitute_preference__label': 'para prevenir errores',
				'substitute_preference__description': 'Para prevenir errores',
				'zip code': 'Código postal',
				'zip': 'Código postal',
				'full name': 'Nombre completo',
				'payment details': 'Detalles de pago',
				'your order will be delivered': 'Su pedido será enviado',
				'your order will be ready for pickup': 'Su pedido estará listo para ser enviado',
				'your order will be delivered in': 'Su pedido será enviado en',
				'your order will be ready for pickup in': 'Su pedido estará listo para recojer',
				'substitution choice': 'Opción de cambio',
				'address line 1': 'Dirección L1',
				'add another address line': 'Añada otra línea de dirección',
				'address line 2': 'Dirección L2',
				'remove another address': 'Elimine otra dirección',
				'almost there!': '¡Casi llegamos…!',
				'send the delivery sooner, if possible': 'Si es posible, enviar mas temprano',
				'prepare the order sooner, if possible': 'Si es posible, preparar mas temprano',
				'sooner if possible': 'De ser posible, mas temprano',
				'checkout summary': 'Resumen de pago',
				'only': 'Solo',
				'day': 'Día',
				'free': 'Gratis',
				'total save': 'Ahorro total',
				'place order': 'Confirmar pedido',
				'your email or password seems to be wrong': 'Su correo electrónico o contraseña parecen estar errados',
				'please try again': 'Inténtelo de nuevo',
				'oops': 'Ups',
				'null': 'nulo',
				'select': 'Seleccionar',
				'show previous orders': 'Mostrar pedido anterior',
				'hide previous orders': 'Ocultar pedido anterior',
				'choose list': 'Escoger lista',
				'choose order': 'Escoger pedido',
				'choose shopping list': 'Escoger lista de compras',
				'we couldn\'t create your shopping list': 'No hemos podido crear su lista de compras',
				'list name': 'Nombre de lista',
				'create list': 'Crear lista',
				'floor': 'Piso',
				'apartment': 'Apartamento',
				'close details': 'Cerrar detalles',
				'we just sent you an order confirmation by email': 'Acabamos de enviarte una confirmación de pedido al correo electrónico',
				'thank you for shopping at': 'Gracias por comprar en',
				'prutah_checkout_finish_thank_you_line_1': 'Gracias por comprar en',
				'prutah_checkout_finish_thank_you_line_2': '',
				'please add your payment card': 'Por favor agregue su tarjeta de pago',
				'my orders': 'Mis pedidos',
				'hours': 'Horas',
				'ready for pickup': 'Listo para recoger',
				'we are sorry, but the following products are out of stock': 'Lo sentimos, pero los siguientes productos están agotados',
				'remove from cart & continue': 'Eliminar del carrito y continuar',
				'you save': 'Usted ahorra',
				'can\'t find item?': '¿No puede encontrar el artículo?',
				'can\'t find an item?': '¿No puede encontrar el artículo?',
				'tax': 'Impuesto',
				'unknown': 'Desconocido',
				'other': 'Otro',
				'add it here': 'Añadir aquí',
				'choose category': 'Elegir categoría',
				'if we are unfamiliar with the product or don\'t have it in stock, we will give you a call': 'Si no estamos conocemos el producto o está agotado, lo llamaremos',
				'store info': 'Información de la tienda',
				'stores info': 'Informacion de las tiendas',
				'store-details': 'Detalles de la tienda',
				'store location': 'Ubicación de la tienda',
				'price high-low': 'Precios de mayor a menor',
				'price low-high': 'Precios de menor a mayor',
				'my shopping lists': 'Mis listas de compras',
				'remove shopping list': 'Eliminar listas de compras',
				'are you sure you want to remove the shopping list': '¿Está seguro de querer eliminar las listas de compras?',
				'removed': 'Eliminar',
				'add to shopping list': 'Añadir a la lista de compras',
				'payments number': 'Número de pagos',
				'cvv: required error': 'cvv: error de requerimiento',
				'enter cvv': 'ingresar CVV',
				'cvv: please enter cvv error': 'Por favor ingrese su cvv',
				'cvv: minlength error': 'cvv: error de longitud',
				'cancel': 'Cancelar',
				'continue with this cart': 'Continuar con este carrito de compras',
				'merge carts': 'Carritos de compras fusionados',
				'would you like to merge the two carts or continue using this cart': 'Le gustaría fusionar los dos carritos o continuar usando este carrito',
				'somewhere else': 'Algún otro lugar',
				'you have an open cart with': 'Usted tiene un carrito abierto con',
				'total weight': 'Peso total',
				'account info': 'Información de cuenta',
				'orders': 'Pedidos',
				'payment methods': 'Métodos de pago',
				'coupons/discounts': 'Cupones / Descuentos',
				'save changes': 'Guardar cambios',
				'credit cards': 'Tarjetas de crédito',
				'paypal accounts': 'Cuenta de PayPal',
				'shopping list named': 'Lista de compras nombrada',
				'your account info has been successfully updated': 'La información de su cuenta ha sido actualizada con éxito',
				'was successfully created with': 'Fue creado exitosamente con',
				'items successfully added': 'Artículos añadidos correctamente',
				'items were successfully added to shopping list': 'Los artículos fueron añadidos correctamente a la lista de compras',
				'item was successfully added to shopping list': 'El artículo fue añadido correctamente a la lista de compras',
				'items were not added to shopping list': 'Los artículos no fueron añadidos a la lista de compras',
				'item were not added to shopping list': 'El artículo no fue añadido a la lista de compras',
				'saved in': 'Guardado en',
				'we are not able to add the following products to your current order': 'No hemos podido añadir los siguientes productos a su pedido actual',
				'opening hours': 'Horario de apertura',
				'additional': 'Adicional',
				'area map': 'Mapa del área',
				'contact us': 'Contáctenos',
				'store information': 'Informacion de la tienda',
				'how can we help you today': '¿En qué podemos ayudarle el día de hoy',
				'how can we help you': '¿En qué podemos ayudarle',
				'send': 'Enviar',
				'sent successfully': 'Enviado correctamente',
				'successfully updated': 'Actualizado correctamente',
				'successful update': 'Actualizacion correcta',
				'shopping list was successfully updated': 'La lista de compras fue correctamente actualizada',
				'service provider blocker': 'Proveedor de servicios bloqueado',
				'your service provider is blocking some of the website\'s functionality': 'Su proveedor de servicios está bloqueando algunas de las funciones de la página web',
				'once we complete picking your order, we adjust your final charge based on weight adjustments, substitutions & out of stock items': 'Cuando terminemos de trabajar su pedido ajustaremos el monto total en base a los pesos y articulos encontrados',
				'please contact your service provider': 'Por favor, contacte a su proveedor de servicios',
				'close': 'Cerrado',
				'passwords do not match': 'Las contraseñas no coinciden',
				'send reset code': 'Enviar código de reinicio',
				'are you sure you want to clear your cart': '¿Está seguro de querer vaciar el carrito de compras?',
				'e-mail': 'Correo electrónico',
				'your message has been sent': 'Su mensaje ha sido enviado',
				'change zip code': 'Cambiar código postal',
				'change area': 'Cambiar área',
				'hey there': 'Hola',
				'let\'s make sure we\'re available in your area': 'Verificando si hacemos envios a su area',
				'please enter your zip code': 'Por favor, ingrese su código postal',
				'please enter your area': 'Por favor, ingrese su área',
				'area: required error': 'Por favor ingrese su área',
				'the area you entered, is served by this store': 'El área que ha introducido, es atendida por esta tienda',
				'pickup service only': 'Solo servicio de recoger en la tienda',
				'we serve your area': 'Hacemos envios a su area',
				'delivery method in this area': 'Formas de envio en este area',
				'check': 'Comprobar',
				'for ': 'Para ',
				'we offer': 'Ofrecemos',
				'both': 'Ambos',
				'and': 'y',
				'and the': 'y la',
				'pick up': 'Recoger',
				'options': 'Opciones',
				'your store will be': 'Su tienda será',
				'by changing your zip code you will lose your shopping cart': 'Al cambiar su código postal usted perderá su carrito de compras',
				'by changing your area you will lose your shopping cart': 'Al cambiar su área perderá los elementos en su carrito de compras',
				'go back': 'Regresar',
				'don\'t change zip code': 'No cambiar el código postal',
				'don\'t change area': 'No cambiar el área',
				'currently we do not deliver to your area': 'Actualmente no hacemos envíos a su área',
				'change store': 'Cambiar de tienda',
				'go to store': 'Ir a la tienda',
				'the zipcode you entered, is served by this store': 'El código postal que ha introducido, es atendido por esta tienda',
				'next delivery regular': 'Siguiente envío regular',
				'thank you for contacting us': 'Gracias por contactarnos',
				'delivery preferences': 'Preferencias de envío',
				'pickup preferences': 'Preferencias de entrega',
				'privacy policy': 'Políticas de privacidad',
				'about us': 'Sobre nosotros',
				'not active': 'Inactivo',
				'case of': 'En caso de',
				'case': 'Caso',
				'single': 'Individual',
				'ordersfromposfieldtitle0': 'Por favor ingrese su número de teléfono móvil',
				'ordersfromposfielddescription0': 'Le enviaremos un mensaje de texto de confirmación a su teléfono móvil',
				'phonenumber: required error': 'Por favor ingrese su número de telefono',
				'orderfromposcodesenttitle0': 'Enviamos un mensajes con un código de verificación a <%value%>',
				'confirmcode: required error': 'Por favor ingrese el código de verificación',
				'hi': 'Hola',
				'entrance': 'Entrada',
				'faq': 'Preguntas más frecuentes',
				'new password': 'Nueva contraseña',
				'resetnewpassword: required error': 'Por favor ingrese una nueva contraseña',
				'resetconfirmpassword: required error': 'Please enter a password confirmation',
				'resetconfirmpassword: passwords do not match error': 'Por favor confirme la nueva contraseña',
				'resetnewpassword: minlength error': 'La nueva contraseña debe incluir al menos 6 dígitos',
				'floor: not_editable error': 'No puede editar este campo antes de ingresar un código postal para su dirección',
				'floor: not_editable_locked error': 'Para cambiar la dirección, elija de nuevo de la lista de códigos postales',
				'floor: not_editable_locked_empty error': 'Ingrese su código postal y elija su dirección de la lista',
				'text1: not_editable error': 'No puede editar este campo antes de ingresar un código postal para su dirección',
				'text1: not_editable_locked error': 'Para cambiar la dirección, elija de nuevo de la lista de códigos postales',
				'text1: not_editable_locked_empty error': 'Ingrese su código postal y elija su dirección de la lista',
				'text2: not_editable error': 'No puede editar este campo antes de ingresar un código postal para su dirección',
				'text2: not_editable_locked error': 'Para cambiar la dirección, elija de nuevo de la lista de códigos postales',
				'text2: not_editable_locked_empty error': 'Ingrese su código postal y elija su dirección de la lista',
				'street: not_editable error': 'No puede editar este campo antes de ingresar un código postal para su dirección',
				'street: not_editable_locked error': 'Para cambiar la dirección, elija de nuevo de la lista de códigos postales',
				'street: not_editable_locked_empty error': 'Ingrese su código postal y elija su dirección de la lista',
				'cityinput: not_editable error': 'No puede editar este campo antes de ingresar un código postal para su dirección',
				'cityinput: not_editable_locked error': 'Para cambiar la dirección, elija de nuevo de la lista de códigos postales',
				'cityinput: not_editable_locked_empty error': 'Ingrese su código postal y elija su dirección de la lista',
				'city: not_editable error': 'No puede editar este campo antes de ingresar un código postal para su dirección',
				'city: not_editable_locked error': 'Para cambiar la dirección, elija de nuevo de la lista de códigos postales',
				'city: not_editable_locked_empty error': 'Ingrese su código postal y elija su dirección de la lista',
				'countryinput: not_editable error': 'No puede editar este campo antes de ingresar un código postal para su dirección',
				'countryinput: not_editable_locked error': 'Para cambiar la dirección, elija de nuevo de la lista de códigos postales',
				'countryinput: not_editable_locked_empty error': 'Ingrese su código postal y elija su dirección de la lista',
				'housenum: not_editable error': 'No puede editar este campo antes de ingresar un código postal para su dirección',
				'housenum: not_editable_locked error': 'Para cambiar la dirección, elija de nuevo de la lista de códigos postales',
				'housenum: not_editable_locked_empty error': 'Ingrese su código postal y elija su dirección de la lista',
				'entry: not_editable error': 'No puede editar este campo antes de ingresar un código postal para su dirección',
				'entry: not_editable_locked error': 'Para cambiar la dirección, elija de nuevo de la lista de códigos postales',
				'entry: not_editable_locked_empty error': 'Ingrese su código postal y elija su dirección de la lista',
				'entrance: not_editable error': 'No puede editar este campo antes de ingresar un código postal para su dirección',
				'entrance: not_editable_locked error': 'Para cambiar la dirección, elija de nuevo de la lista de códigos postales',
				'entrance: not_editable_locked_empty error': 'Ingrese su código postal y elija su dirección de la lista',
				'apartment: not_editable error': 'No puede editar este campo antes de ingresar un código postal para su dirección',
				'apartment: not_editable_locked error': 'Para cambiar la dirección, elija de nuevo de la lista de códigos postales',
				'apartment: not_editable_locked_empty error': 'Ingrese su código postal y elija su dirección de la lista',
				'apartmentinput: not_editable error': 'No puede editar este campo antes de ingresar un código postal para su dirección',
				'apartmentinput: not_editable_locked error': 'Para cambiar la dirección, elija de nuevo de la lista de códigos postales',
				'apartmentinput: not_editable_locked_empty error': 'Ingrese su código postal y elija su dirección de la lista',
				'address1input: not_editable error': 'No puede editar este campo antes de ingresar un código postal para su dirección',
				'address1input: not_editable_locked error': 'Para cambiar la dirección, elija de nuevo de la lista de códigos postales',
				'address1input: not_editable_locked_empty error': 'Ingrese su código postal y elija su dirección de la lista',
				'address2input: not_editable error': 'No puede editar este campo antes de ingresar un código postal para su dirección',
				'address2input: not_editable_locked error': 'Para cambiar la dirección, elija de nuevo de la lista de códigos postales',
				'address2input: not_editable_locked_empty error': 'Ingrese su código postal y elija su dirección de la lista',
				'comments: not_editable error': 'No puede editar este campo antes de ingresar un código postal para su dirección',
				'comments: not_editable_locked error': 'Para cambiar la dirección, elija de nuevo de la lista de códigos postales',
				'comments: not_editable_locked_empty error': 'Ingrese su código postal y elija su dirección de la lista',
				'zipcode: not_editable error': 'No puede editar este campo',
				'zipinput: not_editable error': 'No puede editar este campo',
				'zipcodeinput: not_editable error': 'No puede editar este campo',
				'once we collected your order, we will call to collect your payment': 'Una vez que recojamos su pedido, le llamaremos para que realice su pago',
				'choose how you\'d like to pay': 'Elegir la forma de pago',
				'your credit cards': 'Su tarjeta de credito',
				'your paypal accounts': 'Su cuenta de PayPal',
				'enter your credit on file': 'Ingrese su numero de cuenta de credito',
				'subtotal': 'Subtotal',
				'substitutions choice': 'Opciones de cambio',
				'let\'s change your password...': 'Cambiar su contraseña...',
				'you must agree to the terms': 'Debe estar de acuerdo con los terminos',
				'registration completed': 'Registro concluido',
				'registration is closed right now': 'Registro cerrado por el momento',
				'please enter cvv': 'Por favor ingrese el cvv',
				'pre auth: declined invld acct': 'El CVV no es correcto, favor tratar nuevamente',
				'cvv is an anti-fraud security feature to help verify that you are in possession of your credit card': 'CVV es una función de seguridad para evitar fraudes, que ayudar a verificar que usted está en posesión de su tarjeta de crédito',
				'the three-digit cvv number is printed on the signature panel on the back of the card immediately after the card\'s account number': 'El CVV consta de tres dígitos impresos en el panel de firma, que está en el reverso de la tarjeta, inmediatamente después del número de cuenta',
				'select location': 'Seleccionar ubicación',
				'pickup location': 'Lugar de entrega',
				'day0': 'Dom',
				'day1': 'Lun',
				'day2': 'Mar',
				'day3': 'Mier',
				'day4': 'Jue',
				'day5': 'Vier',
				'day6': 'Sab',
				'week_day_0': 'Dom',
				'week_day_1': 'Lun',
				'week_day_2': 'Mar',
				'week_day_3': 'Mier',
				'week_day_4': 'Jue',
				'week_day_5': 'Vier',
				'week_day_6': 'Sab',
				'ticket_status_1': 'Nuevo',
				'ticket_status_2': 'Procesando',
				'ticket_status_3': 'Hecho',
				'ticket': 'Tickete',
				'by': 'por',
				'back to tickets': 'Volver a tickete',
				'me': 'yo',
				'status': 'Estado',
				'description': 'Descripción',
				'created date': 'Fecha de creación',
				'customer service': 'Servicio al usuario',
				'service': 'Servicio',
				'inquiry': 'Reclamo',
				'type in your comment': 'Escriba su comentario',
				'ticket id': 'Id del ticket',
				'no previous inquiries': 'No hay reclamos anteriores',
				'we didn\'t recognize the address you entered': 'No podemos reconocer la dirección que usted ha ingresado',
				'sorry, currently we do not deliver to this area.': 'Disculpe, actualmente no hacemos envios a ese area',
				'sorry, currently we do not deliver to this area, for further inquiries call': 'Disculpe, actualmente no hacemos envios a esa área. Para otras consultas',
				'sorry, currently we do not deliver to this area, for further inquiries call our customer service': 'Disculpe, actualmente no hacemos envíos a esa área. Para otras consultas comuníquese con atención al cliente',
				'sorry, branch change required': 'Disculpe, se requiere un cambio de sucursal',
				'this address could only be delivered from branch': 'A su ubicacion solo se hacen envios desde la sucursal',
				'try another address or choose pickup option': 'Intente otra dirección o cambie la opción de entrega',
				'in': 'en',
				'hrs': 'hrs',
				'review your order and process payment': 'Verifique su pedido y procese el pago',
				'what did you mean': '¿Qué quizo decir?',
				'please choose delivery time': 'Por favor seleccione el tiempo de envio',
				'please choose pickup time': 'Por favor seleccione la hora de recogida',
				'delivery details': 'Detalles de envío',
				'your details': 'Sus detalles',
				'pickup details': 'Detalles de recogida',
				'street number and street name': 'Nombre y número de la calle',
				'entrance, apartment, suite, building, floor, etc.': 'Apartamento, edificio, piso',
				'apartment, suite, building, floor, etc.': 'Apartamento, suite, edificio, piso, etc.',
				'type letter': 'Ingrese letra',
				'add products': 'Añadir productos',
				'add': 'Añadir',
				'choose exist list': 'Seleccione lista existente',
				'clear all': 'Borrar todo',
				'or': 'o',
				'for': 'Para',
				'to ': 'Para ',
				'your order total {{areaname}}does not meet the {{minimum | currency}} minimum order total': 'El total de la orden {{areaname}} no cumple {{minimum | currency}} el minimo',
				'please add to your cart items worth {{difference | currency}} to proceed to checkout': 'Por favor agregar al carrito articulos por valor de {{difference | currency}} para procesar la orden',
				'not including items on sale': 'No incluye articulos en oferta',
				'delivery fee': 'Cargo por envio',
				'pickup fee': 'Cargo por retirar',
				'service fee': 'Cargo por servicio',
				'delivery cost': 'Costo de envío',
				'delivery cost tax': 'Impuestos de envío',
				'pickup times & areas': 'Horas y áreas de recogida',
				'choose your pickup time': 'Seleccione la hora de retiro',
				'delivery times': 'Horas de envío',
				'delivery times & areas': 'Horas y areas de envio',
				'delivery areas': 'Áreas de envío',
				'add your gift card': 'Añadir su tarjeta de regalo',
				'enter your gift card id': 'Ingrese el id de su tarjeta de regalo',
				'phone: pattern error': 'No es un teléfono valido',
				'phone: maxlength error': 'No es un teléfono valido',
				'phone: number error': 'No es un teléfono valido',
				'homephone: pattern error': 'No es un teléfono valido',
				'homephone: number error': 'No es un teléfono valido',
				'workphone: pattern error': 'No es un teléfono valido',
				'workphone: number error': 'No es un teléfono valido',
				'mobilephone: pattern error': 'No es un teléfono valido',
				'mobilephone: number error': 'No es un teléfono valido',
				'pay by phone': 'Pagar por teléfono',
				'get it within': 'Recibir en un plazo de',
				'next delivery': 'Siguiente envío',
				'next pickup': 'Siguiente recogida',
				'changes to your cart will be lost. are you sure you want to leave?': 'Los cambios en tu carrito de compras se perderán. ¿Estás seguro de que deseas salir?',
				'the following products were not saved correctly': 'Los siguientes productos no fueron guardados correctamente',
				'the following products will not be for sale at this given time': 'Los siguientes articulos no estaran en oferta en ese momento',
				'shoplistname: required error': 'Nombre de la lista de compras: error de requerimiento',
				'coupon number': 'Número de cupón',
				'delivery & pickup': 'Envío y entrega',
				'pickup times': 'Tiempo  de recogida',
				'store map': 'Mapa de la tienda',
				'none': 'Ninguno',
				'look for the express pickup icon': 'Busque el ícono de retiro express',
				'look for the express delivery icon': 'Busque el ícono de envió express',
				'invalid coupon code': 'El código del cupón no es válido',
				'we couldn\'t find a matching coupon code': 'No podemos encontrar un código de cupón',
				'where would you like to shop': '¿Dónde le gustaría comprar?',
				'drop files here to upload or click to browse...': 'Coloque los archivos aquí para cargarlos o haga clic para navegar...',
				'drag files or browse...': 'Arrastrar archivos o navegar',
				'attachments': 'Archivos adjuntos',
				'link': 'Enlace',
				'select the store closer to your home': 'Seleccione la tienda más cercana a su hogar',
				'for this store we offer': 'Para esta tienda ofrecemos',
				'buy from this store': 'Comprar en esta tienda',
				'deliveries': 'Envíos',
				'dear customer': 'Estimado usuario',
				'please notice': 'Por favor, observe',
				'pseudoproductname: Required error': 'Por favor ingrese el nombre del producto',
				'pseudoproductname: maxlength error': 'El nombre del producto no puede contener más de 200 caracteres',
				'area': 'área',
				'recipe': 'Receta',
				'steps': 'Pasos',
				'more info': 'Más información',
				'product description': 'Descripción del producto',
				'ingredients': 'Ingredientes',
				'more information': 'Más información',
				'directions': 'Instrucciones',
				'nutrition facts': 'Datos nutricionales',
				'nutrition values': 'Valores nutricionales',
				'serving size': 'Porciones',
				'servings per container': 'Porciones por envase',
				'amount per serving': 'Cantidad por porción',
				'calories': 'Calorías',
				'calories from fat': 'Calorías provenientes de grasa',
				'daily value': 'Valor diario',
				'total fat': 'Grasa total',
				'saturated fat': 'Grasas saturadas',
				'trans fat': 'Grasas trans',
				'cholesterol': 'Colesterol',
				'sodium': 'Sodio',
				'total carbohydrate': 'Total de carbohidratos',
				'dietary fiber': 'Fibra dietética',
				'sugars': 'Azúcar',
				'protein': 'Proteína',
				'vitamin a': 'Vitamina A',
				'vitamin c': 'Vitamina C',
				'calcium': 'Calcio',
				'iron': 'Hierro',
				'g': 'g',
				'monounsaturated fat': 'Grasas mono saturadas',
				'polyunsaturated fat': 'Grasas poli saturadas',
				'percent daily': 'Los valores son basados en una dieta de  2,000 calorias. Su valor diario puede ser mayor o menor dependiendo en sus necesidades calóricas.',
				'something went wrong': 'Algo no está bien',
				'total price is less then the minimum price': 'El total es menor al minimo',
				'not all of the given products were found': 'No se encontraron todos los productos',
				'the following products are not active': 'Los siguientes productos no estan activos',
				'add to shopping cart': 'Añadir al carrito de compras',
				'items to cart': 'Articulos al carrito',
				'min': 'min',
				'from': 'desde',
				'edit order': 'Editar pedido',
				'do you want to remove / add items to your existing order': '¿Usted desea eliminar / añadir artículos al pedido existente?',
				'note': 'Advertencia',
				'prices and specials will change according to the update date': 'Los precios y ofertas cambiaran de acuerdo a la nueva fecha',
				'more': 'Más',
				'less': 'Menos',
				'offer not found': 'La oferta no se encontró',
				'product info disclaimer': 'Información sobre el producto o envase mostrados pueden no estar actualizados o completos. Siempre consulte el producto físico para la información más precisa y advertencias. Para obtener información adicional, póngase en contacto con el vendedor o fabricante.',
				'delivery hours': 'Horas de envío',
				'delivery area': 'Áreas de envío',
				'branch': 'Sucursal',
				'quantity limitation': 'Limites en cuanto a la cantidad',
				'is limited to ': 'Está limitado a: ',
				'items only': 'Sólo artículos',
				'cart not found': 'Carrito de compras no encontrado',
				'existing member': 'Miembro existente',
				'connect your {clubname} membership account': 'Conectar con su membresia {clubName}',
				'join {clubname} membership account': 'Inscribirse a {clubName}',
				'to complete your purchase, join/connect to {clubname} membership account': 'Para finalizar su compra registrese o ingrese su cuenta {clubName}',
				'in order to complete your purchase, you must connect to the loyalty club': 'Para completar su compra debe registrarse al programa de cliente frecuente',
				'customer id': 'Id del usuario',
				'connect': 'Conectar',
				'connect_to_loyalty': 'Conectar con club de lealtad',
				'could save': 'Podrías ahorrar',
				'continue without membership': 'Continuar sin membrecía',
				'continue without club discounts': 'Continuar sin club de descuentos',
				'you are now logged in and connected to your membership': 'Usted ahora ha iniciado sesión y se ha conectado con su membrecía',
				'membership club': 'Membrecía del club',
				'connect to our membership club': 'Conectese a nuestro club de miembros',
				'membership club number': 'Número de membrecía',
				'membership club special deals': 'Ofertas para miembros',
				'membership_club': 'Membership Club',
				'{clubname} special deals': 'Ofertas {clubname}',
				'expiration date': 'Fecha de expiración',
				'you already a registered member': 'A esta registrado al programa',
				'we did not find your membership club account': 'No encontramos la cuenta de su membrecía',
				'sorry': 'Lo siento',
				'you are now connected to your membership club account': 'Usted está ahora conectado a su cuenta como miembro del club',
				'you have successfully registered to {clubname}. you will need to connect as a club member, once you receive your credit card': 'Se ha registrado correctamente en {clubname}. deberá conectarse como miembro del club, una vez que reciba su tarjeta de crédito',
				'the registration process will end when you will pay a membership fee at checkout': 'El proceso de registro finalizará cuando pague una tarifa de membresía al finalizar la compra',
				'remove credit card info': 'Eliminar la información de la tarjeta de crédito',
				'are you sure you want to remove credit card information': 'Está seguro que quiere remover la tarjeta de crédito',
				'remove paypal account': 'Remover cuenta de Paypal',
				'are you sure you want to remove this paypal account': '¿Estás seguro de que deseas eliminar esta cuenta de paypal?',
				'are you sure you want to cancel your order update and return to your original order': '¿Está seguro de que desea cancelar la actualización de su pedido y volver a su pedido original?',
				'print': 'Imprimir',
				'track order': 'Localizar orden',
				'view order': 'Ver orden',
				'forgot something': '¿Olvidó algo',
				'update order': 'Actualizar pedido',
				'update order failed': 'Fallo la actualizacion de la orden',
				'order received': 'Orden Recibida',
				'order is in process, no changes can be made at this time': 'Orden en proceso. No se pueden hacer cambios en este momento',
				'order in process': 'Orden en proceso',
				'order ready': 'Orden lista',
				'in process': 'En proceso',
				'ready': 'Listo',
				'need help': 'Necesita ayuda',
				'edit your order': 'Editar su orden',
				'your order': 'Su orden',
				'has been updated': 'Ha sido actualizada',
				'please check your email for order details': 'Revise su email para detalles de la orden',
				'thank you': 'Gracias',
				'we have started collecting your original order.': 'Hemos empezado a sacar su pedido original',
				'we have started collecting your order.': 'Hemos empezado a sacar su pedido',
				'we have finished collecting your original order.': 'Hemos terminado de sacar su pedido original',
				'this is a canceled order.': 'Esta orden ha sido cancelada',
				'we have finished collecting your order.': 'Hemos terminado de sacar su orden',
				'your order can no longer be changed': 'Ya no pude hacer cambios en su orden',
				'your order cannot be changed at this time': 'Su orden no puede ser modificada en este momento',
				'cancel update': 'Cancelar actualización',
				'canceling': 'Cancelando',
				'update': 'Actualizar',
				'replace shopping cart': 'Remplazar carrito de compras',
				'are you sure you want to replace your shopping cart with the order from': 'Está seguro que quiere remplazar su carrito de compras con la orden de',
				'replace': 'Reemplazo',
				'replace_2': 'Reemplazo',
				'you can still edit your order': 'Todavía puede editar su orden',
				'your original order is': 'Su orden original es',
				'cancel order update': 'Cancelar actualizacion de la orden',
				'in process ': 'En proceso',
				'continue as new cart': 'Continuar como carrito nuevo',
				'add your credit card': 'Agregar tarjeta de credito',
				'add your credit/debit card': 'Añade tus tarjetas de crédito/débito',
				'use my delivery address as my billing address*': 'Usar mi dirección de entrega como mi dirección de facturación*',
				'*by checking this option you approve transfer of your address data to the payment provider': 'Al marcar esta opción, aprueba la transferencia de los datos de su dirección al proveedor de pago',
				'you can move the selected items into a new order': 'Puede mover los articulos seleccionados a una nueva orden',
				'paypal': 'PayPal',
				'icreditmasterpass': 'Master Pass',
				'cost': 'Costo',
				'please wait': 'Por favor espere',
				'checking your address': 'Verificando su direccion',
				'missing details': 'Faltan detalles',
				'the server check your address': 'Verificando si hay envios hacia su area',
				'please enter your delivery address in order to see available delivery times': 'Por favor ingresar su direccion para verificar si hay envios',
				'your cart is empty': 'Su carrito esta vacio',
				'housenum: max error': 'Por favor ingresar un numero menor a 3000',
				'personalid: required error': 'ID personal es requerido',
				'customerid: required error': 'ID de cliente es requerido',
				'storecredit: required error': 'Por favor ingrese detalles de pago',
				'special_exp': 'Termina el',
				'special_exp_new': 'Termina el',
				'close more delivery times': 'Cerrar horas de envios',
				'en': 'es',
				'categories': 'Categorias',
				'enter specials department': 'Ir a ofertas',
				'order by': 'Ordenar por',
				'start shopping': 'Empezar a comprar',
				'choose an order': 'Escoger un pedido',
				'load more': 'Cargar mas',
				'restore': 'Restituir',
				'newsletter': 'Novedades',
				'show more': 'Mostrar mas',
				'show less': 'Mostrar menos',
				'savings': 'Ahorro',
				'membership savings': 'Ahorro por ser miembro',
				'potential membership savings': 'Puede ahorrar si es miembro',
				'select delivery date & time': 'Escoja hora y fecha de envio',
				'select pickup date & time': 'Escoja hora y fecha de retiro',
				'the expiration of these': 'Expira el',
				'sales will not be valid for this chosen time': 'Las ofertas no seran validas para esa fecha',
				'the special displayed below, will expire on the date you are supposed to receive your order': 'Estas ofertas no estaran disponibles el dia seleccionado',
				'these items will be charged at their regular price': 'Estos articulos seran cobrados a precio regular',
				'this product will be charged the regular price, promotion will not be applied': 'Este articulo sera cobrado a precio regular. La promocion no sera aplicada',
				'price of updated cart (est.)': 'Precio del carrito actualizado (aprox.)',
				'price of updated service fee (est.)': 'Costo actualizado del calgo por servicio (aprox.)',
				'welcome': 'Bienvenido',
				'please enter a zip code': 'Por favor ingresar codigo de area',
				'please enter a city': 'Por favor ingresar ciudad',
				'please enter a delivery address': 'Por favor ingresar direccion de envio',
				'verify_1': 'Please verify',
				'verify_2': 'your age',
				'verify_3': 'You must be at least',
				'verify_4': 'years old to',
				'verify_5': 'purchase this item',
				'verify_6': 'I am',
				'verify_7': 'older',
				'verify_8': 'than',
				'verify_9': 'younger',
				'years': 'años',
				'sorry_2': 'Lo siento',
				'you cannot purchase': 'usted no puede realizar compras',
				'this item': 'este articulo',
				'login_tab_title': 'Inicie  sesión',
				'register_tab_title': 'Registro',
				'club member name': 'Nombre del miembro',
				'our top picks': 'Nuestros favoritos',
				'got it': 'Entendido',
				'friend\'s email': 'Correo de un amigo',
				'or share your promo code': 'Compartir código promocional',
				'copy': 'Copiar',
				'send invitation': 'Enviar invitación',
				'invitation sent successfully': 'Invitación enviada exitosamente',
				'congratulations': 'Felicidades',
				'shop now': 'Compre ahora',
				'shop': 'Compre',
				'your order is being edited by another device': 'Su orden esta siendo editada desde otro dispositivo',
				'main menu': 'Menú principal',
				'invalid cart id': 'ID de carrito invalido',
				'loyalty club member': 'Membresía del club',
				'in this purchase': 'En esta compra',
				'save_1': 'Guardar',
				'not a member yet? join now!': '¿No eres miembro? ¡Registrate ahora!',
				'continue without loyalty': 'Continuar sin suscribirse',
				'please type in your zip code': 'Por favor ingrese su código postal',
				'find address': 'Encuentre la dirección',
				'you cannot edit this field before entering a zip code for your address': 'No puede editar este campo antes de ingresar un código postal para su dirección',
				'please choose your address': 'Por favor elija su dirección',
				'you will be able to edit the address details': 'Podrá editar los detalles de la dirección',
				'you cannot edit this field': 'No puede editar este campo',

				/*pele card errors*/
				'invalid card': 'Tarjeta no válida',
				'permitted transaction.': 'Transacción permitida',
				'the card is blocked, confiscate it.': 'La tarjeta está bloqueada, retenerla.',
				'the card is stolen, confiscate it.': 'La tarjeta está extraviada, retenerla.',
				'contact the credit company.': 'Contacte a su entidad bancaria.',
				'refusal by credit company.': 'Rechazada por la entidad bancaria.',
				'the card is forged, confiscate it.': 'Esta tarjeta fue olvidada, retenerla.',
				'incorrect cvv/id.': 'cvv/id incorrecto.',
				'an error occurred while building access key for blocked card files.': 'Se produjo un error mientras que de accedía a la clave de acceso por archivos de tarjeta bloqueada.',
				'no communication. please try again or contact system administration': 'No hay comunicación. Inténtelo de nuevo o contacte al sistema administrativo',
				'the program was stopped by user`s command (esc) or com port can\'t be open (windows)': 'El programa fue detenido por el comando del usuario (esc) o el puerto com no se puede abrir (windows).',
				'the acquirer is not authorized for foreign currency transactions': 'El comprador no está autorizado para realizar transacciones en moneda extranjera',
				'this card is not permitted for foreign currency transactions': 'Esta tarjeta no está permitida para transacciones en moneda extranjera',
				'this card is not supported.': 'Esta tarjeta no es compatible.',
				'track 2 (magnetic) does not match the typed data.': 'La pista 2 (magnética) no coincide con los datos escritos.',
				'additional required data was entered/not entered as opposed to terminal settings (z field).': 'Los datos adicionales requeridos fueron/no fueron ingresados, al contrario de las configuraciones del terminal (campo z)',
				'last 4 digits were not entered (w field).': 'Los últimos 4 dígitos no fueron introducidos (campo w).',
				'entry in int_in file is shorter than 16 characters.': 'La entrada en el archivo int_in es menor a 16 caracteres.',
				'the input file (int_in) does not exist.': 'No existe el archivo de entrada (int_in).',
				'blocked cards file (neg) does not exist or has not been updated, transmit or request authorization for each transaction.': 'Bloqueado el archivo de tarjetas (neg) no existe o no está actualizado, transmitir o solicitar autorización para cada transacción.',
				'one of the parameter files/vectors does not exist.': 'Uno de los archivos de parámetros / vectores no existe.',
				'date file (data) does not exist.': 'El archivo de fecha (data), no existe.',
				'format file (start) does not exist.': 'No existe archivo de formato (start).',
				'the difference in days in the blocked cards input is too large, transmit or request authorization for each transaction.': 'La diferencia en días en la entrada de las tarjetas bloqueadas es demasiado grande, transmitir o solicitar la autorización para cada transacción.',
				'the difference in generations in the blocked cards input is too large, transmit or request authorization for each transaction.': 'La diferencia de generaciones en la entrada de tarjetas bloqueadas es demasiado grande, transmitir o solicitar la autorización para cada transacción.',
				'when the magnetic strip is not completely entered, define the transaction as a telephone number or signature only.': 'Si no se introduce por completo la banda magnética, complete la operación solicitando un número telefónico o firma.',
				'the central terminal number was not entered into the defined main supplier terminal.': 'El número del terminal central no se ha introducido en el terminal principal del proveedor definido.',
				'the beneficiary number was not entered into the defined main beneficiary terminal.': 'El número del beneficiario no se ha introducido en el terminal principal del beneficiario definido.',
				'the supplier/beneficiary number was entered, however the terminal was not updated as the main supplier/beneficiary.': 'Se introdujo el número de proveedor/beneficiario, sin embargo, el terminal no se ha actualizado como principal para proveedor/beneficiario.',
				'the beneficiary number was entered, however the terminal was updated as the main supplier': 'Se introdujo el número del beneficiario, sin embargo, el terminal se actualizo como proveedor principal.',
				'old transactions, transmit or request authorization for each transaction.': 'Operaciones viejas, transmites o solicitudes de autorización para cada transacción.',
				'defective card.': 'Tarjeta defectuosa.',
				'this card is not permitted for this terminal or is not authorized for this type of transaction.': 'Esta tarjeta no está permitida para este terminal o no está autorizada para este tipo de operación.',
				'this card is not permitted for this transaction or type of credit.': 'Esta tarjeta no está permitida para esta transacción o este tipo de crédito.',
				'expired card.': 'Tarjeta expirada.',
				'expired token': 'Token expirado',
				'installment error, the amount of transactions needs to be equal to: first installment plus fixed installments times number of installments.': 'Error en las cuotas, la cantidad de transacciones deben ser iguales a: primera cuota, más el número de cuotas fijadas.',
				'unable to execute a debit transaction that is higher than the credit card`s ceiling.': 'No se puede débitar una cantidad superior al límite máximo de la tarjeta de crédito.',
				'incorrect control number.': 'Número de control incorrecto.',
				'the beneficiary and supplier numbers were entered, however the terminal is defined as main.': 'Se introdujeron los números del beneficiario y el proveedor, sin embargo, el terminal se define como principal.',
				'the transaction`s amount exceeds the ceiling when the input file contains j1, j2 or j3 (contact prohibited).': 'La cantidad de transacciones supera el límite máximo cuando el archivo de entrada contiene J1, J2 o J3 (contacto no permitido).',
				'the card is blocked for the supplier where input file contains j1, j2 or j3 (contact prohibited).': 'La tarjeta es bloqueada por el proveedor cuando el archivo de entrada contiene J1, J2 o J3 (contacto no permitido).',
				'random where input file contains j1 (contact prohibited).': 'Archivo de entrada contiene al azar J1 (contacto no permitido).',
				'the terminal is prohibited from requesting authorization without transaction (j5).': 'El terminal no tiene permitido solicitar autorización sin operación (J5).',
				'the terminal is prohibited for supplier-initiated authorization request (j6).': 'El terminal no tiene permitido solicitar autorización para ingresar proveedor (J6).',
				'the terminal must request authorization where the input file contains j1, j2 or j3 (contact prohibited).': 'El terminal debe solicitar autorización, cuando el archivo de entrada contiene j1, j2 o j3 (contacto no permitido).',
				'secret code must be entered where input file contains j1, j2 or j3 (contact prohibited).': 'Código secreto debe ser introducido si el archivo de entrada contiene j1, j2 o j3 (contacto no permitido).',
				'incorrect vehicle number.': 'número de vehículo incorrecto.',
				'the number of the distance meter was not entered.': 'No se ha ingresado el número del medidor de distancia.',
				'the terminal is not defined as gas station (petrol card or incorrect transaction code was used).': 'El terminal no se define como estación de servicio (se utilizó tarjeta de gasolina o un código de operación incorrecto).',
				'an id number is required (for israeli cards only) but was not entered.': 'Se requiere número de ID (sólo para tarjetas israelíes), pero no se ha ingresado.',
				'cvv is required but was not entered.': 'Se requiere CVV, pero no se ha ingresado.',
				'cvv and id number are required (for israeli cards only) but were not entered.': 'Se requiere CVV y número de ID (sólo para tarjetas israelíes), pero no se ingresaron.',
				'abs attachment was not found at the beginning of the input data in memory.': 'Los archivos adjuntos abs no se encontraron al inicio de los datos de entrada en la memoria.',
				'the card number was either not found or found twice.': 'O bien no se encontró el número de tarjeta o esta se encontró dos veces.',
				'incorrect transaction type.': 'Tipo de operación incorrecto.',
				'incorrect transaction code.': 'Código de operación incorrecto.',
				'incorrect credit type.': 'Tipo de crédito incorrecto.',
				'incorrect currency.': 'Moneda incorrecta.',
				'the first installment and/or fixed payment are for non-installment type of credit.': 'La primera cuota y/o pago fijo es para un tipo de crédito sin cuotas.',
				'number of installments exist for the type of credit that does not require this.': 'Existe un número de cuotas para este tipo de crédito que no requiere esto.',
				'linkage to dollar or index is possible only for installment credit.': 'Vinculación con el dólar o la indexación solo es posible para crédito a plazos.',
				'the magnetic strip is too short.': 'La banda magnética es demasiado corta.',
				'the pin code device is not defined.': 'El dispositivo de código pin no se ha definido.',
				'must enter the pin code number.': 'Debe introducir el código pin.',
				'smart card reader not available - use the magnetic reader.': 'Lector de tarjetas inteligentes no disponible - utilizar lector magnético.',
				'must use the smart card reader.': 'Debe utilizar el lector de tarjetas inteligentes.',
				'denied - locked card.': 'Negado - tarjeta bloqueada.',
				'denied - smart card reader action didn\'t end in the correct time.': 'Denegado: la acción del lector de tarjetas inteligentes no terminó en el momento correcto.',
				'denied - data from smart card reader not defined in system.': 'Denegado - datos de lector de tarjetas inteligentes no definido en el sistema.',
				'incorrect pin code.': 'Código pin incorrecto.',
				'currency does not exist in vector 59.': 'La moneda no existe en el vector 59.',
				'the club code entered does not match the credit type.': 'El código del club introducido no coincide con el tipo de crédito.',
				'cannot cancel charge transaction. please create a credit transaction.': 'No se puede cancelar la operación de carga. Por favor, genere una operación de crédito.',
				'unable to read/write/open the tran file.': 'No se puede leer/escribir/abrir el archivo tran.',
				'no authorization from credit company for clearance.': 'Sin autorización de la compañía de crédito para su despacho.',
				'the terminal is not permitted to send queries for immediate debit cards.': 'El terminal no está autorizado a consultas tarjetas de débito inmediato.',
				'the transaction amount is too large, divide it into a number of transactions.': 'El monto de la transacción es demasiado grande, divídala en varias operaciones.',
				'the terminal is not authorized to execute forced transactions.': 'El terminal no está autorizado para ejecutar transacciones forzadas.',
				'the terminal is not authorized for cards with service code 587.': 'El terminal no está autorizado para tarjetas con código de servicio 587.',
				'the terminal is not authorized for immediate debit cards.': 'El terminal no está autorizado para tarjetas de débito inmediato.',
				'the terminal is not authorized for installment transactions.': 'El terminal no está autorizado para operaciones a plazos.',
				'the terminal is authorized for installment transactions only, not telephone/signature.': 'El terminal está autorizado solo para operaciones a plazos,no telefónicas o con firma.',
				'the terminal is not authorized for telephone transactions.': 'El terminal no está autorizado para transacciones telefónicas.',
				'the terminal is not authorized for signature-only transactions.': 'El terminal no está autorizado para las transacciones con solo firma.',
				'the terminal is not authorized for foreign currency transactions, or transaction is not authorized.': 'El terminal no está autorizado para operaciones en moneda extranjera, o transacciones no autorizadas.',
				'the terminal is not authorized for club transactions.': 'El terminal no está autorizado para las transacciones del club.',
				'the terminal is not authorized for star/point/mile transactions.': 'El terminal no está autorizado para operaciones con estrellas/ puntos/millas.',
				'the terminal is not authorized for isracredit credit.': 'El terminal no está autorizado para el crédito isracredit.',
				'the terminal is not authorized for amex credit.': 'El terminal no está autorizado para el crédito amex.',
				'the terminal is not authorized for dollar linkage.': 'El terminal no está autorizado para la vinculación en dólares.',
				'the terminal is not authorized for index linkage.': 'El terminal no está autorizado para indexar enlaces.',
				'the terminal is not authorized for index linkage with foreign cards.': 'El terminal no está autorizado para indexar enlaces con tarjetas extranjeras.',
				'the terminal is not authorized for star/point/mile transactions for this type of credit.': 'El terminal no está autorizado para operaciones con estrellas/puntos/milla para este tipo de crédito.',
				'the terminal is not authorized for isra 36 credit.': 'El terminal no está autorizado para el crédito Isra 36.',
				'the terminal is not authorized for amex 36 credit.': 'El terminal no está autorizado para crédito amex 36.',
				'the terminal is not authorized for this club code.': 'El terminal no está autorizado para el código club.',
				'the terminal is not authorized for immediate debit transactions (except for immediate debit cards).': 'El terminal no está autorizado para realizar operaciones de débito inmediato (a menos que se empleen tarjetas de débito inmediato).',
				'the terminal is not authorized to accept visa card staring with 3.': 'El terminal no está autorizado para aceptar tarjetas visa que comiencen en 3.',
				'the terminal is not authorized to execute credit transactions above the ceiling.': 'el terminal no está autorizado para ejecutar operaciones de crédito por encima del límite.',
				'the card is not permitted to execute club transactions.': 'La tarjeta no está autorizada para realizar operaciones del club.',
				'the card is not permitted to execute star/point/mile transactions.': 'Tarjeta no autorizada para ejecutar transacciones con puntos/estrellas/millas.',
				'the card is not permitted to execute dollar transactions (regular or telephone).': 'Tarjeta no autorizada para ejecutar transacciones en dólares (regulares o por teléfono).',
				'the card is not valid according to isracard`s list of valid cards.': 'Tarjeta no válida de acuerdo con la lista isracard de tarjetas válidas.',
				'defective card according to system definitions (isracard vector1), error in the number of figures on the card.': 'Tarjeta defectuosa de acuerdo con las definiciones del sistema (isracard vector1), error en el número de figuras de la tarjeta.',
				'the card is not permitted to execute dollar transactions according to system definitions (isracard vector1).': 'La tarjeta no está autorizada para ejecutar transacciones en dólares de acuerdo con las definiciones del sistema (isracard vector1).',
				'the card belongs to a group that is not permitted to execute transactions according to system definitions (visa vector 20).': 'La tarjeta pertenece a un grupo que no tiene autorización para realizar estas operaciones, según las definiciones del sistema (visa vector 20).',
				'the card`s prefix (7 figures) is invalid according to system definitions (diners vector21).': 'El prefijo de la tarjeta (7 figuras) no es válido de acuerdo con las definiciones del sistema (diners vector 21).',
				'the card is not permitted to carry out installment transactions according to isracard`s list of valid cards.': 'La tarjeta no está autorizada para realizar transacciones a plazos de acuerdo a la lista isracard de tarjetas válidas.',
				'the number of installments is too large according to isracard`s list of valid cards.': 'El número de cuotas es demasiado grande según la lista  isracard de tarjetas válidas.',
				'visa and diners cards are not permitted for club installment transactions.': 'Las tarjetas visa y diners no están autorizadas para operaciones a plazos del club.',
				'series of cards are not valid according to system definition (isracard vector5).': 'Serie de tarjetas no válidas según la definición del sistema (Isracard vector5).',
				'invalid service code according to system definitions (isracard vector6).': 'Código de servicio no válido de acuerdo con las definiciones del sistema (isracard vector6).',
				'the card`s prefix (2 figures) is invalid according to system definitions (isracard vector7).': 'El prefijo de la tarjeta (2 figuras) no es válido de acuerdo con las definiciones del sistema (isracard vector7).',
				'invalid service code according to system definitions (visa vector12).': 'Código de servicio no válido de acuerdo a las definiciones del sistema (visa vector 12).',
				'invalid service code according to system definitions (visa vector13).': 'Código de servicio no válido de acuerdo a las definiciones del sistema (visa vector 13).',
				'immediate debit card is prohibited for executing credit transaction.': 'La tarjeta de débito inmediato no está autorizada para la ejecución de operaciones de crédito.',
				'the card is not permitted to execute installment transactions according to alpha vector no. 31.': 'La tarjeta no está autorizada para realizar operaciones a plazos de acuerdo al alpha vector no. 31.',
				'the card is not permitted for telephone and signature-only transactions according to alpha vector no. 31.': 'La tarjeta no está autorizada para operaciones telefónicas y operaciones con solo firma de acuerdo con alpha vector no. 31.',
				'the card is not permitted for telephone transactions according to alpha vector no. 31.': 'La tarjeta no está autorizada para operaciones telefónicas alpha vector no. 31.',
				'credit is not approved for immediate debit cards.': 'Crédito no aprobado para tarjetas de débito inmediato.',
				'credit is not approved for foreign cards.': 'Crédito no aprobado para tarjetas extranjeras.',
				'incorrect club code.': 'Código club incorrecto.',
				'the card is not permitted to execute flexible credit transactions (adif/30+) according to system definitions (diners vector21).': 'Esta tarjeta no está autorizada a realizar operaciones de crédito flexibles (adif/30+) de acuerdo con las definiciones del sistema (diners vector 21).',
				'the card is not permitted to execute immediate debit transactions according to system definitions (diners vector21).': 'Esta tarjeta no está pautorizada para ejecutar transacciones de débito inmediato de acuerdo con las definiciones del sistema (diners vector 21).',
				'the payment amount is too low for credit transactions.': 'La cantidad a pagar es demasiado baja para una operación de crédito.',
				'incorrect number of installments for credit transaction.': 'Número incorrecto de pagos para operaciones de crédito.',
				'zero ceiling for this type of card for regular credit or credit transaction.': 'El limite de este tipo de tarjeta para crédito regular y operaciones de crédito es cero.',
				'zero ceiling for this type of card for immediate debit credit transaction.': 'El limite de este tipo de tarjeta para operaciones de crédito y débito inmediato es cero.',
				'zero ceiling for this type of card for immediate debit in dollars.': 'El limite de este tipo de tarjeta para el débito inmediato en dólares es cero.',
				'zero ceiling for this type of card for telephone transaction.': 'El limite de este tipo de tarjeta para las operaciones telefónicas es cero.',
				'zero ceiling for this type of card for credit transaction.': 'El limite de este tipo de tarjeta para las operaciones de crédito es cero.',
				'zero ceiling for this type of card for installment transaction.': 'El limite de este tipo de tarjeta para realizar pagos, es cero.',
				'american express card issued abroad not permitted for instalments transaction.': 'Las tarjetas american express emitidas en el extranjero no están permitidas para operaciones de pago.',
				'jcb cards are only permitted to carry out regular credit transactions.': 'Las tarjetas jcb sólo permiten llevar a cabo operaciones regulares de crédito.',
				'the amount in stars/points/miles is higher than the transaction amount.': 'La cantidad de estrellas/puntos/millas es mayor que el monto de la transacción.',
				'the club card is not within terminal range.': 'La tarjeta del club no está en el rango de este terminal.',
				'star/point/mile transactions cannot be executed.': 'Las transacciones con estrellas/puntos/millas no se pueden ejecutar.',
				'dollar transactions cannot be executed for this type of card.': 'Las transacciones en dólares no se pueden ejecutar con este tipo de tarjeta.',
				'credit transactions cannot be executed with other than regular credit.': 'Las operaciones de crédito no pueden ser ejecutadas con otro instrumento que no sea de crédito regular.',
				'amount of discount on stars/points/miles is higher than the permitted.': 'Descuento con estrellas/puntos/millas es superior al permitido.',
				'forced transactions cannot be executed with credit/immediate debit card.': 'Las operaciones forzadas no se pueden ejecutar con tarjetas de crédito/débito inmediato.',
				'the previous transaction cannot be cancelled (credit transaction or card number are not identical).': 'La operación anterior no puede ser cancelada (operación de crédito o número de tarjeta no son idénticos).',
				'double transaction.': 'Transacción duplicada.',
				'the terminal is not permitted for index linkage of this type of credit.': 'El terminal no está autorizado para indexar enlaces de este tipo de crédito.',
				'the terminal is not permitted for dollar linkage of this type of credit.': 'El terminal no está autorizado para vincular en dólares este tipo de crédito.',
				'the card is invalid according to system definitions (isracard vector1).': 'La tarjeta no es válida de acuerdo con las definiciones del sistema (Isracard vector1).',
				'unable to execute the self-service transaction if the gas station does not have self service.': 'No se puede ejecutar la operación de auto-servicio si la estación de servicio no es de auto servicio.',
				'credit transactions are forbidden with stars/points/miles.': 'Las operaciones de crédito están prohibidas con estrellas/puntos/millas.',
				'dollar credit transactions are forbidden on tourist cards.': 'Las operaciones de crédito en dólares están prohibidas con tarjetas de turismo.',
				'phone transactions are not permitted on club cards.': 'Las transacciones telefónicas no están permitidas con las tarjetas del club.',
				'application error.': 'Error de aplicación.',
				'transaction amount missing or zero.': 'Monto de la operación desaparecido o igual a cero.',
				'no communication to pelecard.': 'Sin comunicación con pelecard.',
				'doubled transaction.': 'Transacción duplicada.',
				'terminal number does not exist.': 'No existe este número de terminal.',
				'terminal executes broadcast and/or updating data. please try again later.': 'El terminal ejecuta transmisión y/o actualización de datos. inténtelo más tarde.',
				'user name and/or password not correct. please call support team.': 'Nombre de usuario y/o contraseña equivocado. Por favor contacte al equipo de soporte.',
				'user password has expired. please contact support team.': 'Contraseña del usuario ha caducado. Por favor, póngase en contacto con el equipo de apoyo.',
				'locked user. please contact support team.': 'Usuario bloqueado. por favor, póngase en contacto con el equipo de apoyo.',
				'blocked terminal. please contact account team.': 'Terminal bloqueado. por favor, póngase en contacto con equipo contable.',
				'token number abnormal.': 'Número anormal de token',
				'user is not authorized in this terminal.': 'El usuario no está autorizado para utilizar este terminal.',
				'validity structure invalid. use mmyy structure only.': 'Validar estructura. utilizar sólo estructura mm/aa.',
				'ssl verifying access is blocked. please contact support team.': 'La verificación de acceso a ssl está bloqueada. Por favor contacte al equipo de soporte.',
				'data not exist.': 'No existen datos.',
				'general error. please contact support team.': 'Error general. por favor, póngase en contacto con el equipo de soporte.',
				'necessary values are blocked/wrong.': 'Hay valores necesarios que están bloqueados/error.',
				'general error. repeat action.': 'Error general. Repetir acción.',
				'necessary values missing to complete installments transaction': 'Faltan valores necesarios para completar la operación de pago.',
				/*end pele card errors*/

				'video': 'Video',
				'user not found': 'Nombre de usuario o contraseña incorrecta',
				'in {name} dep.': 'En el departamento {name}',
				'in specials': 'En ofertas',
				'coupons': 'Cupones',
				'my coupons': 'Mis cupones',
				'no coupons available at this time. check again later or check our specials!': 'No hay cupones disponibles en este momento. Revise luego.',
				'giftcards.0: gift card must be up to 255 characters error': 'Tarjeta de regalo debe tener un máximo de 255 caracteres',
				'your membership has expired': 'Su membresía ha expirado',
				'hello': 'Hola',
				'your club membership has expired on': 'Su membresía club expiró el',
				'membership number': 'Número de membresía',
				'renew you membership and enjoy exclusive benefits and discounts': 'Renueve su membresía y disfrute de exclusivos beneficios y descuentos',
				'please call customer service for renewal': 'Por favor llame al servicio al cliente para la renovación',
				'loyalty registration was not fully completed, registration voucher will be sent by mail': 'El registro de lealtad no se completó por completo, el comprobante de registro se enviará por correo',
				'phone number does not match the existing phone number on the system': 'El número de teléfono no coincide con el número de teléfono existente en el sistema',
				'pay attention': 'Atención',
				'enter a valid number': 'Ingrese un número válido',
				'paying with different payment method will': 'Pagar con un método diferente hara que.',
				'discard your loyalty club discounts': 'Descarta los descuentos de tu club de fidelización',
				'do you want to continue': '¿Desea continuar?',
				'return and pay with loyalty credit card': 'Regrese y pague con tarjeta de crédito de fidelidad',
				'no thanks, continue shopping': 'No gracias, continuar comprando',
				'renew membership': 'Renovar membresía',
				'loyalty membership renewal': 'Renovación de membresía de lealtad',
				'your membership has been renewed successfully': 'Su membresía ha sido renovada con éxito',
				'you can enjoy from the club benefits in this order': 'Puede disfrutar de los beneficios del club en esta orden',
				'continue': 'Continuar',
				'loyalty_club_continue': 'Continuar',
				'loyalty card id': 'ID de tarjeta de fidelidad',
				'clip & add': 'Recortar y agregar',
				'clip': 'Agregar',
				'personal id': 'Identificación personal',
				'we are not able to register your membership club account': 'No podemos registrar su cuenta del club de membresía',
				'please make sure your details are correct or try again later': 'Asegúrese de que sus datos sean correctos o vuelva a intentarlo más tarde',
				'new member': 'Nuevo miembro',
				'{retailername} membership offers exclusive': 'La membresía de {retailername} ofrece exclusivas',
				'promotions in-store and online': 'Promociones en tienda y online',
				'join our membership and enjoy exclusive benefits and promotions': 'Únase a nuestra membresía y disfrute de ventajas y promociones exclusivas',
				'the items in your shopping cart will be cleared if you': 'Su carrito se vaciara si',
				'choose to edit your previous order': 'Escoger para editar su orden anterior',
				'edit order & clear cart': 'editar orden y vaciar el carrito',
				'it seems you are editing your order on a different device/browser.': 'Parece que esta editando la orden en otro dispositivo',
				'it seems you have finished editing your order on a different device/browser.': 'Parece que termino de editar la orden en otro dispositivo',
				'do you want to add the product to this new order or to the order that is being updated?': 'Desea agregar este producto a una nueva orden o a la que esta editando',
				'note! you in the middle of editing order': 'Atencion! Esta editando una orden',
				'you in the middle of editing order. changing store is impossible': 'Usted en medio de la orden de edición. cambiar de tienda es imposible',
				'note! it seems you have finished editing your order on a different device/browser.': 'Atencion! Al parecer termino de editar la orden en otro dispositivo',
				'add to new order': 'Agregar a una nueva orden',
				'add to updated order': 'Agregar a una orden modificada',
				'join our membership club': 'Únete a nuestro club de membresía',
				'join': 'Únete',
				'join the club': 'Unirse al club',
				'join now': 'Únete ahora',
				'join / connect to club': 'Unirse / conectarse al club',
				'connect / join to club': 'Conectar / unirse al club',
				'connect to club': 'Conectarse al club',
				'this email already exists': 'Ya existe este correo electrónico',
				'unable to create user': 'No se puede crear el usuario',
				'delivery_type_1_times': 'Horas de envío',
				'delivery_type_2_times': 'Horas para retirar',
				'packed': 'Empacado',
				'frombranch': 'Desde',
				'special type': 'Especial',
				'select from variety': 'Escogido de varios',
				'forpackquantity': 'for',
				'pack': 'paquete',
				'forbidden action': 'Acción prohibida',
				'internal server error': 'Internal server error',
				'please leave the order outside the door if nobody\'s home': 'Por favor dejar el envio en la puerta si no hay nadie en casa',
				'identify': 'Identificacion',
				'show full details in {clubname} club page': 'Mostrar todos los detalles en la página del club {clubname}',
				'enter your promo code': 'Introduce tu código de promoción',
				'promo code unidentified': 'Código de promoción no identificado',
				'promocode: promo code unidentified error': 'Promo code unidentified',
				'promo code': 'Código promocional',
				'for {clubname} full details': 'Para conocer todos los detalles de {clubname}',
				'click here': 'Haga click aquí',
				'home phone number': 'Teléfono de casa',
				'work phone number': 'Teléfono de oficina',
				'mobile phone number': 'Teléfono celular',
				'special': 'Oferta',
				'check all | clicking the action buttons above as \'add to cart\' refers to selected items': 'Comprobar todo | hacer clic en los botones de acción de arriba como \'agregar al carrito\' se refiere a los elementos seleccionados',
				'back to previous orders': 'Volver a ordenes anteriores',
				'save changes before leaving page?': 'Guardar cambios antes de salir de la pagina?',
				'don\'t save': 'No guardar',
				'back to my shopping lists': 'Volver a mis listas de compra',
				'add file': 'Agregar archivo',
				'no attachments': 'Sin adjuntos',
				'buy': 'Comprar',
				'no purchases have been made yet previous orders will be saved on this screen': 'No has hecho ninguna compra, las compras futuras serán mostradas en esta pantalla',
				'no shopping list created yet': 'No has creado listas de compras',
				'all departments': 'Todos los departamentos',
				'{retailer} club members can save time and upload their shopping cart from their last visit to the store': 'Los miembros del club {minorista} pueden ahorrar tiempo y cargar su carrito de compras desde su última visita a la tienda.',
				'upload my cart': 'Subir mi carrito',
				'no, thanks': 'No gracias',
				'approve & register': 'Aprobar y proceder',
				'not approve': 'No proceder',
				'i agree to receive promotional content & offers from {company_name} and {hubname}': 'Estoy de acuerdo en recibir promoceiones de {company_name} y {hubname}',
				'unfortunately, your cart cannot be uploaded': 'Desafortunadamente, su carrito no se puede cargar',
				'please try again later or continue shopping': 'Por favor tratar de vuelta o continuar comprando',
				'areaquery: spautocompleterequirematch error': 'Por favor seleccionar una dirección',
				'areaquery: required error': 'Por favor seleccionar una dirección',
				'pickup address': 'Dirección de retiro',
				'the pickup will be picked from': 'Será retirado en',
				'additional special\'s variety': 'Variedad especial adicional-',
				'password: invalid password error': 'La clave debe contener entre 6 y 20 caracteres',
				'customer service order': 'Orden de atención al cliente',
				'logo': 'Logo',
				'copy completed': 'Copia completada',
				'edit comment': 'Editar comentario',
				'filters': 'Filtros',
				'close filters': 'Cerrar filtros',
				'cant find item image': 'No podemos encontrar la imagen',
				'plus': 'Más',
				'minus': 'Menos',
				'open recipe': 'Abrir las intrucciones',
				'banner': 'Banner',
				'play video': 'Reproducir video',
				'remove from cart': 'Quitar del carrito',
				'express delivery': 'Envío express',
				'express pickup': 'Retiro express',
				'paypal logo': 'Logo Paypal',
				'masterpass logo': 'Logo masterpass',
				'language': 'Idioma',
				'account': 'Cuenta',
				'open store info': 'Mostrar información de la tienda',
				'close store info': 'No mostrar información de la tienda',
				'open/close cart': 'Cerrar/Abrir carrito',
				'open ticket': 'Abrir ticket',
				'close ticket': 'Cerrar ticket',
				'paypal account': 'Cuenta PayPal',
				'previous product': 'Producto anterior',
				'next product': 'Siguiente producto',
				'disclaimer': 'Advertencia',
				'the previous items': 'Los artículos anteriores',
				'the next items': 'Los siguientes articulos',
				'open filter options': 'Abrir filtros',
				'close filter options': 'Cerrar filtros',
				'clear': 'Borrar',
				'open product page': 'Abrir pagina de articulos',
				'open': 'Abrir',
				'close our top picks': 'Cerrar sugerencias',
				'clarification': 'Aclaración',
				'show category': 'Mostrar categorias',
				'show order': 'Mostrar orden',
				'close order': 'Cerrar orden',
				'on credit payment': 'Pago a credito',
				'please enter order confirmation number': 'Ingresar el numero de orden',
				'user not connected to organization': 'Usuario no conectado a la organización',
				'no delivery time slots available': 'No hay envios disponibles',
				'no pick up time slots available': 'No hay retiros disponibles',
				'invalid phone number': 'Teléfono invalido',
				'phones - invalid phone number.': 'Teléfono invalido',
				'invalid city': 'Ciudad invalida',
				'invalid address': 'Dirección invalida',
				'invalid street': 'Calle invalida',
				'invalid address 1': 'Dirección 1 invalida',
				'invalid address 2': 'Dirección 2 invalida',
				'invalid zip code': 'Código de área invalido',
				'shopping cart': 'Carrito',
				'main manu': 'Menu principal',
				'home page banner': 'Banner de la página de inicio',
				'promoted specials': 'Promociones especiales',
				'filters & sorting': 'Filtros y orden',
				'product list': 'Lista de productos',
				'checkout footer': 'Pie de página de pago',
				'delivery method': 'Tipo de envio',
				'delivery method & details': 'Tipo de envio y detalles',
				'navigation': 'Navegar',
				'main display': 'Pantalla principal',
				'shortcuts': 'Atajos',
				'filters_region': 'Filters',
				'languages': 'Idiomas',
				'settings': 'Configuración',
				'next slide': 'Siguiente',
				'home page link': 'Página principal',
				'newsletter bubble': 'Burbuja de boletín',
				'number of items': 'Cantidad de articulos',
				'cart_total': 'Total',
				'items_in_cart': 'ArtÍculos',
				'review our last minute recommendations, just before you checkout': 'Antes de finalizar revisa nuestras sugerencias',
				'thank you, you are now logged in': 'Gracias, ya estas registrado',
				'thank you, registration completed successfully': 'Gracias, registro exitoso',
				'delivery method options': 'Formas de envio',
				'substitute preference options': 'Opciones para sustitutos',
				'payment method options': 'Opciones de pago',
				'add coupon': 'Agregar cupon',
				'remove product': 'Quitar producto',
				'remove coupon': 'Quitar cupon',
				'all specials': 'Todas las ofertas',
				'sorry, we do not deliver to your area': 'Disculpe, no hacemos entregas a su area',
				'please type in your email and we will let you know when we expand to your location': 'Por favor ingrese su e-mail y le haremos saber cuando hagamos envios a su localización',
				'email address': 'Dirección de e-mail',
				'keep in touch': 'Manténgase en contacto',
				'try a different address': 'Trate otra direccion',
				'try a different city': 'Trate otra ciudad',
				'try a different zip code': 'Trate otro código de area',
				'please enter a more accurate address to continue': 'Por favor ingrese una dirección de envio mas precisa para poder continuar',
				'express delivery is allowed only with products that contain express delivery tag': 'La entrega express solo está permitida con productos que contienen etiqueta de entrega express.',
				'this service is available for express items only. removing non express items from your cart will enable using this option': 'Este servicio está disponible solo para artículos express. eliminar artículos no exprés de su carrito habilitará el uso de esta opción',
				'express delivery products limit exceeded': 'Excedio la cantidad de artículos para delivery express',
				'express delivery is limited to': 'El delivery express esta limitado a',
				'items. to enable this service, remove items from your cart': 'artículos. Para proceder con el servicio retire artículos del carrito',

				'apologies': 'Disculpe',

				'view all': 'Ver todos',

				'products limit': 'Limite de productos',
				'this delivery has a limit of {deliveryitemslimit} products': 'Este tipo de envio tiene un limite de {deliveryItemsLimit} articulos',
				'limited to {deliveryitemslimit} products': 'limitado a {deliveryItemsLimit} articulos',
				'limited to products marked with \'{tagname}\' tag only': 'limitado a productos marcados con la etiqueta \'{tagname}\' únicamente',
				'has no items limit': 'No tiene limite de articulos',
				'choose the pickup location': 'Escoja sucursal para retiro',
				'if you wish to add another product, remove one from the cart first': 'Si quiere agregar otro articulo debe retirar uno del carrito',
				'or change the delivery method': 'o cambie el tipo de envio',
				'change delivery method': 'Cambie el tipo de envio',
				'are you sure you want to change the delivery type': 'Esta seguro que desea cambiar el tipo de envio',
				'changes in delivery times may apply': 'Pueden haber cambios en la hora de envio',
				'changes in inventory between the stores may apply': 'Pueden aplicarse cambios en el inventario entre las tiendas',
				'yes, change delivery method': 'Si, cambiar el tipo de envio',
				'no, stay in current delivery': 'No, mantener este tipo de envio',
				'or select a store for pickup': 'o seleccione una sucursal para retiro',
				'select a store for pickup': 'Seleccione una sucursal para retiro',
				'choose a convenient time & pickup your order at one of our stores': 'Escoja una hora para retirar su pedido en una de nuestras sucursales',
				'choose': 'Escoger',
				'branch logo': 'Logo de sucursal',
				'unfortunately we didn\'t find any stores in this area': 'Disculpe, no conseguimos sucursales en su area',
				'try a different area name': 'Trate otro nombre',
				'please enter a valid address': 'Por favor ingresar una dirección valida',
				'please add street and house number': 'Por favor ingresar una direccion',
				'delivery_type_1': 'Delivery',
				'delivery_type_2': 'Retirar en sucursal',
				'delivery_type_5': 'Express Delivery',
				'see you soon': 'Nos vemos pronto!',
				'the order will wait for you at the store': 'Puede retirar la orden en la sucursal',
				'from branch': 'de la sucursal',
				'chametz': 'Chametz',
				'passoverkosher': 'Kosher para Pesaj',
				'passoverkoshertemporarily': 'Por el momento Kosher para Pesaj',
				'passoverkitniyotkosher': 'Kosher para Pesaj (Kitniyot)',
				'passoverkitniyotkoshertemporarily': 'Por el momento Kosher para Pesaj (Kitniyot)',
				'meat': 'Carne-Kosher',
				'milky': 'Lacteo-Kosher',
				'pareve': 'Parve-Kosher',
				'israelmilk': 'Jalav Israel',
				'israelcooking': 'Bishul ISrael',
				'sabbathobservingfactory': 'Sabbath Observing Factory',
				'noshviitconcern': 'No Shviit Concern',
				'noorlaconcern': 'No Orla Concern',
				'notevelconcern': 'No Tevel Concern',
				'noovernightconcern': 'No Overnight Concern',
				'nogeniza': 'No Geniza',
				'recipes and allergies': 'Recetas y Alergenos',
				'list of ingredients': 'Lista de ingredientes',
				'allergies contained': 'Alergenos',
				'possible allergies': 'Posibles alergias',
				'about the product': 'Acerca del producto',
				'extra info and instructions': 'Información adicional',
				'supervisions': 'Kashrut',
				'usage warnings': 'Advertencias de uso',
				'storage instructions': 'Instrucciones de almacenamiento.',
				'usage instructions': 'Direcciones de uso',
				'fat percentage': 'Porcentaje de grasa',
				'cream percentage': 'Porcentaje de crema',
				'fruit percentage': 'Porcentaje de frutas',
				'alcohol percentage': 'Porcentaje de alcohol',
				'ph': '(pH)',
				'country of origin': 'Pais de origen',
				'date taken into effect': 'Fecha de vigencia',
				'height': 'Alto',
				'depth': 'Largo',
				'width': 'Ancho',
				'net width': 'Ancho neto',
				'gross width': 'Ancho bruto',
				'general information': 'Información general',
				'diet type': 'Tipo de dieta',
				'the waiting period will not exceed two hours': 'Envio en 2 horas',
				'at_hour': 'a',
				'special_reminder_bubble_title': 'AHORRA!',
				'special_reminder_awarded_bubble_title': 'Ahorraste!',
				'special_reminder_button_title': 'Click & AHORRA!',
				'add products to earn this promotion': 'Agrega productos para aplicar a esta promocion',
				'done': 'Listo',
				'select your benefit': 'Seleccione su beneficio',
				'more specials': 'Más ofertas',
				'show more specials': 'Mostrar mas ofertas',
				'show less specials': 'No mostrar ofertas',
				'coupon': 'Cupón',
				'coupon_requirements_error': 'Cupón no aplica',
				'remaining credit': 'Crédito',
				'cart amount higher': 'Monto del carrito exedido por',
				'than your credit': 'de su credito',
				'edit cart': 'Modificar carrito',
				'cart total': 'Total de carrito',
				'to complete the purchase, remove products from the cart valued at': 'Para finalizar la compra debe retirar artículos del carrito por valor de',
				'payment by credit': 'pago a credito',
				'this user is not set as credit customer': 'El usuario no es cliente de credito',
				'cart amount higher than your credit. to proceed please remove at least': 'Para finalizar la compra debe retirar artículos del carrito por valor de',
				'valid until': 'Válido hasta',
				'please verify that you are not a robot': 'Por favor verificar que no es automatizado',
				'attachment.size - invalid attachment, file bigger then 2mb': 'Invalid attachment size: bigger than 2MB',
				'attachment.originalname - invalid attachment name': 'Error: invalid attachment name',
				'attachment.mimetype - invalid attachment, file type not allowed': 'Error: invalid attachment file type',
				'all conditions must be marked in order to complete the order': 'Todas las condiciones deben estar marcadas para completar el pedido.',
				'employee discount': 'Descuento para empleados',
				'card id': 'Tarjeta de identificacion',
				'discount': 'Descuento',
				'remaining obligo': 'Obligo restante',
				'monthly obligo': 'Obligo mensual',
				'club name': 'Nombre del club',
				'obligo type': 'Tipo obligo',
				'monthly discount obligo': 'Obligo de descuento mensual',
				'cardid: required error': 'Card Id is required',
				'pin: required error': 'Pin: error requerido',
				'employee discount card holder?': '¿Titular de la tarjeta de descuento para empleados?',
				'employee discount card data': 'Datos de la tarjeta de descuento para empleados',
				'employee discount account not found': 'Cuenta de descuento para empleados no encontrada',
				'estimated employee savings': 'Ahorros estimados de los empleados',
				'getting data...': 'Recibiendo información….',
				'error': 'Error',
				'what\'s your lifestyle?': '¿Cual es tu estilo de vida?',
				'filter entire store': 'Filtrar toda la tienda',
				'filter the entire store according to your preferred lifestyle or diet!': '¡Filtre toda la tienda de acuerdo con su estilo de vida o dieta preferidos!',
				'clear all filters': 'Limpiar todos los filtros',
				'all filters': 'Todos los filtros',
				'filtering store': 'Filtrando la tienda',
				'the entire store is now filtered by': 'La tienda esta filtrada por',
				'store is filtered': 'La tienda esta filtrada',
				'back to filters': 'Volver a filtros',
				'we couldn\'t find any products': 'No se ha encontrado ningún producto',
				'we couldn\'t find any specials': 'No se ha encontrado a oferta',
				'clear_all_filters_2': 'Limpiar los filtros',
				'try editing your filters': 'Trate de editar los filtros',
				'permanent': 'Permanente',
				'permanent_filters_legal_text': 'Al utilizar las diversas opciones de filtrado que se ofrecen en el sitio, la Compañía no garantiza que no habrá mal funcionamiento y / o errores en el sitio, incluso en el desempeño del filtrado en particular. La Compañía no se compromete a que la categorización de productos sea precisa y excluya productos que no cumplan con los criterios requeridos ni que todos los productos presentados cumplan con los criterios. El uso de la información obtenida es de su exclusiva responsabilidad, incluso con respecto a cualquier daño causado como resultado del uso de la Información o la confianza en ella. Cada producto, incluidos sus componentes, debe probarse individualmente. Usted será responsable de verificar cualquier información con los datos del fabricante para ese producto y, en caso de discrepancia entre la información presentada en el sitio y la información presentada por el fabricante, se debe seguir la información presentada por el fabricante. .Lo anterior no creará responsabilidad, ya sea por daños indirectos o directos, de la Compañía en relación con la información mostrada por el fabricante de cualquier producto.',
				'pause': 'Pausa',
				'play': 'Reproducir',
				'required fields are marked with *': 'Campos obligatorios marcados con *',
				'select language': 'Select language',
				'retailer data': 'Datos del minorista',
				'banner link': 'Enlace de banner',
				'map': 'Mapa',

				'verify account': 'Verificar la cuenta',
                'there was a problem verifying your account': 'Hubo un problema al verificar su cuenta',
                'please contact customer service': 'Por favor póngase en contacto con el servicio al cliente',
				'you are now registered': 'estas registrado',
				'please verify your account': 'Por favor verifica tu cuenta',
				'before your first purchase, a verification code will be sent to your mobile phone': 'Antes de su primera compra le será enviado un código de verificación a su celular',
				'send sms': 'Mandar SMS',
				'enter the code that was sent to your phone': 'Ingrese el código que llego a su celular',
				'change number': 'Cambiar numero',
				'verify': 'Verificar',
				'i didn\'t receive the code (resend)': 'No recibi el código, reenviar',
				'the code is invalid or expired': 'El código es invalido/expirado',
				'your account is verified': 'Su cuenta fue verificada',
				'invalid verification code': 'Código de verificación invalido',
				'you have successfully registered': 'Registro exitoso',
				'for the customer club': 'para el club de clientes',
				'for the customer club and the website': 'para el club de clientes y el sitio web',
				'loyalty club details': 'detalles del club de fidelidad',
				'loyalty_terms_confirm': 'Confirmo que he leído el',
				'loyalty terms': 'Reglas del club',
				'website registration': 'Registro en la pagina',
				'join club': 'Unirse al club',
				'loyaltypolicyapproval: required error': 'Acepte los términos y la política de fidelidad',
				'personalid: minlength error': 'Ingrese un número de 9 dígitos',
				'personalid: maxlength error': 'Ingrese un número de 9 dígitos',
				'personalid: number error': 'Solo se deben escribir dígitos',
				'please let me know about promotions on site and club': 'Por favor avíseme sobre las promociones en el sitio y el club.',
				'phonenum: required error': 'Please enter your phone number',
				'phonenum: pattern error': 'Not a valid phone number',
				'organization': 'Compañia',
				'organization management': 'Gestión de la organización',
				'detach branch': 'Separar rama',
				'go to organization': 'Ir a la organización',
				'user added to organization': 'Usuario agregado a la organización',
				'user removed from organization': 'Usuario eliminado de la organización',
				'organization created': 'Organización creada',
				'organization data changed': 'Organization data changed',
				'organization activated': 'Organización activada',
				'organization deactivated': 'Organización desactivada',
				'organization obligo changed': 'Organization obligo changed',
				'role': 'Rol',
				'remaining budget': 'Presupuesto restante',
				'select branch': 'Seleccionar rama',
				'users': 'Usuarios',
				'admin': 'Admin',
				'max length of phone number is 10': 'La longitud máxima del número de teléfono es 10',
				'payment by organization credit': 'Pago mediante crédito de la organización',
				'sorry, your organization account is inactive': 'Lo siento, la cuenta de su organización está inactiva',
				'sorry, there are no branches assigned to this organization': 'Lo siento, no hay sucursales asignadas a esta organización',
				'please contact your organization admin': 'Por favor contacte al administrador de su organización',
				'total assigned': 'Total asignado',
				'credit cards can not be added. you are assigned to an organization': 'No se pueden agregar tarjetas de crédito. Estás asignado a una organización',
				'associated branch': 'Rama asociada',
				'budget history': 'Historial presupuestario',
				'organization budget': 'Presupuesto de la organización',
				'remaining organization budget': 'Presupuesto restante de la organización',
				'overall budget for users': 'Presupuesto general para los usuarios',
				'user': 'Usuario',
				'changed by user': 'Cambiado por el usuario',
				'action': 'Acción',
				'action sum': 'Suma de acciones',
				'remaining user credit': 'Crédito de usuario restante',
				'remaining organization credit': 'Crédito restante de la organización',
				'user credit update': 'Actualización de crédito de usuario',
				'credit update': 'Actualización de crédito',
				'data update': 'Actualización de datos',
				'set as credit customer': 'Establecer como cliente de crédito',
				'unset credit customer': 'Cliente de crédito no establecido',
				'order canceled in backend': 'Orden cancelada en trastienda',
				'order changed in backend': 'Orden modificada en trastienda',
				'order renewed in backend': 'Orden reactivada en trastienda',
				'user checkout': 'Finalizar orden',
				'user order edit': 'Editar orden',
				'collection app edit': 'Editado por el colector',
				'collection app canceled': 'Cancelado por el colector',
				'collection app finished': 'Finalizado por el colector',
				'changed by pos': 'Modificado por el POS',
				'order refunded': 'Orden devuelta',
				'remove selection': 'Quitar seleccion',
				'payment cancelled': 'Pago cancelado',
				'payment expired': 'Pago expirado',
				'bit logo': 'Logo Bit',

				'cancel order': 'Cancelar orden',
				'are you sure you want to cancel this order': 'Esta seguro que desea cancelar la orden?',
				'order #': 'Número de orden',
				'keep the order': 'Mantener la orden',
				'your order was cancelled': 'Su orden fue cancelada',
				'this order will not be charged': 'Su orden no será cobrada',
				'your payment method will not be charged': 'Su método de pago no se cobrará',
				'a confirmation email was sent to {email}': 'Se envió un correo electrónico de confirmación a {email}',
				'no available deliveries in the upcoming days': 'No hay envios disponibles para los próximos dias',
				'home page': 'Página principal',
				'days': 'Días',
				'business days': 'Días laborales',
				'delivery within': 'Entrega dentro',
				'within': 'dentro',
				'delivery price': 'Precio de entrega',
				'we will contact you in order to': 'Nos pondremos en contacto contigo para',
				'schedule the delivery hours': 'Programar el horario de entrega',
				'remove item from cart': 'Eliminar artículo del carrito',
				'to the selected delivery area we only send products marked with \'{tagname}\' tag': 'Al área de entrega seleccionada, solo enviamos productos marcados con la etiqueta \'{tagname}\'',
				'you can either remove the product from the cart or change the delivery area': 'Puede retirar el producto del carrito o cambiar el área de entrega',
				'or change the delivery area': 'O cambiar el área de entrega',
				'ordered': 'Ordenado',
				'accessibility statement': 'Declaración de accesibilidad',
				'accessibility arrangements': 'Arreglos de accesibilidad',
				'accessibility_statement_p1': 'En {retailerName}, creemos que nuestros servicios deben estar disponibles para cualquier persona y nos comprometemos a proporcionar un sitio web que sea accesible para la audiencia más amplia posible, independientemente de las circunstancias y la capacidad. Trabajamos arduamente para hacer y mantener este sitio accesible siguiendo el nivel de conformidad AA de WCAG 2.0, que es el estándar israelí 5568 por ley.',
				'accessibility_statement_p2': 'Migdalor, un centro multiservicio para personas ciegas y con discapacidad visual, auditó este sitio para verificar su accesibilidad y solucionamos todos los problemas que se encontraron en el informe de auditoría. Tenga en cuenta que, aunque estamos trabajando para mantener este sitio accesible, es posible que algunas partes no sean lo suficientemente accesibles.',
				'accessibility_statement_p3': 'Aquí está la accesibilidad que sabemos que estamos proporcionando en este sitio:',
				'accessibility_point_1': '* Este sitio se puede ver y navegar a través de diferentes tamaños de pantalla y plataformas sin perder información.',
				'accessibility_point_2': '* Puede ampliar el atajo de teclado de texto: \'CTRL\' y (control más). Lo ampliará en un 10% cada vez que presione la tecla + (más). Para reducirlo, use el atajo de teclado: \'CTRL\' y \'-\' (control menos).',
				'accessibility_point_3': '* Puede navegar y activar todas las funciones en el sitio usando solo el teclado.',
				'accessibility_point_4': '* La estructura de las páginas es semántica y conforme al estándar.',
				'accessibility_point_5': '* Tecnología de asistencia: este sitio es accesible y está disponible para los usuarios de lectores de pantalla. Para una navegación óptima, recomendamos usar el lector de pantalla NVDA o la voz en off de iOS.',
				'accessibility_point_6': '* Encabezados jerárquicos',
				'accessibility_point_7': '* Descripción significativa de las imágenes',
				'accessibility_point_8': '* Enfoque visible',
				'accessibility_point_9': '* Orden de enfoque simple y significativo',
				'accessibility_point_10': '* Notificación de error en el formulario de contacto',
				'please enter your house number': 'Por favor ingrese su número de casa',
				'so we can assign you to the right branch': 'Para que podamos asignarte a la rama correcta',

				'your products from a website': 'Sus productos desde un sitio web',
				'inventory availability may vary according to the delivery area': 'La disponibilidad de inventario puede variar según el área de entrega',
				'please note - we could not identify {number}': 'tenga en cuenta que no pudimos identificar {número}',
				'more products': 'Más productos',
				'more product': 'Más producto',
				'from the {name} website': 'Del sitio web de {nombre}',
				'out of stock': 'Agotadoי',
				'the site is active only for organization users': 'El sitio está activo solo para usuarios de la organización',
				'please login or register': 'Por favor, inicie sesión o regístrese',
				'sorry, this website is for organization users only': 'Lo siento, este sitio web es solo para usuarios de organizaciones',
				'you user is not assigned to an organization': 'Su usuario no está asignado a una organización',
				'sorry, you can not register with this email address': 'Lo siento, no puedes registrarte con esta dirección de correo electrónico',
				'your account has not yet been verified via email - please verify by clicking on the link sent to your email inbox': 'Su cuenta aún no ha sido verificada por correo electrónico; verifique haciendo clic en el enlace enviado a la bandeja de entrada de su correo electrónico',
				'send again': 'Enviar de nuevo',
				'didn\'t receive an email': 'No recibí un correo electrónico',
				'user email address not found': 'Dirección de correo electrónico del usuario no encontrada',
				'a verification email will be sent to your email box. the link is valid for {link_ttl} minutes': 'Se enviará un correo electrónico de verificación a su casilla de correo electrónico. El enlace es válido por {link_ttl} minutos',
				'your account requires activation, email with activation link have been sent to your email. the link is valid for {link_ttl} minutes': 'su cuenta requiere activación, se ha enviado un correo electrónico con el enlace de activación a su correo electrónico. el enlace es válido por {link_ttl} minutos.',
                'your account requires activation.': 'Su cuenta requiere activación.',
                'email with activation link have been sent to your email.': 'Un correo electrónico con el enlace de activación sera enviado.',
                'the link is valid for {link_ttl} minutes': 'El enlace es válido por {link_ttl} minutos.',
				'activation code expired': 'El código de activación venció',
				'website login': 'Inicio de sesión del sitio web',
				'send a new link': 'Enviar un nuevo enlace',
				'sorry, this link has expired': 'Lo siento, este enlace ha caducado',
				'a new link can be sent': 'Se puede enviar un nuevo enlace',
				'you have successfully registered for the site': 'Se ha registrado con éxito en el sitio',
				'this account has already been verified': 'Esta cuenta ya ha sido verificada',
				'you may enter the trade site': 'Puede ingresar al sitio comercialר',
				'this account is an organizational account. you may need to verify it similarly from time to time': 'Esta cuenta es una cuenta de organización. es posible que deba verificarlo de manera similar de vez en cuando',
				'please verify your identity': 'Verifique por favor su Identidad',
				'to verify your identity, please enter a phone number': 'Para verificar su identidad, ingrese un número de teléfono',
				'continue to registration': 'Continuar con el registro',
				'phone number does not match': 'El número de teléfono no coincide',
				'user invitation not found': 'Invitación de usuario no encontrada',
				'user already registered': 'Usuario ya registrado',
				'phone number hint': 'Pista de número de teléfono',
				'sorry, there was an authentication error': 'Lo sentimos, hubo un error de autenticación',
				'please try again or contact the person in charge of the organization': 'Inténtelo de nuevo o póngase en contacto con el responsable de la organización.',

				'add users': 'Agregar usuarios',
				'adding users': 'Agregando usuarios',
				'remove users': 'Eliminar usuarios',
				'remove user': 'Eliminar usuario',
				'please select a file': 'Por favor seleccione un archivo',
				'search for a user by phone or email': 'Buscar un usuario por teléfono o correo electrónico',
				'download csv example': 'Descargar ejemplo de csv ',
				'uploading a csv file': 'Subiendo un archivo csv',
				'file upload complete. a summary email will be sent to your inbox': 'Carga de archivo completa. se enviará un correo electrónico de resumen a su bandeja de entrada',

				'in order to add users to the organization, a csv file must be uploaded with the users information': 'Para agregar usuarios a la organización, se debe cargar un archivo csv con la información de los usuarios',
				'once added to the system, an identity verification email will be sent to each of the users': 'Una vez agregado al sistema, se enviará un correo electrónico de verificación de identidad a cada uno de los usuarios',
				'only after identity verification, users will be able to complete the registration process for the site': 'Solo después de la verificación de identidad, los usuarios podrán completar el proceso de registro para el sitio',
				'in order to remove users from the organization, a csv file must be uploaded with the users details': 'Para eliminar usuarios de la organización, se debe cargar un archivo csv con los detalles de los usuarios',
				'pay attention! these users will no longer be able to access the organization website': '¡Preste atención! estos usuarios ya no podrán acceder al sitio web de la organización',
				'the file must contain at least one user with a phone number and email': 'El archivo debe contener al menos un usuario con un número de teléfono y correo electrónico',
				'user_verification_status_1': 'No verificado',
				'user_verification_status_2': 'No verificado',
				'user_verification_status_3': 'Verificado',
				'please confirm removing user from the organization': 'Por favor confirme la eliminación del usuario de la organización.',
				'user removed from the organization': 'Usuario eliminado de la organización',
				'order_organization_confirm: required error': 'Please enter order confirmation number',
				'account not verified': 'Cuenta no verificada',
				'this account is an organizational account. you account verification has expired': 'Esta cuenta es una cuenta de organización. La verificación de su cuenta ha expirado',
				'account verification succeed': 'Verificación de cuenta exitosa',

				'this user may already be on the system': 'Es posible que este usuario ya esté en el sistema',
				'please try to login': 'Intente iniciar sesión',
				'fullnameinput: required error': 'Favor ingrese su nombre completo',
				'cityinput: required error': 'Favor ingrese su ciudad',
				'register_privacy_policy': 'Politica de privacidad',
				'shippingcarrier: required error': 'Este campo es requerido',

				'i agree to the terms & conditions and privacy policy': 'Estoy de acuerdo con los términos y condiciones de las políticas de privacidad',
				'your credit and debit cards': 'Sus tarjetas de crédito y debito',
				'enter your credit on file id :': 'Ingrese su numero de cuenta de credito:',
				'sorry, currently we don\'t deliver to this address.': 'Lo sentimos, pero actualmente no hacemos envíos a esta dirección',
				'items in cart must be a total of {price} or more': 'Los artículos en el carrito deben sumar un total de {price}, o más',
				'please add additional products to the cart to proceed': 'Por favor, añada productos adicionales al carrito para continuar',
				'please check your email for order details. thank you!': 'Por favor revise su e-mail para detalles de la orden. Gracias',
				'canceling update': 'Cancelando actualización',

				'we use cookies to improve your experience': 'Usamos cookies para mejorar tu experiencia',
				'we use cookies or other trackers to facilitate the use of the site, improve the performance and security of the site, personalize the content offered, including personalized advertising content, and compile traffic and navigation statistics. some cookies, other than functional and security cookies, require your consent to be placed.': 'Hacemos uso de cookies u otros rastreadores para facilitar el uso del sitio, mejorar el rendimiento y la seguridad del sitio, personalizar el contenido ofrecido, incluyendo el contenido publicitario personalizado, y compilar estadísticas de tráfico y navegación. Algunas cookies, además de las cookies funcionales y de seguridad, requieren su consentimiento para ser colocadas.',
				'accept': 'Aceptar',
				'accept all cookies': 'Aceptar todas las cookies',
				'setup my cookies': 'Configurar mis cookies',
				'managing cookies': 'Administrar cookies',
				'these cookies allow you to benefit from the services offered by the site, to optimize its use and personalization (in particular personalization of offers and advertisements) depending on your profile.': 'Estas cookies le permitirán gozar de los servicios ofrecidos por el sitio, para optimizar su uso y personalización (en particular, la personalización de ofertas y anuncios) en función de su perfil.',
				'marketing': 'Márketing',
				'marketing cookies are used to improve the relevance of the ads you see and to make sure that you do not see the same ad multiple times.': 'Las cookies de marketing sirven para mejorar la relevancia de los anuncios que ve y para asegurarse de que no vea el mismo anuncio en múltiples ocasiones.',
				'additional features': 'Características adicionales',
				'these cookies make it possible to activate additional features on the site, for example to facilitate your purchases.': 'Estas cookies hacen posible la activación de características adicionales del sitio. Por ejemplo, para facilitar sus compras.',
				'audience measurement': 'Medición de audiencia',
				'these cookies allow us to obtain navigation statistics on our site. we use this data to identify possible malfunctions of the site, to improve the site, for marketing purposes, and in order to personalize your experience on the site.': 'Estas cookies nos permiten obtener estadísticas de navegación en nuestro sitio. Estos datos son utilizados para identificar posibles fallas del sitio, para mejorar el sitio, con fines de marketing y para personalizar su experiencia de navegación en el sitio.',
				'google analytics': 'Google analytics',
				'these cookies allow us to obtain statistics on the number of visits to our site (e.g., number of visits, most viewed pages, etc.). we use this data to understand our users better, check the performance of our content, get insights, and identify possible malfunctions of the site to make improvements.': 'Estas cookies permiten la obtención de estadísticas respecto del número de visitas a nuestro sitio (por ejemplo, número de visitas, páginas más visitadas, etc.). Usamos estos datos para comprender mejor a nuestros usuarios, verificar el rendimiento de nuestro contenido, obtener información e identificar posibles fallas de funcionamiento con el fin de realizar mejoras.',
				'functional (not configurable)': 'Funcionales (no configurables)',
				'these cookies allow you to remember your choices and preferences on your account and to provide features that facilitate the use of the site. they are essential for the proper functioning of the site and are strictly necessary for the provision of a service that you have expressly requested.': 'Estas cookies le permiten recordar sus elecciones y preferencias en su cuenta y proporcionan funciones que facilitan el uso del sitio. Son imprescindibles para el buen funcionamiento del sitio y son los estrictamente necesarios para la prestación de un servicio que ha solicitado expresamente.',

				'user products list not found': 'No se encontró la lista de productos del usuario',
				'user products list is disabled for this retailer': 'La lista de productos del usuario no está habilitada para este tienda',
				'my lists': 'Mis listas',
				'my products': 'Mis productos',
				'my products list': 'Mi lista de productos',
				'your time is precious': 'Tu tiempo es valioso',
				'we have prepared a list of your personal products': 'Hemos preparado una lista con tus productos personales',
				'add products from the list': 'Agregar productos de la lista',
				'in cart': 'En el carrito',
				'the products i usually purchase': 'Mi lista de productos',
				'we have prepared a list of products you usually purchase': 'Hemos preparado una lista con tus productos personales',
				'sitemap': 'Mapa del sitio',
				'pages': 'Paginas',
				'products marked with': 'Productos marcados con',
				'limited quantity': 'Cantidad limitada',
				'tag are limited to': 'la etiqueta se limita a',
				'items only.': 'artículos solamente.',

				'the {product_count} products listed below will be': 'Los {product_count} productos que se muestran a continuación serán',
				'removed from your cart': 'Eliminado de tu carrito',
				'out of stock products': 'Productos agotados',
				'the items below will be removed from your cart': 'Los siguientes articulos serán eliminados de su carrito',
				'back to cart': 'Volver al carrito',
				'confirm': 'Confirmar',
				'expired':'expiré',
				'{product_count} out of stock products will not be supplied': '{product_count} los productos agotados no se suministrarán',
				'{coupons_count} expired coupons cannot be redeemed': '{coupons_count} los cupones caducados no se pueden canjear',
				'registration attempt has been blocked, please contact support': 'Se ha bloqueado el intento de registro, póngase en contacto con el soporte',
				'account has been suspended, please contact support': 'Cuenta suspendida, comuníquese con soporte',
				'delivery-fee-explain-default': 'Se espera que el importe final de los gastos de envío se actualice en el momento de la compra y podría estar sujeto a cambios debido a la zona/hora de entrega o a cualquier descuento adicional. ',
				'delivery_type_1_fee': 'Gastos de entrega',
				'delivery_type_2_fee': 'Gastos de recogida',
				'includes_delivery_type_1_fee': 'Incluye los gastos de entrega',
				'includes_delivery_type_2_fee': 'Incluye los gastos de recogida',
				'not_includes_delivery_fee': '(no incluye los gastos de envío)',

				'your points': 'Sus puntos',
				'buy with points': 'Comprar más puntos',
				'almost expired': 'Casi vencido',
				'coupon clipped': 'Cupón utilizado',
                'add product': 'Añadir producto',
                'product in cart': 'Producto en el carrito',
                'clip coupon': 'Utilizar cupón',
				'clipped externally': 'Cupón desde otro sitio web',
				'personal coupons': 'Cupones personales',
				'may interest you': 'También le puede gustar',
				'clip for {x} points': 'Utilizar por {x} puntos',
				'not enough points': 'No hay suficientes puntos',
				'coupon expires today': 'El cupón caduca hoy',
				'coupon expires in {x} days': 'El cupón caduca en {x} días',
				'clip for': 'Utilizar por',
				'valid': 'Válido',
				'can be redeemed once': 'Se puede canjear una vez',
				'can be redeemed {x} times': 'Se puede canjear {x} veces',

				'last_minute_recommendations_headline': 'tenemos recomendaciones seleccionadas especialmente para usted.',
				'last_minute_recommendations_title_coupons': 'Cupones que pueden ayudarle a ahorrar dinero',
				'last_minute_recommendations_title_products_usually': '¿Tal vez olvidó estos productos?',
				'last_minute_recommendations_title_products_complementary': 'También le puede gustar...',
				'last_minute_recommendations_title_specials_offers': 'Promociones que no querrá perderse',
				'last_minute_recommendations_title_products_offers': 'No querrá perderse estos productos',

				'add_units_special': 'Añada {remaining} unidades para realizar el especial',
				'add_currency_special': 'Añada {remaining} para realizar el especial',
				'add_measure_special': 'Añada {remaining} {unit} para realizar el especial',
				'there are no available items for this promotion': 'No hay artículos disponibles para esta promoción',
				'no_coupons_for_filter': 'Lo siento<br>No pudimos encontrar ningún cupón "{coupon_filter}"\n',
				'search coupon': 'Search coupon',

				'add_units_coupon': 'Añade {remaining} productos más para conseguir el descuento',
				'add_currency_coupon': 'Añade {remaining} productos más para conseguir el descuento',
				'add_measure_coupon': 'Añade {remaining} {unit} más para conseguir el descuento',
				'add_units_cart_coupon': 'Añade {remaining} productos',
				'add_unit_cart_coupon': 'Añade un producto',
				'add_currency_cart_coupon': 'Añade {remaining}',
				'add_measure_cart_coupon': 'Añade {remaining} {unit}',
				'add_units_special_purchase': 'Añade {remaining} más',
				'buy_units_total': 'Compra {total} productos',
				'buy_unit_total': 'Compra {total} productos',
				'buy_currency_total': 'Compra por importe de {total}',
				'buy_measure_total': 'Compra {total} {unit}',

				'suggestions_dialog_header_1': 'Recomendación para los reemplazos',
				'suggestions_dialog_header_2': 'Por favor seleccione un reemplazo de nuestra variedad de productos similares',
				'suggestions_dialog_header_3': 'Agotado',
				'suggestions_dialog_header_4': 'Recomendación para los reemplazos',
				'ok, continue' : 'De acuerdo, CONTINUAR',
                'change chosen time': 'Cambiar la fecha de entrega',

				'hover description for cvv': 'El número CVV («Valor de verificación de la tarjeta») en su tarjeta de crédito o débito es un número de 3 dígitos en las tarjetas de las marcas VISA, Mastercard y Discover. En su tarjeta de crédito o débito de American Express es un código numérico de 4 dígitos.',
				'enter cvv number': '',
				'select pickup time': 'seleccionar hora de recogida',
				'select delivery time': 'seleccione el tiempo de entrega',
				'please notice, the expiration of these {count} {type} will not be valid for this chosen time': 'Por favor, ten en cuenta que la fecha de vencimiento de estos {count} {type} no será válida para este tiempo seleccionado',
				'the coupon displayed below, will not be valid for this chosen time': 'El cupón que se muestra a continuación no será válido para este tiempo seleccionado',
				'these {count} coupons will not be valid for this chosen time': 'Estos {count} cupones no serán válidos para este tiempo seleccionado',
				'please notice, the coupon displayed below, will not be valid for this chosen time': 'Por favor, ten en cuenta que el cupón mostrado a continuación no será válido para este tiempo elegido',
				'please notice, the special displayed below, will not be valid for this chosen time': 'Por favor, ten en cuenta que la oferta especial mostrada a continuación no será válida para este período seleccionado',
				'price does not include bottle deposit': 'El precio no incluye el depósito de la botella',
				'price includes bottle deposit': 'El precio incluye el depósito de la botella',
				'gift_card_error_message': 'No hemos podido procesar su TARJETA REGALO, por favor, inténtelo de nuevo o pruebe con otra tarjeta',
				'wrong_gift_card_amount_error': 'El monto excede el valor del carrito de compras',
				'amount_exceeds_balance': 'El importe es superior al saldo',
				'customercredit': 'Credito al cliente',
				"sorry, currently we don't deliver to this address for further inquiries call to the customer service.": 'Lo sentimos, actualmente no hacemos envíos a esta dirección. Para más consultas, llame al servicio de atención al cliente.',
				'gg_map_dialog_title': 'Elija una dirección',
				'gg_map_dialog_hint': 'Estamos teniendo problemas para identificar su ubicación exacta. Asegúrese de colocar el pasador con gran precisión. Esto nos ayudará a determinar su dirección exacta',
				'autocomplete_adrs_didnt_find': '¿No encontró su dirección?',
				'autocomplete_adrs_choose_location': 'Seleccione su ubicación en el mapa',
				'sorry, your city was not found. please select an address from auto-complete suggestions or pin your address using the google maps option.': 'Lo sentimos, su ciudad no fue encontrada. Seleccione una dirección de las sugerencias de autocompletar o señale su dirección usando la opción de Google Maps.',
				'sorry, your address was not found. please select an address from auto-complete suggestions or pin your address using the google maps option.': 'Lo sentimos, no se encontró su dirección. Seleccione una dirección de las sugerencias de autocompletar o señale su dirección usando la opción de Google Maps.',
				'sorry, your zip code was not found. please select an address from auto-complete suggestions or pin your address using the google maps option.': 'Lo sentimos, no se ha encontrado su código postal. Por favor, seleccione una dirección de las sugerencias de autocompletado o fije su dirección utilizando la opción de Google Maps.',
				'country_of_origin_title': 'País de origen',
				'origin_country_legal_text': 'Tenga en cuenta que, si bien el país de origen indicado es exacto en la fecha de pedido, el país de origen real del producto entregado puede variar en función de las existencias disponibles en el punto de recogida durante la entrega',
				'after package deduction': 'Después de la deducción del paquete',
				'gg_map_alert_need_location': 'Por favor, activa la ubicación para usar Google Maps.',
				'gg_map_alert_error': 'Hay algunos problemas al mostrar Google Maps.',
				'gg_map_alert_unsupport': 'La geolocalización no es compatible con este navegador.',
				'country: required error': 'Por favor ingresa un país',
				'january': 'Enero',
				'february': 'Febrero',
				'march': 'Marzo',
				'april': 'Abril',
				'may': 'Puede',
				'june': 'Junio',
				'july': 'Julio',
				'august': 'Agosto',
				'september': 'Septiembre',
				'october': 'Octubre',
				'november': 'Noviembre',
				'december': 'Diciembre',
				'redeemed': 'redimidos',
				'points gained': 'puntos ganados',
				'source': 'Fuente',
				'when': 'Cuando',
                'retrieving data. wait a few seconds and try to cut or copy again': '¿Esta dirección es correcta? Ahora puede editarla y refinarla',
				'is this address correct? you can now edit and refine it': '¿Esta dirección es correcta? Ahora puede editarla y refinarla',
                'edit your address': 'Edite su dirección',
				'searchtext_storename_placeholder': '{Nombre de la tienda}',
				'contact_us': 'Contáctenos',
                'how_can_we_help_you_today': '¿Cómo podemos ayudarle hoy?',
                'leave_us_a_message': 'Déjenos un mensaje y lo contactaremos pronto',
                'fullname': 'Nombre completo',
                'message': '¿Cómo podemos ayudarle?',
                'your_message_has_been_sent': 'Tu mensaje ha sido enviado',
                'thank_you_for_contact_us': 'Gracias por contactarnos',
				'balance to be paid': 'Saldo a pagar',
				'redeem': 'Cajear',
				'please save the voucher or gift card until you receive your order and are charged': 'Por favor, guarde el cupón o tarjeta de regalo hasta que reciba su pedido y se realice',
				'balance': 'Saldo',
				'choose gift card/s': 'Elegir TARJETA(S) DE REGALO',
				'add gift card': 'Agregar tarjeta de regalo',
				'redeem gift card & discount cards': 'Para canjear una TARJETA DE REGALO',
				'card number': 'número de tarjeta',
				'please enter the card number to check the balance': 'Introduzca el número de tarjeta para consultar el saldo',
				'customer support phone number': 'Número de teléfono de atención al cliente',
				'complete_and_proceed_to_checkout': 'Completar y proceder al pago',
				'please notice, the following products are not available on this chosen time': 'Tenga en cuenta que los siguientes artículos no están disponibles para el momento elegido',
				'the following products are not available on this chosen time': 'Tenga en cuenta que los siguientes artículos no están disponibles para el momento elegido',
				'if you continue, they will be removed from your cart': 'Si continúa, se retirarán del carrito',
				'result for “{title}”': 'Resultados para “{title}”',
				'find a product': 'Encontrar un producto',
				'brand Results:': 'Resultados por marca:',
				'please enter cvv to continue': 'Por favor, introduzca el CVV para continuar',
				'checkout is opened on another screen': 'El pago se abre en otra pantalla',
				'please wait, we are loading the products': 'Por favor espere, estamos subiendo los productos',
				'loading coupons, please wait...': 'Cargando cupones. Por favor, espere...',
				'verification challenge expired. check the checkbox again.': 'La verificación ha caducado. Vuelve a marcar la casilla de verificación.',
                'receipt': 'Recibo',
				'address_form_field_label_city': 'Ciudad',
				'address_form_field_label_address': 'Dirección',
				'address_form_field_label_zip_code': 'Código postal',
				'address_form_field_label_postal_code': 'Código postal',
				'address_form_field_label_entrance': 'Entrada',
				'address_form_field_label_apartment_number': 'Número de apartamento',
				'address_form_field_label_building_code': 'Reglamento de construcción',
				'address_form_field_label_state': 'Estado',
				'address_form_field_label_comments': 'Comentarios',
				'address_form_field_label_apartment_suite_unit': 'Apartamento / Suite / Unidad',
                'address_form_field_label_providence': 'Previsión',
                'address_form_field_label_more_details': 'Más información',
                
                'address_drop_pin_message_1': 'Ubicación guardada con éxito. Edita si es necesario.',
                'address_drop_pin_message_cancel': 'Cancelar dirección fijada',

				'autocomplete_city_not_found_non_droppin': 'Lo sentimos, no se ha encontrado su ciudad. Por favor, seleccione una dirección de la lista o póngase en contacto con el servicio de asistencia para obtener ayuda.',
				'autocomplete_address_not_found_non_droppin': 'Lo sentimos, no se ha encontrado su dirección. Por favor, seleccione una dirección de la lista o póngase en contacto con el servicio de asistencia.',
				'autocomplete_zip_code_not_found_non_droppin': 'Lo sentimos, no se ha encontrado su código postal. Por favor, seleccione una dirección de la lista o póngase en contacto con el servicio de asistencia para obtener ayuda.',	

                'address_form_field_error_city: required error': 'Por favor ingrese un Ciudad válido',
                'address_form_field_error_address: required error': 'Por favor ingrese un Dirección válido',
                'address_form_field_error_zip_code: required error': 'Por favor ingrese un Código postal válido',
                'address_form_field_error_postal_code: required error': 'Por favor ingrese un Código postal válido',
                'address_form_field_error_entrance: required error': 'Por favor ingrese un Entrada válido',
                'address_form_field_error_apartment_number: required error': 'Por favor ingrese un Número de apartamento válido',
                'address_form_field_error_building_code: required error': 'Por favor ingrese un Reglamento de construcción válido',
                'address_form_field_error_state: required error': 'Por favor ingrese un Estado válido',
                'address_form_field_error_comments: required error': 'Por favor ingrese un Comentarios válido',
                'address_form_field_error_apartment_suite_unit: required error': 'Por favor ingrese un Apartamento / Suite / Unidad válido',
                'address_form_field_error_providence: required error': 'Por favor ingrese un Previsión válido',
                'address_form_field_error_more_details: required error': 'Por favor ingrese un Más información válido',
                
                'account_details_form_personal_details_title': 'Detalles personales',
                'account_details_form_personal_details_save_btn': 'Guardar datos personales',
                'account_details_form_address_title': 'Dirección',
                'account_details_form_address_save_btn': 'Guardar dirección',

				'pickup time:': 'Fecha de recogida:',
                'pickup address:': 'Dirección de recogida:',
                'delivery time:': 'Fecha de entrega:',
                'delivery address:': 'Dirección de entrega:',
                'on_day': '',
                'wish_to_change_delivery':'¿Desea cambiar la fecha de entrega?',
                'wish_to_change_pickup':'¿Desea cambiar la fecha de recogida?',
                'select date & time':'Seleccionar fecha y hora',
                'save & change delivery time':'Guardar y cambiar fecha de entrega',
                'save & change pickup time':'Guardar y cambiar fecha de recogida',
                'back to home page':'Volver a la página principal',
                'your order has been updated':'Su pedido se actualizó',

				'checkout_finish_last_update_time_delivery': 'Puede cambiar la fecha de entrega hasta',
				'checkout_finish_last_update_time_pickup': 'Puede cambiar la fecha de recogida hasta',
				'forgot_sth_want_to_change_delivery': '¿Desea cambiar la fecha de entrega?',
				'forgot_sth_want_to_change_pickup': '¿Desea cambiar la fecha de recogida?',

				'update_order_popup_title_def': 'Puede actualizar los artículos de su pedido o la hora de {delivery method}.',
				'update_order_popup_order_item_def': 'cambiar productos',
				'update_order_popup_delivery_time_def': 'cambiar entrega',
				'update_order_popup_pickup_time_def': 'cambiar recogida',
				'update_order_popup_cancel_def': '¿Desea cancelar su pedido? Haga clic aquí para cancelarlo {cancel order}',
				'update_order_popup_lastest_time_def': 'Puede actualizar la hora de su pedido {delivery method} hasta {time calculate}',
				'update_order_popup_err_cant_update_time': 'No es posible actualizar la hora de {delivery method}.',

				'irre_sales_collect_one': 'Este {saleType} no es válido para la fecha actualizada y se cobrará a su precio regular',
				'irre_sales_collect_many': 'Estos {count} {saleType} no serán válidos para esta fecha actualizada y se cobrarán a su precio normal',
				'irre_sales_checkout_one': 'Al actualizar este pedido, los siguientes {saleType} dejarán de ser válidos y el artículo se cobrará a su precio habitual',
				'irre_sales_checkout_many': 'Al actualizar este pedido, los siguientes {count} {saleType} dejarán de ser válidos, y los artículos se cobrarán a su precio normal',
				'irre_sales_oos_one': 'Estos artículos no están disponibles yse eliminarán del carrito',
				'irre_sales_oos_many': 'Estos artículos no están disponibles yse eliminarán del carrito',
				'irre_sales_btn_continue': 'sí, actualizar',
				'irre_sales_btn_change_slot': 'Modificar fecha de {delivery method}',
				'irre_sales_btn_cancel_update': 'Cancelar actualización',
				
				'confirm_full_credit_card_charge_title': 'Confirmar cargo completo a tarjeta de crédito',
                'confirm_full_credit_card_charge_content': 'Monto de pago EBT configurado en $0. ¿Estás seguro de que quieres cargar el monto completo a tu tarjeta de crédito?',
                'confirm_full_credit_card_charge_content_btn_confirm': 'Sí, cargar a la tarjeta de crédito',
                'confirm_full_credit_card_charge_content_btn_back': 'Volver',
				'thank you for your order!': '¡Gracias por su pedido!',
				'api_error_message': 'Se ha producido un error{error_message} (error {error_code}). inténtelo de nuevo en unos minutos, si el problema se repite póngase en contacto con el servicio de atención al cliente',
				'payment error': 'Error en el pago',
				'error received': 'Error detectado',
				'please check you credit card details or contact your credit card provider': 'Compruebe los datos de su tarjeta de crédito o póngase en contacto con su proveedor.',

				"add your credit card": "Añada su tarjeta de crédito",
				"payment methods": "Métodos de pago",
				"your credit cards": "Sus tarjetas de crédito",
				"add a credit card": "Añadir tarjeta de crédito",
				"gift cards": "Tarjetas de regalo",
				"add gift cards": "Añadir tarjetas de regalo",
				"choose gift card/s": "Elegir TARJETA(S) DE REGALO",
				"please enter the card number to check balance": "Introduzca el número de tarjeta para consultar el saldo",
				"{gift card name} details": "Información de {Gift card name}",
				"on the next screen, you can select your payment amount": "En la siguiente pantalla, puede seleccionar el importe del pago",
				"balance:": "Saldo:",
				"please enter amount to pay": "Introduzca el importe a pagar",
				"amount exceeds balance": "El importe es superior al saldo",
				"please save the voucher or gift card until you receive your order and are charged.": "Por favor, guarde el vale o la tarjeta de regalo hasta que reciba su pedido y se le cobre.",
				"remove card": "Retirar tarjeta",
				"see more": "ver más",
				"show less": "Ver menos",
				"amount exceeds shopping cart value": "El monto excede el valor del carrito de compras",
				'delivery_disclaimer_default_text':'Las ventanas de entrega solo se pueden seleccionar durante el proceso de pago',
				'missing premise or street number': 'Número de calle faltante',
				'amount to pay': 'Importe a pagar',
				'you_can_not_add_more_than':'No puedes agregar al carrito de compras más de {cart} artículos marcados con este símbolo',
			};
		}]);

})(angular, app);
