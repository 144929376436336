(function (angular, app) {
    'use strict';
    app.controller('AddGiftCardCtrl', [
        '$rootScope', '$scope', '$filter', '$timeout', 'Config', 'Cart', 'PaymentsService', 'options', 'Dialog', 'SP_PAYMENTS', 'User', 'Util', 'summaryCtrl',
        function ($rootScope, $scope, $filter, $timeout, Config, Cart, PaymentsService, options, dialog, SP_PAYMENTS, user, util, summaryCtrl) {
            var addGiftCardCtrl = this,
                _listeners = [],
            sortType = {
                'ASC': 0,
                'DESC': 1
            },
            _roundCurrencyFilter = $filter('roundCurrency');
            addGiftCardCtrl.giftCards = options.giftCards || [];
            //addGiftCardCtrl.redeemedGiftCards = options.redeemedGiftCards || {};
            addGiftCardCtrl.mainPaymentMethod = options.mainPaymentMethod;
            addGiftCardCtrl.maxAmount = options.maxAmount || null;
            addGiftCardCtrl.giftCardsData = options.giftCardsData || {};
            addGiftCardCtrl.removedGiftCards = [];
            addGiftCardCtrl.giftCardsIndex = {};
            addGiftCardCtrl.giftCardsSettings = Config.retailer.settings.giftCardsSettings ? JSON.parse(Config.retailer.settings.giftCardsSettings) : {};
            addGiftCardCtrl.total = Cart.total.finalPriceWithTax + Cart.total.serviceFee.finalPriceWithTax + Cart.total.deliveryCost.finalPriceWithTax;
            addGiftCardCtrl.giftCardPopupSettings = Config.retailer.settings.checkoutDisplaySettings ? JSON.parse(Config.retailer.settings.checkoutDisplaySettings) : {};
            //addGiftCardCtrl.selectedGiftCard = addGiftCardCtrl.giftCards[0].paymentMethodId;
            addGiftCardCtrl.giftCardInputRegExp = new RegExp(/^[0-9.-]*$/);
            addGiftCardCtrl.carouselActions = {
                toggleSelectedGiftCard: toggleSelectedGiftCard,
                showAddCardDialog: showAddCardDialog
            };
            addGiftCardCtrl.toggleEllipsisBtn = toggleEllipsisBtn;
            addGiftCardCtrl.toggleSelectedGiftCard = toggleSelectedGiftCard;
            addGiftCardCtrl.showAddCardDialog = showAddCardDialog;
            addGiftCardCtrl.checkGiftCard = checkGiftCard;
            addGiftCardCtrl.redeemGiftCard = redeemGiftCard;
            addGiftCardCtrl.removeCard = removeCard;
            addGiftCardCtrl.isDisableRedeemBtn = isDisableRedeemBtn;
            addGiftCardCtrl.checkAmount = checkAmount;
            addGiftCardCtrl.checkMaxGiftCardAmount = checkMaxGiftCardAmount;
            addGiftCardCtrl.resetCardDetails = resetCardDetails;
            addGiftCardCtrl.isGiftCardNotEligible = isGiftCardNotEligible;
            addGiftCardCtrl.next = next;
            addGiftCardCtrl.close = close;
            addGiftCardCtrl.isRedeemedGiftCardsExist = isRedeemedGiftCardsExist;
            addGiftCardCtrl.legalText = {};
            addGiftCardCtrl.isValidateCvv = isValidateCvv;

            // If Max Gift Card is enabled, it should not be placed in the first order of the gift card array
            _reorderGiftCards(addGiftCardCtrl.giftCards, SP_PAYMENTS.PAYMENT_METHODS.IDS.MAX_GIFT_CARD);
            addGiftCardCtrl.carouselData = {
                selectedGiftCard: options.selectedGiftCard || addGiftCardCtrl.giftCards[0].paymentMethodId,
                balanceToBePayed: 0,
                redeemedGiftCards: options.redeemedGiftCards || {},
                selectedGiftCardData: addGiftCardCtrl.giftCards[0] || {}
            }

            _init();

            function _init() {
                if(options.isUsedCashbackPoints) {
                    addGiftCardCtrl.total -= options.availableUserCashback;
                }
                _setGiftCardIndex();
                _setGiftCardsData();
                _calculateBalanceToBePayed();
                _setGiftCardDisplay();
                _calculateTextTruncation();
                summaryCtrl._removeRedeemedGiftCard = addGiftCardCtrl.removeCard;

            }

            function toggleEllipsisBtn() {
                addGiftCardCtrl.giftCardDisplay.giftCardsSeeMore = !addGiftCardCtrl.giftCardDisplay.giftCardsSeeMore;

                if(addGiftCardCtrl.giftCardDisplay && addGiftCardCtrl.giftCardDisplay.giftCardsSeeMore && addGiftCardCtrl.originalLegalText) {
                    addGiftCardCtrl.giftCardDisplay.legalText = addGiftCardCtrl.originalLegalText;
                } else {
                    addGiftCardCtrl.giftCardDisplay.legalText = addGiftCardCtrl.truncatedLegalText;
                }
            }

            function toggleSelectedGiftCard (giftCardId) {
                if(addGiftCardCtrl.carouselData.selectedGiftCard === giftCardId) return;

                addGiftCardCtrl.carouselData.selectedGiftCard = giftCardId;
                addGiftCardCtrl.carouselData.selectedGiftCardData = addGiftCardCtrl.giftCards[addGiftCardCtrl.giftCardsIndex[giftCardId]];

                if (addGiftCardCtrl.carouselData.selectedGiftCard === SP_PAYMENTS.PAYMENT_METHODS.IDS.MAX_GIFT_CARD) {
                    return showAddCardDialog(giftCardId);
                }

                _setGiftCardDisplay()
            }

            function showAddCardDialog(giftCardId) {
                if (addGiftCardCtrl.giftCardsData[giftCardId] && addGiftCardCtrl.giftCardsData[giftCardId].token || addGiftCardCtrl.carouselData.redeemedGiftCards[giftCardId] && addGiftCardCtrl.carouselData.redeemedGiftCards[giftCardId].token) {
                    if (giftCardId === SP_PAYMENTS.PAYMENT_METHODS.IDS.MAX_GIFT_CARD) {
                        _setGiftCardDisplay();
                        return;
                    }
                    addGiftCardCtrl.giftCardDisplay.giftCardsSeeMore = false;
                    addGiftCardCtrl.carouselData.selectedGiftCard = giftCardId;
                    return;
                }

                // Open iframe when the max gift card has not been added
                dialog.hide(addGiftCardCtrl.carouselData.redeemedGiftCards);
                if(Config.retailer.settings.useDeliveryAddressAsBilling && summaryCtrl.compiledUserAddress) {
                    return dialog.show({
                        templateUrl: 'template/dialogs/use-delivery-address-as-billing/index.html',
                        controller: ['$scope', function ($dialogScope) {
                            var dialogCtrl = this;
                            dialogCtrl.sendUserAddress = false;
                            dialogCtrl.compiledUserAddress = summaryCtrl.compiledUserAddress;
                            var userAddress = user.data.addresses[0];
                            var userCountryName = userAddress && userAddress.country
                            if (userCountryName) {
                                userAddress.countryCode = util.getCountryCode(userCountryName);
                            }
                            $dialogScope.continue = function () {
                                return setMaxGiftCard(giftCardId, {
                                    sendUserAddress: dialogCtrl.sendUserAddress,
                                    address: userAddress.text1 ? userAddress.text1 : userAddress.text2 ? userAddress.text2 : '',
                                    city: userAddress.city ? userAddress.city : '',
                                    country: userAddress.country ? userAddress.country : '',
                                    countryCode: userAddress.countryCode ? userAddress.countryCode : '',
                                })
                            };
                        }],
                        controllerAs: 'dialogCtrl',
                        styleClass: 'clear-cart-popup'
                    });
                } else {
                    return setMaxGiftCard(giftCardId, {})
                }
            }

            function checkGiftCard (paymentMethodId) {
                addGiftCardCtrl.giftCardsData[paymentMethodId].errorMessage = '';
                var cardNumber = addGiftCardCtrl.giftCardsData[paymentMethodId].number;
                var cvv = addGiftCardCtrl.giftCardsData[paymentMethodId].cvv;
                var giftCard = findGiftCard(paymentMethodId);
                var finalizedNumber = giftCard && giftCard.isCvvRequired &&
                paymentMethodId === SP_PAYMENTS.PAYMENT_METHODS.IDS.NOFSHONIT ? cardNumber + cvv : cardNumber;

                return PaymentsService.getGiftCardBalance(paymentMethodId, finalizedNumber)
                  .then(function (result) {
                      addGiftCardCtrl.giftCardsData[paymentMethodId].balance = _roundCurrencyFilter(result.balance);
                      addGiftCardCtrl.giftCardsData[paymentMethodId].token = result.token;
                      addGiftCardCtrl.giftCardsData[paymentMethodId].lastFourDigits = result.lastFourDigits;
                      addGiftCardCtrl.giftCardsData[paymentMethodId].amount = _roundCurrencyFilter(addGiftCardCtrl.giftCardsData[paymentMethodId].balance < addGiftCardCtrl.carouselData.balanceToBePayed ? addGiftCardCtrl.giftCardsData[paymentMethodId].balance : addGiftCardCtrl.carouselData.balanceToBePayed);
                      addGiftCardCtrl.maxAmount = addGiftCardCtrl.giftCardsData[paymentMethodId].amount;

                  }).catch(function () {
                      addGiftCardCtrl.giftCardsData[paymentMethodId].errorMessage = 'gift_card_error_message';
                  });
            }

            function setMaxGiftCard(paymentMethodId, additionalData) {
                return PaymentsService.addMaxGiftCard(additionalData)
                  .then(function (result) {
                      if (result.paymentData) {
                          // addGiftCardCtrl.giftCardsData[paymentMethodId].balance = null;
                          addGiftCardCtrl.giftCardsData[paymentMethodId].token = result.paymentData.token;
                          addGiftCardCtrl.giftCardsData[paymentMethodId].number = result.paymentData.cardNumber;
                          addGiftCardCtrl.giftCardsData[paymentMethodId].lastFourDigits = result.paymentData.lastFourDigits;
                          addGiftCardCtrl.giftCardsData[paymentMethodId].queryTransId = result.paymentData.queryTransId;
                          addGiftCardCtrl.giftCardsData[paymentMethodId].amount = _roundCurrencyFilter(addGiftCardCtrl.carouselData.balanceToBePayed);
                          addGiftCardCtrl.giftCardsData[paymentMethodId].isCvvRequired = false;
                          addGiftCardCtrl.maxAmount = _roundCurrencyFilter(addGiftCardCtrl.carouselData.balanceToBePayed);
                          if (result.paymentData.balance) {
                              addGiftCardCtrl.giftCardsData[paymentMethodId].balance = _roundCurrencyFilter(result.paymentData.balance);
                              addGiftCardCtrl.giftCardsData[paymentMethodId].amount = addGiftCardCtrl.giftCardsData[paymentMethodId].balance;
                              if (addGiftCardCtrl.maxAmount < addGiftCardCtrl.giftCardsData[paymentMethodId].amount) {
                                  addGiftCardCtrl.giftCardsData[paymentMethodId].amount = addGiftCardCtrl.maxAmount;
                              }
                          }
                          var payload = {
                              giftCards: options.giftCards,
                              giftCardsData: angular.copy(addGiftCardCtrl.giftCardsData),
                              redeemedGiftCards: angular.copy(addGiftCardCtrl.carouselData.redeemedGiftCards),
                              maxAmount: addGiftCardCtrl.maxAmount,
                              selectedGiftCard: paymentMethodId,
                              mainPaymentMethod: options.mainPaymentMethod,
                              isUsedCashbackPoints: options.isUsedCashbackPoints,
                              availableUserCashback: options.availableUserCashback
                          }
                          return dialog.show({
                              templateUrl: 'template/dialogs/add-gift-card/index.html',
                              controller: 'AddGiftCardCtrl',
                              controllerAs: 'addGiftCardCtrl',
                              ariaLabelledby: 'add_gift_card_title',
                              styleClass: 'add-gift-cards-dialog',
                              clickOutsideToClose: false,
                              disableClosing: true,
                              locals: { options: payload },
                              resolve: {
                                  summaryCtrl: summaryCtrl
                              }
                          })
                      } else {
                          console.log('Cannot open gift card dialog')
                      }
                  })
                  .catch(function (error) {
                      addGiftCardCtrl.giftCardsData[paymentMethodId].errorMessage = 'gift_card_error_message';
                  });
            }

            function redeemGiftCard(paymentMethodId) {
                if (paymentMethodId === SP_PAYMENTS.PAYMENT_METHODS.IDS.MAX_GIFT_CARD) {
                    if (!addGiftCardCtrl.giftCardsData[paymentMethodId].cvv || isNaN(addGiftCardCtrl.giftCardsData[paymentMethodId].cvv)) {
                        addGiftCardCtrl.carouselData.selectedGiftCardData.isCvvRequired = true;
                        return;
                    } else {
                        addGiftCardCtrl.carouselData.selectedGiftCardData.isCvvRequired = false;
                    }
                }

                if(addGiftCardCtrl.removedGiftCards[paymentMethodId]) {
                    delete addGiftCardCtrl.removedGiftCards[paymentMethodId];
                }

                addGiftCardCtrl.carouselData.redeemedGiftCards[paymentMethodId] = {
                    methodId: paymentMethodId,
                    amount: addGiftCardCtrl.giftCardsData[paymentMethodId].amount,
                    cardNumber: addGiftCardCtrl.giftCardsData[paymentMethodId].number,
                    token: addGiftCardCtrl.giftCardsData[paymentMethodId].token,
                    lastFourDigits: addGiftCardCtrl.giftCardsData[paymentMethodId].lastFourDigits,
                    cvv: addGiftCardCtrl.giftCardsData[paymentMethodId].cvv,
                    queryTransId: addGiftCardCtrl.giftCardsData[paymentMethodId].queryTransId,
                    paymentsNumber: 1
                }

                addGiftCardCtrl.carouselData.balanceToBePayed = addGiftCardCtrl.carouselData.balanceToBePayed.toFixed(2);
                addGiftCardCtrl.carouselData.balanceToBePayed -= addGiftCardCtrl.giftCardsData[paymentMethodId].amount;
                addGiftCardCtrl.giftCardsData[paymentMethodId] = null;
            }

            function removeCard(paymentMethodId) {
                addGiftCardCtrl.removedGiftCards[paymentMethodId] = addGiftCardCtrl.carouselData.redeemedGiftCards[paymentMethodId];
                delete addGiftCardCtrl.carouselData.redeemedGiftCards[paymentMethodId];
                if (paymentMethodId === SP_PAYMENTS.PAYMENT_METHODS.IDS.MAX_GIFT_CARD) {
                    addGiftCardCtrl.carouselData.selectedGiftCard = addGiftCardCtrl.giftCards.length > 0 && addGiftCardCtrl.giftCards[0].paymentMethodId;
                    addGiftCardCtrl.giftCardsData[paymentMethodId] = {}
                }
                _calculateBalanceToBePayed();

                return addGiftCardCtrl.carouselData.redeemedGiftCards;
            }

            function resetCardDetails(paymentMethodId) {
                addGiftCardCtrl.giftCardsData[paymentMethodId] = {};
                if (paymentMethodId === SP_PAYMENTS.PAYMENT_METHODS.IDS.MAX_GIFT_CARD) {
                    if(addGiftCardCtrl.giftCards.length > 0 && addGiftCardCtrl.giftCards[0].paymentMethodId) {
                        toggleSelectedGiftCard(addGiftCardCtrl.giftCards[0].paymentMethodId);
                    }
                }
            }

            function checkAmount(paymentMethodId) {
                addGiftCardCtrl.giftCardsData[paymentMethodId].wrongAmountError = '';

                if(addGiftCardCtrl.giftCardsData[paymentMethodId].balance && addGiftCardCtrl.giftCardsData[paymentMethodId].amount > addGiftCardCtrl.giftCardsData[paymentMethodId].balance) {
                    addGiftCardCtrl.giftCardsData[paymentMethodId].wrongAmountError = 'amount_exceeds_balance'
                    return;
                }

                if(addGiftCardCtrl.giftCardsData[paymentMethodId].amount > addGiftCardCtrl.carouselData.balanceToBePayed ||
                    addGiftCardCtrl.giftCardsData[paymentMethodId].amount > addGiftCardCtrl.maxAmount
                ) {
                    addGiftCardCtrl.giftCardsData[paymentMethodId].wrongAmountError = 'wrong_gift_card_amount_error';
                    return;
                } 
            }

            function checkMaxGiftCardAmount(paymentMethodId) {
                addGiftCardCtrl.wrongAmountError = '';

                if(addGiftCardCtrl.giftCardsData[paymentMethodId].balance && addGiftCardCtrl.giftCardsData[paymentMethodId].amount > addGiftCardCtrl.giftCardsData[paymentMethodId].balance) {
                    addGiftCardCtrl.wrongAmountError = 'amount_exceeds_balance';
                    return;
                }

                if(addGiftCardCtrl.giftCardsData[paymentMethodId].amount > addGiftCardCtrl.carouselData.balanceToBePayed ||
                    addGiftCardCtrl.giftCardsData[paymentMethodId].amount > addGiftCardCtrl.maxAmount
                ) {
                    addGiftCardCtrl.wrongAmountError = 'wrong_gift_card_amount_error';
                    return;
                } 
            }

            function isDisableRedeemBtn(paymentMethodId) {
                if (
                  paymentMethodId === SP_PAYMENTS.PAYMENT_METHODS.IDS.MAX_GIFT_CARD ||
                  paymentMethodId === SP_PAYMENTS.PAYMENT_METHODS.IDS.NOFSHONIT
                ) {
                    return !addGiftCardCtrl.giftCardsData[paymentMethodId].cvv || addGiftCardCtrl.giftCardsData[paymentMethodId].cvv.length === 0;
                }
                return !addGiftCardCtrl.giftCardsData[paymentMethodId].amount || !addGiftCardCtrl.giftCardsData[paymentMethodId].balance || addGiftCardCtrl.giftCardsData[paymentMethodId].amount < 0 || !addGiftCardCtrl.carouselData.balanceToBePayed || addGiftCardCtrl.giftCardsData[paymentMethodId].amount > _roundCurrencyFilter(addGiftCardCtrl.maxAmount) || addGiftCardCtrl.giftCardsData[paymentMethodId].amount > addGiftCardCtrl.giftCardsData[paymentMethodId].balance;
            }

            function isGiftCardNotEligible() {
                return addGiftCardCtrl.carouselData.selectedGiftCard === SP_PAYMENTS.PAYMENT_METHODS.IDS.TAV_PLUS && addGiftCardCtrl.mainPaymentMethod !== SP_PAYMENTS.PAYMENT_METHODS.IDS.CREDIT_CARD;
            }

            function close () {
                var redeemedGiftCards = Object.values(addGiftCardCtrl.carouselData.redeemedGiftCards);
                if (!redeemedGiftCards || !redeemedGiftCards.length) {
                    dialog.hide({});
                    return;
                }

                if(addGiftCardCtrl.removedGiftCards) {
                    angular.extend(addGiftCardCtrl.carouselData.redeemedGiftCards, addGiftCardCtrl.removedGiftCards);
                }
                _calculateTotalGiftCard();
                dialog.hide();
            }

            function next() {
                _calculateTotalGiftCard();
                dialog.hide(addGiftCardCtrl.carouselData.redeemedGiftCards);
            }

            function _calculateTotalGiftCard() {
                // For Max Gift Card, the dialog is forced to close leading to the missing total giftCard data in summaryCtr, so we need to manually set the totalGiftCard before closing
                if (addGiftCardCtrl.carouselData.redeemedGiftCards[SP_PAYMENTS.PAYMENT_METHODS.IDS.MAX_GIFT_CARD]) {
                    summaryCtrl.totalPriceForPointsCalculation += summaryCtrl.totalGiftCards || 0;
                    summaryCtrl.totalGiftCards = 0;
                    summaryCtrl.redeemedGiftCards = addGiftCardCtrl.carouselData.redeemedGiftCards;
                    summaryCtrl.redeemedGiftCardIds = Object.keys(summaryCtrl.redeemedGiftCards);
                    angular.forEach(summaryCtrl.redeemedGiftCardIds, function (giftCardId) {
                        summaryCtrl.totalGiftCards += summaryCtrl.redeemedGiftCards[giftCardId].amount;
                    });

                    if(summaryCtrl.userCashbackLoyalty) {
                        summaryCtrl.totalPriceForPointsCalculation -= summaryCtrl.totalGiftCards || 0;
                        summaryCtrl.availableUserCashback -= summaryCtrl.totalGiftCards || 0;
                        _calculateUserPoints();
                    }
                }
            }

            function _calculateUserPoints() {
                if(summaryCtrl.totalPriceForPointsCalculation > 0) {
                    var points = summaryCtrl.totalPriceForPointsCalculation / summaryCtrl.activeCashbackLoyalty.moneyToPointRatio;
                    summaryCtrl.currentOrderPoints = Number(points.toFixed(1));
                } else {
                    summaryCtrl.currentOrderPoints = null;
                }
            }

            function _setGiftCardsData() {
                angular.forEach(addGiftCardCtrl.giftCards, function (giftCard) {
                    if (!addGiftCardCtrl.giftCardsData[giftCard.paymentMethodId]) {
                        addGiftCardCtrl.giftCardsData[giftCard.paymentMethodId] = {
                            number: null,
                            balance: null
                        };
                    }
                });
            }

            function _calculateBalanceToBePayed() {
                addGiftCardCtrl.carouselData.balanceToBePayed = addGiftCardCtrl.total;
                var keys = Object.keys(addGiftCardCtrl.carouselData.redeemedGiftCards);
                angular.forEach(keys, function (giftCardId) {
                    addGiftCardCtrl.carouselData.balanceToBePayed -= addGiftCardCtrl.carouselData.redeemedGiftCards[giftCardId] ? addGiftCardCtrl.carouselData.redeemedGiftCards[giftCardId].amount : 0;
                });
            }

            function _setGiftCardDisplay() {
                var giftCardDisplay = Config.retailer.settings.giftCardDisplay ? JSON.parse(Config.retailer.settings.giftCardDisplay) : {}

                addGiftCardCtrl.giftCardGeneralDisplay = giftCardDisplay;

                if(addGiftCardCtrl.carouselData.selectedGiftCard) {
                    if(giftCardDisplay[Config.language.culture]) {
                        if(giftCardDisplay[Config.language.culture].config) {
                            addGiftCardCtrl.giftCardDisplay = giftCardDisplay[Config.language.culture].config[addGiftCardCtrl.carouselData.selectedGiftCard];

                            if(!addGiftCardCtrl.giftCardDisplay) addGiftCardCtrl.giftCardDisplay = {}

                            var MAX_TRUNCATED_LEGAL_TEXT_LENGTH = 256;

                            if(addGiftCardCtrl.giftCardDisplay && addGiftCardCtrl.giftCardDisplay.legalText) {
                                addGiftCardCtrl.originalLegalText = addGiftCardCtrl.giftCardDisplay.legalText;
                                if(addGiftCardCtrl.giftCardDisplay.legalText.length > MAX_TRUNCATED_LEGAL_TEXT_LENGTH) {
                                    addGiftCardCtrl.truncatedLegalText = addGiftCardCtrl.giftCardDisplay.legalText.slice(0, MAX_TRUNCATED_LEGAL_TEXT_LENGTH) + '...';
                                    addGiftCardCtrl.giftCardDisplay.legalText = addGiftCardCtrl.truncatedLegalText;

                                    addGiftCardCtrl.giftCardDisplay.showEllipsisBtn = true;
                                }
                            }

                            addGiftCardCtrl.giftCardDisplay.giftCardsSeeMore = false;
                        }
                    }
                }
            }

            function _calculateTextTruncation() {
                $timeout(function() {
                    var legalTextEl = document.querySelector('.legal-text-wrapper > span.text');
                    if(legalTextEl) {
                        var scrollHeight = legalTextEl.scrollHeight;
                        var clientHeight = legalTextEl.clientHeight;

                        if(scrollHeight > clientHeight) {
                            addGiftCardCtrl.showSeeMoreBtn = true;
                        }
                    }
                }, 0)
            }

            function _setGiftCardIndex() {
                if(addGiftCardCtrl.giftCards.length > 0) {
                    angular.forEach(addGiftCardCtrl.giftCards, function (giftCard, index) {
                        addGiftCardCtrl.giftCardsIndex[giftCard.paymentMethodId] = index;
                    });
                }
            }

            function _reorderGiftCards(giftCards, paymentMethodId) {
                if (giftCards.length > 0) {
                    var index = giftCards.findIndex(function (giftCard) {
                        return giftCard.paymentMethodId === paymentMethodId;
                    })

                    if (index >= 0) {
                        giftCards.push(giftCards.splice(index, 1)[0]);
                    }
                }
            }
            
            function isRedeemedGiftCardsExist() {
                var redeemedGiftCardsValues = Object.values(addGiftCardCtrl.carouselData.redeemedGiftCards);
                var isRedeemedGiftCardsHaveAmount = false;
                redeemedGiftCardsValues.forEach(function (giftCard) {
                    if (addGiftCardCtrl.carouselData.selectedGiftCard === SP_PAYMENTS.PAYMENT_METHODS.IDS.MAX_GIFT_CARD) {
                        if(giftCard.methodId === SP_PAYMENTS.PAYMENT_METHODS.IDS.MAX_GIFT_CARD) {
                            isRedeemedGiftCardsHaveAmount = true;
                        }
                    } else {
                        isRedeemedGiftCardsHaveAmount = true
                    }
                });
                return isRedeemedGiftCardsHaveAmount;
            }

            function isValidateCvv(){
                return !isNaN(addGiftCardCtrl.giftCardsData[addGiftCardCtrl.carouselData.selectedGiftCard].cvv) && addGiftCardCtrl.giftCardsData[addGiftCardCtrl.carouselData.selectedGiftCard].cvv.length > 0;
            }

            function findGiftCard(paymentMethodId) {
                var index = addGiftCardCtrl.giftCardsIndex[paymentMethodId];
                if (isNaN(index) || index < 0) {
                    return {};
                }

                return addGiftCardCtrl.giftCards[index];
            }

            function _cutText(text, length) {
                if (text.length > length) {
                    return text.substring(0, length) + '...';
                }
                return text;
            }

            _listeners.push($scope.$watch(function (){
                return addGiftCardCtrl.giftCardsData && addGiftCardCtrl.giftCardsData[addGiftCardCtrl.carouselData.selectedGiftCard] && addGiftCardCtrl.giftCardsData[addGiftCardCtrl.carouselData.selectedGiftCard].cvv
            }, function() {
                if(
                  addGiftCardCtrl.carouselData.selectedGiftCard === $rootScope.SP_PAYMENTS.PAYMENT_METHODS.IDS.MAX_GIFT_CARD &&
                  addGiftCardCtrl.giftCardsData[addGiftCardCtrl.carouselData.selectedGiftCard]
                ) {
                    if (!addGiftCardCtrl.giftCardsData[addGiftCardCtrl.carouselData.selectedGiftCard].cvv && addGiftCardCtrl.carouselData.selectedGiftCardData.isCvvRequired) {
                        addGiftCardCtrl.carouselData.selectedGiftCardData.isCvvRequired = false;
                        return;
                    }
                    if (addGiftCardCtrl.carouselData.selectedGiftCardData.isCvvRequired) {
                        if (
                            addGiftCardCtrl.giftCardsData[addGiftCardCtrl.carouselData.selectedGiftCard].cvv &&
                            addGiftCardCtrl.giftCardsData[addGiftCardCtrl.carouselData.selectedGiftCard].cvv.length > 2 &&
                            !isNaN(addGiftCardCtrl.giftCardsData[addGiftCardCtrl.carouselData.selectedGiftCard].cvv)
                        ) {
                            addGiftCardCtrl.carouselData.selectedGiftCardData.isCvvRequired = false;
                        }
                    } else {
                        // if(isNaN(addGiftCardCtrl.giftCardsData[addGiftCardCtrl.carouselData.selectedGiftCard].cvv) || addGiftCardCtrl.giftCardsData[addGiftCardCtrl.carouselData.selectedGiftCard].cvv.length < 3) {
                        //     addGiftCardCtrl.carouselData.selectedGiftCardData.isCvvRequired = true;
                        // }
                    }
                }
            }));

            util.destroyListeners($scope, _listeners);
    }]);
})(angular, app);
