(function(angular, app) {
    'use strict';

    app.service('FR', [
        function() {
            var self = this;

            self.id = 5;
            self.culture = 'fr';
            self.title = 'Français';
            self.label = 'French';
            self.short = 'Fra';
            self.direction = 'ltr';
            self.icon = 'https://d226b0iufwcjmj.cloudfront.net/localization/fr.png';
            self.dateFormat = {
                date: 'dd/MM/yyyy',
                time: 'HH:mm',
                dateAndTime: 'dd/MM/yyyy' + ' ' + 'HH:mm'
            };
            self.countriesTranslate = {
                FR: {
                    'tax': 'TVA'
                }
            };
            self.translate = {
                'arabic': 'Arabe',
                'english': 'Anglaise',
                'spanish': 'Spanish',
                'french': 'Française',
                'hebrew': 'Hébreu',
                'russian': 'Russe',
                'left': 'Gauche',
                'right': 'Droite',
                'up': 'En haut',
                'down': 'En bas',
                'search': 'Chercher',
                'add to cart': 'Ajouter',
                'add to current cart': 'Ajouter au panier actuel',
                'create new order': 'Créer une commande',
                'cannot add to cart': 'Ne peut pas être ajouté au panier',
                'add cart products': 'Ajouter les articles du panier',
                'special products': 'Produits spéciaux',
                'save to shopping list': 'Ajouter à la liste de courses',
                'new list name': 'Nom de la nouvelle liste',
                'home': 'Accueil',
                'powered by': 'Créé par',
                'remove': 'Supprimer',
                'login': 'Connexion',
                'password': 'Mot de passe',
                'logout': 'Déconnexion',
                'orders history': 'Historique des commandes',
                'recent orders': 'Dernières commandes',
                'checkout': 'Panier',
                'clear cart': 'Vider le panier',
                'callcustomer': 'Appelez-moi',
                'creditcard': 'Carte de crédit',
                'storecredit': 'Compte client',
                'the delivery time is no longer available': 'Le créneau de livraison n’est plus disponible',
                'add card': 'Ajouter une carte',
                'activate card': 'Activer la carte',
                'add another card': 'Ajouter une autre carte',
                'order update': 'Mise à jour de la commande',
                'updated order value': 'Valeur de la commande mise à jour',
                '(estimated)': '(estimative)',
                'in order to complete the order update, please choose the payment method': 'Afin de finaliser la mise à jour de la commande, veuillez choisir un mode de paiement',
                'confirm update': 'CONFIRMER LA MISE À JOUR',
                'add another account': 'Ajouter un autre compte',
                'remove card': 'Supprimer la carte',
                'pay with': 'Payer avec',
                'pay with credit card': 'Payer par carte de crédit',
                'remove account': 'Supprimer le compte',
                'credit card number': 'No carte de crédit',
                'cvv': 'CVV',
                'delivery address': 'Adresse de livraison',
                'city': 'Ville',
                'street': 'Rue',
                'phone num': 'No de téléphone',
                'recipient\'s name': 'Nom du destinataire',
                'floor / apartment': 'Étage/appartement',
                'house number': 'No de la maison',
                'house no.': 'No de la maison',
                'available days': 'Jours disponibles',
                'entry: spautocompleterequirematch error': 'Saisir une lettre (A-J)',
                'house_entry_none': 'Aucun',
                'house_entry_a': 'A',
                'house_entry_b': 'B',
                'house_entry_c': 'C',
                'house_entry_d': 'D',
                'house_entry_e': 'E',
                'house_entry_f': 'F',
                'house_entry_g': 'G',
                'house_entry_h': 'H',
                'house_entry_i': 'I',
                'house_entry_j': 'J',
                'delivery time': 'Horaire de livraison',
                'pickup time': 'Horaire de retrait',
                'today': 'Aujourd’hui',
                'amount exceeded eligibility': 'Le montant dépassé l`éligibilité',
                'sunday': 'Dimanche',
                'monday': 'Lundi',
                'tuesday': 'Mardi',
                'wednesday': 'Mercredi',
                'thursday': 'Jeudi',
                'friday': 'Vendredi',
                'saturday': 'Samedi',
                'sun': 'Dim',
                'mon': 'Lun',
                'tue': 'Mar',
                'wed': 'Mer',
                'thu': 'Jeu',
                'fri': 'Ven',
                'notes': 'Remarques',
                'add instructions': 'Ajouter des instructions',
                'summary': 'Résumé',
                'products': 'Produits',
                'you saved': 'Vous économisez',
                'payment': 'Paiement',
                'delivery charge': 'Frais de livraison',
                'price without discount': 'Prix avant réduction',
                'price after discount': 'Prix après réduction',
                'total': 'Total',
                'between': 'entre',
                'order id': 'ID de commande',
                'your order has been received': 'Votre commande a bien été reçue',
                'shopping lists': 'Listes de courses',
                'shopping list created': 'Liste de courses créée',
                'forgot password': 'Mot de passe oublié',
                'register': 'Inscription',
                'retailer details': 'Détails du magasin',
                'in all departments': 'dans tous les rayons',
                'edit details': 'Modifier les détails',
                'loading': 'Chargement',
                'supervision': 'Cacheroute',
                'load previous orders': 'Afficher les commandes précédentes',
                'close previous orders': 'Masquer les commandes précédentes',
                'previous orders': 'Mes commandes',
                'substitutions': 'Remplacements',
                'order time': 'Date de commande',
                'call customer': 'Appeler le client',
                'payment method': 'Mode de paiement',
                'total (estimated)' : 'Total (estimé)',
                'out of stock': 'Rupture',
                'received_items': 'Reçue',
                'ordered': 'Commandé',
                'payment method ending with': 'Mode de paiement terminant avec',
                'credit card charge': 'Carte de crédit débitée',
                'new product': 'Nouveau produit',
                'view details': 'Voir les détails',
                'sale': 'Promo',
                'sale!': 'Promo !',
                'club': 'Club',
                'club sale': 'Promo Club',
                'open sale details': 'Cliquer pour voir les détails de la promotion',
                'choose brand': 'Choisir une marque',
                'specials_and_benefits_title': 'Promotions et offres',
                'specials': 'Promotions',
                'go to specials': 'Toutes les promotions',
                'item': 'Produit',
                'items': 'Produits',
                'hide cart': 'Masquer le panier',
                'best ingredients for best results': 'Les meilleurs ingrédients pour les meilleurs résultats',
                'order items by': 'Trier les produits par',
                'name': 'Nom',
                'actions': 'Action',
                'creation date': 'Date de création',
                'delete': 'Supprimer',
                'create new shopping list': 'Créer une nouvelle liste',
                'shopping list name': 'Nom de la liste de courses',
                'create': 'Créer',
                'id': 'Identifiants',
                'time': 'Horaire',
                'date and time': 'Date et heure',
                'ok': 'OK',
                'address': 'Adresse',
                'the site is undergoing maintenance': 'Le site est en maintenance',
                'please try again later': 'Réessayez plus tard',
                'the site is active only for registered users, please login': 'Le site n\'est actif que pour les utilisateurs inscrits, connectez-vous',
                'the site is disabled': 'Le site est désactivé',
                'comment': 'Remarque',
                'noun search': 'Recherche',
                'finish order': 'Finir la commande',
                'reset password': 'Réinitialiser le mot de passe',
                'mobile': 'Mobile',
                'mobile phone': 'Téléphone mobile',
                'phone number': 'No de téléphone',
                'edit': 'Modifier',
                'add phone': 'Ajouter un téléphone',
                'first name': 'Prénom',
                'last name': 'Nom de famille',
                'birth date': 'Date de naissance',
                'user name and password': 'Nom d\'utilisateur et mot de passe',
                'user name': 'Nom d\'utilisateur',
                'gender': 'Sexe',
                'male': 'Homme',
                'female': 'Femme',
                'prefer not to say': 'Préfère ne pas répondre',
                'personal information': 'Coordonnées personnelles',
                'user details': 'Informations utilisateur',
                'contact details': 'Coordonnées de contact',
                'comments': 'Commentaires',
                'work': 'Travail',
                'allow to send promotions': 'J\'accepte d’être informé/e des promotions',
                'my items': 'Mes produits',
                'image': 'Image',
                'price': 'Prix',
                'quantity': 'Quantité',
                'actions on marked items': 'Actions sur les articles marqués',
                'create new cart': 'Créer un nouveau panier',
                'create shopping list': 'Création d’une liste de courses',
                'no products were selected': 'Aucun produit choisi',
                'total sum': 'Montant total',
                'order status': 'État de la commande',
                'new': 'Nouvelle',
                'received': 'Reçue',
                'charged': 'Facturée',
                'delivered': 'Livrée',
                'cancelled': 'Annulée',
                'suspended': 'Suspendue',
                'processing': 'En cours',
				'waiting for confirmation': 'En cours',
				'waiting_for_confirmation': 'En cours',
                'user_confirmed': 'En cours',
                'collected': 'Préparée',
                'registered': 'Enregistrée',
                'received text': 'Commande reçue dans le système, mais pas encore facturée',
                'charged text': 'Carte de crédit débitée du montant de l\'achat',
                'delivered text': 'Commande expédiée et en route vers chez vous',
                'cancelled text': 'Commande annulée et non traitée',
                'suspended text': 'Commande ne pouvant être complétée, intervention du client nécessaire',
                'processing text': 'Commande en cours de préparation',
                'collected text': 'Produits préparés, mais pas encore envoyés en caisse',
                'registered text': 'Produits préparés et scannés, mais pas encore payés',
                'no products were found': 'Aucun produit trouvé',
                'order number': 'Numéro de commande',
                'date': 'Date',
                'back': 'Retour',
                '<< back': '<< Retour',
                'save': 'Enregistrer',
                'share': 'Partager',
                'shopping list': 'Liste de courses',
                'address 1': 'Rue + numéro',
                'address 2': 'Entrée + étage + appartement',
                'i confirm that i have read the ': 'Je confirme avoir lu ',
                'terms': 'les conditions',
                'similar products': 'Produits similaires',
                'check all': 'Tout sélectionner',
                'uncheck all': 'Tout désélectionner',
                'all': 'Tout',
                'narrow your choice': 'Filtrer les produits',
                'brand': 'Marque',
                'type': 'Type',
                'category': 'Catégorie',
                'sign in': 'Connexion',
                'sign in using facebook': 'Se connecter avec Facebook',
                'it\'s easy': 'C\'est facile',
                'please sign in to continue...': 'Connectez-vous au site pour continuer...',
                'country': 'Pays',
                'at': 'À',
                'let\'s start': 'Commençons',
                'adding some items': 'Ajouter des produits',
                'it\'s easy!': 'C\'est facile !',
                'forgot password?': 'Mot de passe oublié ?',
                'confirm password': 'Confirmer le MDP',
                'i agree to the': 'Je confirme avoir lu et accepté les',
                'terms & conditions': 'Conditions du site',
                'policy_end': '',
                'please let me know about promotions': 'J\'accepte de recevoir des informations et offres par email et SMS (la réception peut être arrêtée à tout moment)',
                'reset': 'Réinitialiser',
                'please check your email for the password reset information': 'Vérifiez votre email pour voir les infos de réinitialisation du mot de passe',
                'please check your inbox and follow the instructions': 'Veuillez consulter  votre boîte de réception et suivre les instructions',
                'if you haven\'t received an email, please check if you mistyped your email address or contact customer service': 'Si vous n\'avez pas reçu de courriel, veuillez vérifier si vous avez saisi correctement votre adresse courriel ou contactez le service client',
                'open previous carts:': 'Ouvrir le dernier achat :',
                'save and sign in': 'Enregistrer et se connecter',
                'save for later': 'Enregistrer pour plus tard',
                'today\'s special deals': 'Promos du jour',
                'no': 'Non',
                'yes': 'Oui',
                'delivery': 'Livraison',
                'pickup': 'Retrait magasin',
                'pick and go': 'Pick & Go',
                'pick & go': 'Pick & Go',
                'undefined': 'Non défini',
                'anything else': 'Remarque sur la livraison',
                'delivery date': 'Date de livraison',
                'pickup date': 'Date de retrait',
                'estimated total': 'Total estimé',
                'estimated': 'Estimé',
                'allow substitutions': 'Produit manquant',
                'delivery date and time': 'Date et horaires de livraison',
                'next': 'Suivant',
                'contact details & payment': 'Coordonnées et paiement',
                'checkout contact details': 'Coordonnées',
                'summary & payment': 'Résumé et paiement',
                'delivery/pickup preferences': 'Retrait/Livraison',
                'how do you want to get your order?': 'Comment voulez-vous recevoir votre commande ?',
                'the site is active only for registered users': 'Le site n\'est actif que pour les utilisateurs inscrits',
                'please login': 'Connectez-vous',
                'sort by': 'Trier par',
                'save as a shopping list': 'Enregistrer la liste de courses',
                'your shopping cart': 'Votre Panier',
                'save to list': 'Enregistrer dans la liste',
                'product name': 'Nom de l\'article',
                'order ': 'Commande ',
                'order': 'Ordre',
                'unit price': 'Prix unitaire',
                'units': 'Unités',
                'unit': 'Unité',
                'proceed to checkout': 'Passer commande',
                'est.': 'Environ',
                'est. total weight': 'Poids estimatif total',
                'est. weight': 'Poids estimé',
                'mobile: required error': 'Veuillez saisir un numéro de téléphone',
                'mobilephone: required error': 'Veuillez saisir un numéro de téléphone',
                'phone: required error': 'Veuillez saisir un numéro de téléphone',
                'phone: minlength error': 'Le numéro de téléphone doit contenir au moins 7 chiffres',
                'city: required error': 'Veuillez saisir une ville',
                'city: spautocompleterequirematch error': 'Aucune ville trouvée',
                'cityinput: spautocompleterequirematch error': 'Aucune ville trouvée',
                'street: required error': 'Veuillez saisir une rue',
                'street: spautocompleterequirematch error': 'Aucune rue trouvée',
                'streetinput: spautocompleterequirematch error': 'Aucune rue trouvée',
                'housenum: required error': 'Veuillez saisir un numéro de maison',
                'housenum: min error': 'Veuillez saisir un numéro de maison',
                'text1: required error': 'Veuillez saisir une adresse',
                'addresstext: required error': 'Veuillez saisir une adresse',
                'fullname: required error': 'Veuillez saisir un nom et un prénom',
                'email: required error': 'Veuillez saisir une adresse email',
                'email: email error': 'Veuillez saisir une une adresse email valide',
                'firstname: required error': 'Veuillez saisir un prénom',
                'lastname: required error': 'Veuillez saisir un nom de famille',
                'password: required error': 'Veuillez saisir un mot de passe',
                'password: minlength error': 'Le mot de passe doit contenir au moins 6 caractères',
                'password: maxlength error': 'Le mot de passe peut contenir jusqu’à 30 caractères',
                'password: pattern error': 'Le mot de passe doit contenir au moins un chiffre et une lettre',
                'confirmpassword: required error': 'Veuillez confirmer le mot de passe',
                'confirm_password: required error': 'Veuillez confirmer le mot de passe',
                'policyapproval: required error': 'Veuillez accepter les conditions du site',
                'zipcode: required error': 'Veuillez saisir votre code postal',
                'message: required error': 'Veuillez saisir un message',
                'loyaltycardid: required error': 'Veuillez saisir votre numéro d’adhérent',
                'ValidationKey: required error': 'Veuillez saisir votre numéro d’identité',
                'birthdate: date error': 'Veuillez saisir votre date de naissance',
                'confirmpassword: passwords do not match error': 'Les mots de passes ne correspondent pas',
                'confirm_password: Passwords do not match error': 'Les mots de passes ne correspondent pas',
                'confirmPassword: pattern error': 'Les mots de passes ne correspondent pas',
                'confirm_password: pattern error': 'Les mots de passes ne correspondent pas',
                'floor: step error': 'Veuillez saisir un numéro entier',
                'please enter an amount': 'Veuillez saisir une quantité',
                'select order': 'Choisir un tri',
                'create new list': 'Créer une nouvelle liste',
                'my account': 'Mon compte',
                'continue shopping': 'Continuer mes courses',
                'homepagenavigation': 'Aller à l\'accueil',
                'add comments': 'Ajouter des commentaires',
                'add comment': 'Commentaire',
                'what happens if something i ordered is not available?': 'Que devons nous faire si un article de votre commande n’est plus disponible ?',
                'read more': 'Lire la suite',
                'read less': 'Lire moins',
                'substitute_preference_1_label': 'Remplacer',
                'substitute_preference_1_description': 'Nous le remplacerons sans vous appeler',
                'substitute_preference_2_label': 'Appelez-moi',
                'substitute_preference_2_description': 'Nous vous téléphonerons pour le remplacer',
                'substitute_preference_3_label': 'Ne rien faire',
                'substitute_preference_3_description': 'Nous ne le remplacerons pas',
                'substitute_preference_4_label': 'SMS',
                'substitute_preference_4_description': 'Nous vous enverrons un SMS pour vous proposer de le remplacer',
                'substitute_preference_5_label': 'WhatsApp',
                'substitute_preference_5_description': 'Nous vous enverrons un WhatsApp pour vous proposer de le remplacer',
                'substitute_preference__label': 'pour éviter une erreur',
                'substitute_preference__description': 'pour éviter une erreur',
                'zip code': 'Code postal',
                'zip': 'Code postal',
                'full name': 'Nom complet',
                'payment details': 'Détails de paiement',
                'your order will be delivered': 'Votre commande sera livrée le',
                'your order will be ready for pickup': 'Votre commande sera prête à retirer',
                'substitution choice': 'Remplacement de produits',
                'address line 1': 'Adresse',
                'add another address line': 'Ajouter une autre ligne d\'adresse',
                'address line 2': 'Adresse supplémentaire',
                'remove another address': 'Supprimer l’adresse supplémentaire',
                'almost there!': 'Vous y êtes presque !',
                'send the delivery sooner, if possible': 'Envoyer la livraison plus tôt, si possible',
                'prepare the order sooner, if possible': 'Préparer la livraison plus tôt, si possible',
                'sooner if possible': 'Dès que possible',
                'checkout summary': 'Résumé des courses',
                'only': 'Seulement',
                'day': 'Jour',
                'free': 'Gratuit',
                'total save': 'Vous économisez',
                'place order': 'Passer la commande',
                'your email or password seems to be wrong': 'Votre email ou mot de passe n’est pas valide',
                'please try again': 'Réessayez',
                'oops': 'Oups !',
                'null': '',
                'select': 'Sélectionner',
                'show previous orders': 'Afficher les dernières commandes',
                'hide previous orders': 'Masquer les dernières commandes',
                'choose list': 'Choisir une liste',
                'choose order': 'Choisir une commande',
                'choose shopping list': 'Choisir une liste de courses',
                'we couldn\'t create your shopping list': 'Nous n\'avons pas pu créer votre liste de courses',
                'list name': 'Nom de la liste',
                'create list': 'Créer une liste',
                'floor': 'Étage',
                'apartment': 'Appart.',
                'close details': 'Fermer les détails',
                'we just sent you an order confirmation by email': 'Nous venons d’envoyer la confirmation de commande par mail',
                'thank you for shopping at': 'Merci d’avoir commandé chez',
                'prutah_checkout_finish_thank_you_line_1': 'Merci d’avoir commandé chez',
                'prutah_checkout_finish_thank_you_line_2': '',
                'please add your payment card': 'Veuillez saisir les détails de votre carte de crédit',
                'my orders': 'Mes commandes',
                'hours': 'Heures',
                'ready for pickup': 'sera prête à retirer',
                'we are sorry, but the following products are out of stock': 'Désolé, mais les produits suivants sont en rupture de stock',
                'remove from cart & continue': 'Retirer du panier et continuer',
                'you save': 'Vous économisez ',
                'can\'t find item?': 'Non trouvé ?',
                'can\'t find an item?': 'Non trouvé ?',
                'tax': 'Taxe',
                'unknown': 'Inconnu',
                'other': 'Autre',
                'add it here': 'Ajoutez-le ici ',
                'choose category': 'Choisir une catégorie',
                'if we are unfamiliar with the product or don\'t have it in stock, we will give you a call': 'Si nous ne trouvons pas le produit, nous vous contacterons',
                'store info': 'Détails du magasin',
                'stores info': 'Liste des magasins',
                'store-details': 'Détails du magasin',
                'store location': 'Emplacement du magasin',
                'price high-low': 'Prix décroissant',
                'price low-high': 'Prix croissant',
                'my shopping lists': 'Mes listes de courses',
                'remove shopping list': 'Suppression d’une liste de courses',
                'are you sure you want to remove the shopping list': 'Souhaitez vous vraiment supprimer la liste',
                'removed': 'Supprimé',
                'add to shopping list': 'Ajouter à une liste de course',
                'payments number': 'Nombre de mensualités',
                'cvv: required error': 'Veuillez saisir le CVV',
                'enter cvv': 'Veuillez saisir le CVV',
                'cvv: please enter cvv error': 'Veuillez saisir le CVV',
                'cvv: minlength error': 'Le CVV doit contenir au moins 3 chiffres',
                'cancel': 'Annuler',
                'continue with this cart': 'Continuer avec le panier actuel',
                'merge carts': 'Fusionner les paniers',
                'would you like to merge the two carts or continue using this cart': 'Voulez-vous fusionner les 2 paniers ou continuer avec le panier actuel ?',
                'somewhere else': 'ailleurs',
                'you have an open cart with': 'Vous avez déjà un panier ouvert avec',
                'total weight': 'Poids total',
                'account info': 'Détails du compte',
                'orders': 'Commandes',
                'payment methods': 'Moyens de paiement',
                'coupons/discounts': 'Coupons/Réductions',
                'save changes': 'Enregistrer les modifications',
                'credit cards': 'Cartes de crédit',
                'paypal accounts': 'Comptes PayPal',
                'shopping list named': 'Liste de courses intitulée',
                'your account info has been successfully updated': 'Les détails du compte ont bien été mis à jour',
                'was successfully created with': 'Créée avec',
                'items successfully added': 'Produits ajoutés avec succès',
                'items were successfully added to shopping list': 'Produits ajoutés avec succès à la liste de courses',
                'item was successfully added to shopping list': 'Produit ajouté avec succès à la liste de courses',
                'items were not added to shopping list': 'Produits non ajoutés à la liste de courses',
                'item were not added to shopping list': 'Produit non ajouté à la liste de courses',
                'saved in': 'Enregistré dans',
                'we are not able to add the following products to your current order': 'Impossible d’ajouter les produits suivants à votre commande actuelle',
                'opening hours': 'Heures d’ouverture',
                'additional': 'Plus de détails',
                'area map': 'Plan',
                'contact us': 'Nous contacter',
                'store information': 'Détails du magasin',
                'how can we help you today': 'Comment pouvons-nous vous aider',
                'how can we help you': 'Comment pouvons-nous vous aider',
                'sent successfully': 'Message envoyé',
                'successfully updated': 'Détails actualisés',
                'successful update': 'Actualisé avec succès',
                'shopping list was successfully updated': 'Liste de courses actualisée avec succès',
                'service provider blocker': 'Blocage par le FAI',
                'your service provider is blocking some of the website\'s functionality': 'Votre FAI bloque partiellement le site et empêche son bon fonctionnement',
                'once we complete picking your order, we adjust your final charge based on weight adjustments, substitutions & out of stock items': 'Pendant la préparation de la commande, la facture finale peut changer en raison des produits vendus au poids, remplacés ou en rupture de stock',
                'please contact your service provider': 'Veuillez contacter votre FAI',
                'close': 'Fermer',
                'passwords do not match': 'Les mots de passe ne correspondent pas',
                'send reset code': 'Envoyer le code de réinitialisation',
                'are you sure you want to clear your cart': 'Etes vous sûr de vouloir vider votre panier ?',
                'e-mail': 'Email',
                'your message has been sent': 'Votre message a bien été envoyé',
                'change zip code': 'Code postal modifié',
                'change area': 'Changement de secteur',
                'hey there': 'Bonjour',
                'let\'s make sure we\'re available in your area': 'Vérifions si nous livrons votre secteur',
                'please enter your zip code': 'Veuillez saisir votre code postal',
                'please enter your area': 'Veuillez saisir votre lieu de résidence',
                'area: required error': 'Veuillez saisir votre lieu de résidence',
                'the area you entered, is served by this store': 'Ce secteur est desservi par ce magasin',
                'pickup service only': 'Retrait magasin seulement',
                'we serve your area': 'Nous desservons votre secteur',
                'delivery method in this area': 'Type de livraison dans ce secteur',
                'check': 'Vérifier',
                'for ': 'Pour ',
                'we offer': 'Nous proposons',
                'both': '',
                'and': 'et',
                'and the': 'et la',
                'pick up': 'Retrait magasin',
                'options': '',
                'your store will be': 'Votre magasin sera',
                'by changing your zip code you will lose your shopping cart': 'En changeant de code postale vous perdrez votre panier',
                'by changing your area you will lose your shopping cart': 'En changeant de secteur, vous perdrez votre panier',
                'go back': 'Retour',
                'don\'t change zip code': 'Ne pas changer le code postal',
                'don\'t change area': 'Ne pas changer le secteur',
                'currently we do not deliver to your area': 'Pour l’instant, nous ne livrons pas dans votre secteur',
                'change store': 'Changer de magasin',
                'change to delivery': 'Passer en livraison',
                'change to pickup': 'Passer en  Retrait magasin',
                'go to store': 'Poursuivre dans le magasin',
                'the zipcode you entered, is served by this store': 'Le code postal saisi est desservi par ce magasin',
                'next delivery regular': 'Horaire de la prochaine livraison',
                'thank you for contacting us': 'Merci de nous avoir contacté',
                'delivery preferences': 'Livraison',
                'pickup preferences': 'Retrait magasin',
                'privacy policy': 'Politique de confidentialité',
                'about us': 'À propos',
                'not active': 'Non actif',
                'case of': 'Pack de',
                'case': 'Pack',
                'single': 'Unique',
                'ordersfromposfieldtitle0': 'Veuillez saisir votre code postal',
                'ordersfromposfielddescription0': 'Nous vous enverrons un message de vérification',
                'phonenumber: required error': 'Veuillez saisir votre numéro de téléphone mobile',
                'orderfromposcodesenttitle0': 'Nous avons envoyé un SMS avec un code de vérification au <%value%>',
                'confirmcode: required error': 'Veuillez saisir le code de vérification',
                'floor: not_editable error': 'Vous ne pouvez pas modifier ce champ avant d\'avoir saisi un code postal pour votre adresse',
                'floor: not_editable_locked error': 'Pour modifier l\'adresse, veuillez choisir à nouveau dans la liste des codes postaux',
                'floor: not_editable_locked_empty error': 'Veuillez saisir votre code postal et choisir votre adresse dans la liste',
                'text1: not_editable error': 'Vous ne pouvez pas modifier ce champ avant d\'avoir saisi un code postal pour votre adresse',
                'text1: not_editable_locked error': 'Pour modifier l\'adresse, veuillez choisir à nouveau dans la liste des codes postaux',
                'text1: not_editable_locked_empty error': 'Veuillez saisir votre code postal et choisir votre adresse dans la liste',
                'text2: not_editable error': 'Vous ne pouvez pas modifier ce champ avant d\'avoir saisi un code postal pour votre adresse',
                'text2: not_editable_locked error': 'Pour modifier l\'adresse, veuillez choisir à nouveau dans la liste des codes postaux',
                'text2: not_editable_locked_empty error': 'Veuillez saisir votre code postal et choisir votre adresse dans la liste',
                'street: not_editable error': 'Vous ne pouvez pas modifier ce champ avant d\'avoir saisi un code postal pour votre adresse',
                'street: not_editable_locked error': 'Pour modifier l\'adresse, veuillez choisir à nouveau dans la liste des codes postaux',
                'street: not_editable_locked_empty error': 'Veuillez saisir votre code postal et choisir votre adresse dans la liste',
                'cityinput: not_editable error': 'Vous ne pouvez pas modifier ce champ avant d\'avoir saisi un code postal pour votre adresse',
                'cityinput: not_editable_locked error': 'Pour modifier l\'adresse, veuillez choisir à nouveau dans la liste des codes postaux',
                'cityinput: not_editable_locked_empty error': 'Veuillez saisir votre code postal et choisir votre adresse dans la liste',
                'city: not_editable error': 'Vous ne pouvez pas modifier ce champ avant d\'avoir saisi un code postal pour votre adresse',
                'city: not_editable_locked error': 'Pour modifier l\'adresse, veuillez choisir à nouveau dans la liste des codes postaux',
                'city: not_editable_locked_empty error': 'Veuillez saisir votre code postal et choisir votre adresse dans la liste',
                'countryinput: not_editable error': 'Vous ne pouvez pas modifier ce champ avant d\'avoir saisi un code postal pour votre adresse',
                'countryinput: not_editable_locked error': 'Pour modifier l\'adresse, veuillez choisir à nouveau dans la liste des codes postaux',
                'countryinput: not_editable_locked_empty error': 'Veuillez saisir votre code postal et choisir votre adresse dans la liste',
                'housenum: not_editable error': 'Vous ne pouvez pas modifier ce champ avant d\'avoir saisi un code postal pour votre adresse',
                'housenum: not_editable_locked error': 'Pour modifier l\'adresse, veuillez choisir à nouveau dans la liste des codes postaux',
                'housenum: not_editable_locked_empty error': 'Veuillez saisir votre code postal et choisir votre adresse dans la liste',
                'entry: not_editable error': 'Vous ne pouvez pas modifier ce champ avant d\'avoir saisi un code postal pour votre adresse',
                'entry: not_editable_locked error': 'Pour modifier l\'adresse, veuillez choisir à nouveau dans la liste des codes postaux',
                'entry: not_editable_locked_empty error': 'Veuillez saisir votre code postal et choisir votre adresse dans la liste',
                'entrance: not_editable error': 'Vous ne pouvez pas modifier ce champ avant d\'avoir saisi un code postal pour votre adresse',
                'entrance: not_editable_locked error': 'Pour modifier l\'adresse, veuillez choisir à nouveau dans la liste des codes postaux',
                'entrance: not_editable_locked_empty error': 'Veuillez saisir votre code postal et choisir votre adresse dans la liste',
                'apartment: not_editable error': 'Vous ne pouvez pas modifier ce champ avant d\'avoir saisi un code postal pour votre adresse',
                'apartment: not_editable_locked error': 'Pour modifier l\'adresse, veuillez choisir à nouveau dans la liste des codes postaux',
                'apartment: not_editable_locked_empty error': 'Veuillez saisir votre code postal et choisir votre adresse dans la liste',
                'apartmentinput: not_editable error': 'Vous ne pouvez pas modifier ce champ avant d\'avoir saisi un code postal pour votre adresse',
                'apartmentinput: not_editable_locked error': 'Pour modifier l\'adresse, veuillez choisir à nouveau dans la liste des codes postaux',
                'apartmentinput: not_editable_locked_empty error': 'Veuillez saisir votre code postal et choisir votre adresse dans la liste',
                'address1input: not_editable error': 'Vous ne pouvez pas modifier ce champ avant d\'avoir saisi un code postal pour votre adresse',
                'address1input: not_editable_locked error': 'Pour modifier l\'adresse, veuillez choisir à nouveau dans la liste des codes postaux',
                'address1input: not_editable_locked_empty error': 'Veuillez saisir votre code postal et choisir votre adresse dans la liste',
                'address2input: not_editable error': 'Vous ne pouvez pas modifier ce champ avant d\'avoir saisi un code postal pour votre adresse',
                'address2input: not_editable_locked error': 'Pour modifier l\'adresse, veuillez choisir à nouveau dans la liste des codes postaux',
                'address2input: not_editable_locked_empty error': 'Veuillez saisir votre code postal et choisir votre adresse dans la liste',
                'comments: not_editable error': 'Vous ne pouvez pas modifier ce champ avant d\'avoir saisi un code postal pour votre adresse',
                'comments: not_editable_locked error': 'Pour modifier l\'adresse, veuillez choisir à nouveau dans la liste des codes postaux',
                'comments: not_editable_locked_empty error': 'Veuillez saisir votre code postal et choisir votre adresse dans la liste',
                'zipcode: not_editable error': 'Vous ne pouvez pas modifier ce champ',
                'zipinput: not_editable error': 'Vous ne pouvez pas modifier ce champ',
                'zipcodeinput: not_editable error': 'Vous ne pouvez pas modifier ce champ',
                'hi': 'Bonjour',
                'entrance': 'Entrée',
                'faq': 'FAQ',
                'new password': 'Nouveau mot de passe',
                'resetnewpassword: required error': 'Saisissez un nouveau mot de passe',
                'resetconfirmpassword: required error': 'Ressaisissez le nouveau mot de passe',
                'resetconfirmpassword: passwords do not match error': 'Les mots de passe ne correspondent pas',
                'resetnewpassword: minlength error': 'Le nouveau mot de passe doit contenir au moins 6 caractères',
                'once we collected your order, we will call to collect your payment': 'Une fois votre commande préparée, un agent vous contactera pour effectuer le paiement',
                'choose how you\'d like to pay': 'Choisir la méthode de paiement',
                'your credit cards': 'Votre carte de crédit',
                'your paypal accounts': 'Votre compte Paypal',
                'enter your credit on file': 'Veuillez saisir votre numéro de compte client',
                'subtotal': 'Sous-total',
                'substitutions choice': 'Remplacement de produits',
                'let\'s change your password...': 'Choisissez un nouveau mot de passe...',
                'you must agree to the terms': 'Vous devez accepter les conditions d\'utilisation',
                'registration completed': 'Inscription terminée',
                'registration is closed right now': 'Inscription actuellement fermée',
                'please enter cvv': 'Veuillez saisir le CVV',
                'pre auth: declined invld acct': 'Le code CVV ne correspond pas à la carte de crédit. Réessayez',
                'cvv is an anti-fraud security feature to help verify that you are in possession of your credit card': 'Le code CVV (Credit Validation Value) est utilisé pour vérifier que la carte est à vous',
                'the three-digit cvv number is printed on the signature panel on the back of the card immediately after the card\'s account number': 'Les trois chiffres CVV sont imprimés au dos de la carte sur la bande de signature',
                'select location': 'Choisir le lieu',
                'pickup location': 'Lieu de retrait',
                'day0': 'Dima.',
                'day1': 'Lundi',
                'day2': 'Mardi',
                'day3': 'Merc.',
                'day4': 'Jeudi',
                'day5': 'Vend.',
                'day6': 'Same.',
                'week_day_0': 'Dima.',
                'week_day_1': 'Lundi',
                'week_day_2': 'Mardi',
                'week_day_3': 'Merc.',
                'week_day_4': 'Jeudi',
                'week_day_5': 'Vend.',
                'week_day_6': 'Samedi',
                'ticket_status_1': 'Nouveau',
                'ticket_status_2': 'En traitement',
                'ticket_status_3': 'Fin',
                'ticket': 'Ticket de service',
                'by': 'de',
                'back to tickets': 'Retour aux tickets',
                'me': 'Moi',
                'status': 'Statut',
                'description': 'Description',
                'created date': 'Date de création',
                'customer service': 'Service Client',
                'service': 'Service',
                'inquiry': 'Demande No\'-',
                'type in your comment': 'Saisissez votre message ici...',
                'ticket id': 'ID du ticket d’assistance',
                'no previous inquiries': 'Aucune demande enregistrée',
                'we didn\'t recognize the address you entered': 'Nous ne reconnaissons pas l’adresse saisie',
                'sorry, currently we do not deliver to this area.': 'Désolés, nous ne livrons pas encore dans ce secteur',
                'sorry, currently we do not deliver to this area, for further inquiries call': 'Désolés, nous ne livrons pas encore dans ce secteur. Pour plus d’informations, appelez',
                'sorry, currently we do not deliver to this area, for further inquiries call our customer service': 'Désolés, nous ne livrons pas encore dans ce secteur. Pour plus d’informations, appelez le service client',
                'sorry, branch change required': 'Désolés, changement de magasin requis',
                'this address could only be delivered from branch': 'La livraison à cette adresse est possible seulement d’un magasin',
                'try another address or choose pickup option': 'Essayez une autre adresse ou choisissez l\'option Retrait magasin',
                'in': 'Dans les',
                'hrs': 'heures',
                'review your order and process payment': 'Résumé de commande et paiement',
                'what did you mean': 'Voulez-vous dire',
                'please choose delivery time': 'Vous devez choisir un horaire de livraison',
                'please choose pickup time': 'Vous devez choisir un horaire de retrait',
                'delivery details': 'Détails de livraison',
                'your details': 'Coordonnées',
                'pickup details': 'Détails de retrait',
                'street number and street name': 'Rue et numéro de rue',
                'entrance, apartment, suite, building, floor, etc.': 'Entrée, étage, numéro d\'appartement, etc.',
                'apartment, suite, building, floor, etc.': 'Étage, numéro d\'appartement, etc.',
                'type letter': 'Saisir une lettre',
                'add products': 'Ajoutez des produits',
                'add': 'Ajouter',
                'choose exist list': 'Choisir une liste existante',
                'clear all': 'Tout effacer',
                'or': 'ou',
                'for': 'pour',
                'to ': 'pour ',
                'your order total {{areaname}}does not meet the {{minimum | currency}} minimum order total': 'Votre commande {{areaName}} n’a pas encore atteint le minimum requis de {{minimum | currency}}',
                'please add to your cart items worth {{difference | currency}} to proceed to checkout': 'Veuillez ajouter au panier des produits d’une valeur minimum de {{difference | currency}} pour terminer votre achat',
                'not including items on sale': 'Produits en promo non inclus',
                'delivery fee': 'Frais de livraison',
                'pickup fee': 'Frais de retrait',

                'service fee': 'Frais de service',
                'choose your pickup time': 'Choisissez votre horaire de retrait',
                'delivery times': 'Horaires de livraison',
                'delivery times & areas': 'Horaires et secteurs',
                'delivery areas': 'Secteurs de livraison',
                'add your gift card': 'Paiement par bons d’achat',
                'enter your gift card id': 'Saisissez l’identifiant du bon',
                'phone: pattern error': 'No de téléphone non valide',
                'phone: maxlength error': 'No de téléphone non valide',
                'phone: number error': 'No de téléphone non valide',
                'homephone: pattern error': 'No de téléphone non valide',
                'homephone: number error': 'No de téléphone non valide',
                'workphone: pattern error': 'No de téléphone non valide',
                'workphone: number error': 'No de téléphone non valide',
                'mobilephone: pattern error': 'No de téléphone non valide',
                'mobilephone: number error': 'No de téléphone non valide',
                'pay by phone': 'Paiement par téléphone',
                'get it within': 'sera envoyée d’ici',
				'next delivery': 'Prochaine livraison',
				'next pickup': 'Prochain retrait',
                'changes to your cart will be lost. are you sure you want to leave?': 'Les modifications apportées au panier seront perdues. Quitter sans enregistrer ?',
                'the following products were not saved correctly': 'Les produits suivants n\'ont pas été enregistrés correctement',
                'the following products will not be for sale at this given time': 'Les produits suivants ne seront pas en promo au moment choisi',
                'shoplistname: required error': 'Veuillez donner un nom à la liste de courses',
                'coupon number': 'Code coupon',
                'delivery & pickup': 'Horaires de livraison et retrait',
                'pickup times': 'Horaires de retrait',
                'store map': 'Plan',
                'none': 'Aucun',
                'look for the express pickup icon': 'Remarquez l\'icône de retrait express',
                'look for the express delivery icon': 'Remarquez l\'icône de livraison express',
                'invalid coupon code': 'Code coupon non valide',
                'we couldn\'t find a matching coupon code': 'Coupon absent du système',
                'where would you like to shop': 'Où souhaitez vous acheter',
                'drop files here to upload or click to browse...': 'Faire glisser les fichiers ici ou cliquer pour télécharger...',
                'drag files or browse...': 'Faire glisser les fichiers ici ou cliquer pour télécharger...',
                'attachments': 'Fichiers joints',
                'link': 'Lien',
                'select the store closer to your home': 'Choisir le magasin le plus proche',
                'for this store we offer': 'Pour ce magasin, nous proposons',
                'buy from this store': 'Acheter dans ce magasin',
                'deliveries': 'Livraisons',
                'dear customer': 'Cher/ère client/e',
                'please notice': 'Veuillez noter',
                'pseudoproductname: required error': 'Veuillez saisir un nom de produit',
                'pseudoproductname: maxlength error': 'Le nom ne peut pas contenir plus de 200 caractères',
                'area': 'Secteur',
                'recipe': 'Recette recommandée',
                'steps': 'Étapes',
                'more info': 'Plus d\'infos',
                'product description': 'Description du produit',
                'ingredients': 'Ingrédients',
                'more information': 'Plus d\'infos',
                'directions': 'Instructions',
                'nutrition facts': 'Valeur nutritionnelle',
                'nutrition values': 'Valeurs nutritionnelles',
                'serving size': 'Taille d’une portion',
                'servings per container': 'Contenu',
                'amount per serving': 'Poids unitaire',
                'calories': 'Calories (énergie)',
                'calories from fat': 'Calories provenant des lipides',
                'daily value': 'Valeur quotidienne',
                'total fat': 'Matières grasses',
                'saturated fat': 'dont acides gras saturés',
                'trans fat': 'dont acides gras trans',
                'cholesterol': 'Cholestérol',
                'sodium': 'Sodium',
                'total carbohydrate': 'Glucides',
                'dietary fiber': 'Fibres alimentaires',
                'sugars': 'Sucres',
                'protein': 'Protéines',
                'vitamin a': 'Vitamine A',
                'vitamin c': 'Vitamine C',
                'calcium': 'Calcium',
                'iron': 'Fer',
                'g': 'g',
                'monounsaturated fat': 'Acides gras monoinsaturés',
                'polyunsaturated fat': 'Acides gras polyinsaturés',
                'percent daily': 'Les pourcentages de la consommation quotidienne sont basés sur un régime quotidien de 2 000 calories. Votre valeur quotidienne peut être élevée ou basse selon votre apport calorique.',
                'something went wrong': 'Quelque chose a échoué',
                'total price is less then the minimum price': 'Le total de votre panier est inférieur au montant minimum de commande',
                'not all of the given products were found': 'Certains produits n’ont pas été trouvés',
                'the following products are not active': 'Les produits suivants ne sont pas actifs',
                'add to shopping cart': 'Ajouter au panier',
                'items to cart': 'Produits au panier',
                'min': 'Minutes',
                'from': 'Source',
                'edit order': 'Oui modifier la commande',
                'do you want to remove / add items to your existing order': 'Voulez-vous supprimer/ajouter des produits à votre commande ',
                'note': 'Veuillez noter',
                'prices and specials will change according to the update date': 'Les prix et remises peuvent changer selon la date de mise à jour',
                'more': 'Plus',
                'less': 'Moins',
                'offer not found': 'Produit non trouvé',
                'product info disclaimer': 'Toutes les informations relatives au produit y compris les photos sont indiquées sur le site seulement à titre indicatif et sont susceptibles de contenir certaines erreurs ou imprécisions.',
                'delivery hours': 'Horaires de livraison',
                'delivery area': 'Secteur de livraison',
                'branch': 'Magasin',
                'quantity limitation': 'Limite de quantité',
                'is limited to ': 'limité à ',
                'items only': 'articles seulement',
                'cart not found': 'Panier non trouvé',
                'existing member': 'Membre existant',
                'connect your {clubname} membership account': 'Identifiez-vous au club {clubName}',
                'join {clubname} membership account': 'Adhérez maintenant au club {clubName}',
                'to complete your purchase, join/connect to {clubname} membership account': 'Pour finaliser votre achat, inscrivez/connectez-vous au club {clubName}',
                'in order to complete your purchase, you must connect to the loyalty club': 'Pour finaliser votre achat, vous devez vous connecter au club client',
                'customer id': 'Numéro de club',
                'connect': 'Connexion',
                'connect_to_loyalty': 'Identification',
                'could save': 'Vous auriez pu économiser',
                'continue without membership': 'Continuer sans réductions adhérent',
                'continue without club discounts': 'Continuer sans offres adhérent',
                'you are now logged in and connected to your membership': '',
                'membership club': 'Club client',
                'connect to our membership club': 'Vous n’êtes pas connecté au club client',
                'membership club number': 'Numéro de club',
                'membership club special deals': 'Promos Adhérent',
                'membership_club': 'Club',
                '{clubname} special deals': 'Promos de {clubname}',
                'expiration date': 'Date d’expiration',
                'you already a registered member': 'Vous êtes déjà adhérent au club',
                'we did not find your membership club account': 'Compte adhérent au club non trouvé',
                'sorry': 'Désolés',
                'you are now connected to your membership club account': 'Vous êtes maintenant connecté au club client',
                'you have successfully registered to {clubname}. you will need to connect as a club member, once you receive your credit card': 'L\'adhésion au club {clubname} a réussi. Identifiez-vous une fois la carte reçue.',
                'the registration process will end when you will pay a membership fee at checkout': 'Le processus d\'inscription s’achève avec le paiement des frais d\'adhésion à la caisse',
                'remove credit card info': 'Suppression les détails de carte de crédit',
                'are you sure you want to remove credit card information': 'Voulez-vous vraiment supprimer les détails de votre carte de crédit',
                'remove paypal account': 'Suppression d’un compte PayPal',
                'are you sure you want to remove this paypal account': 'Voulez-vous vraiment supprimer les détails de votre compte PayPal',
                'are you sure you want to cancel your order update and return to your original order': 'Voulez-vous vraiment annuler la modification de votre commande et revenir à la commande d\'origine',
                'print': 'Imprimer',
                'track order': 'Commande No',
                'view order': 'Voir la commande',
                'forgot something': 'Avez-vous oublié quelque chose',
                'update order': 'Mise à jour de la commande',
                'update order failed': 'Échec de mise à jour de la commande',
                'order received': 'Commande reçue',
                'order is in process, no changes can be made at this time': 'Commande en cours de préparation, vous ne pouvez plus la modifier',
                'order in process': 'Commande en cours de préparation',
                'order ready': 'Commande prête',
                'in process': 'En cours de préparation',
                'ready': 'Prête',
                'need help': 'Besoin d’aide',
                'edit your order': 'Modifier la commande',
                'your order': 'Votre commande',
                'has been updated': 'a été actualisée',
                'please check your email for order details': 'Veuillez consulter votre e-mail pour obtenir les détails de votre commande',
                'thank you': 'Merci',
                'we have started collecting your original order.': 'Nous avons commencé à préparer votre commande',
                'we have started collecting your order.': 'Nous avons commencé à préparer votre commande',
                'we have finished collecting your original order.': 'Nous avons fini de préparer votre commande',
                'this is a canceled order.': 'Il s\'agit d\'une commande annulée.',
                'we have finished collecting your order.': 'Nous avons fini de préparer votre commande',
                'your order can no longer be changed': 'Votre commande ne peut plus être modifiée',
                'your order cannot be changed at this time': 'Votre commande ne peut plus être modifiée',
                'cancel update': 'Annuler la modification',
                'canceling': 'Annulation',
                'update': 'de la modification',
                'replace shopping cart': 'Remplacer des produits du panier',
                'are you sure you want to replace your shopping cart with the order from': 'Voulez-vous vraiment remplacer les produits du panier actuel par ceux que vous avez commandés le',
                'replace': 'Remplacer',
                'replace_2': 'Remplacer',
                'you can still edit your order': 'Vous pouvez encore modifier votre commande',
                'your original order is': 'Retour à',
                'cancel order update': 'Annuler la modification de la commande',
                'in process ': 'votre commande d’origine',
                'continue as new cart': 'Créer un nouveau panier',
                'add your credit card': 'Ajouter une carte de crédit',
                'add your credit/debit card': 'Ajoutez vos cartes de crédit/débit',
                'use my delivery address as my billing address*': 'Utiliser mon adresse de livraison comme adresse de facturation*',
                '*by checking this option you approve transfer of your address data to the payment provider': 'En cochant cette option, vous acceptez le transfert de vos données d\'adresse au prestataire de paiement',
                'you can move the selected items into a new order': 'Vous pouvez transférer les produits choisis dans votre nouveau panier',
                'paypal': 'PayPal',
                'icreditmasterpass': 'Master Pass',
                'cost': 'Prix',
                'please wait': 'Veuillez patienter',
                'checking your address': 'Nous vérifions l’adresse',
                'missing details': 'Détails manquants',
                'the server check your address': 'Nous vérifions s’il y a des livraisons à l’adresse saisie',
                'please enter your delivery address in order to see available delivery times': 'Veuillez indiquer votre adresse pour voir les horaires de livraison',
                'your cart is empty': 'Votre panier est vide',
                'housenum: max error': 'Veuillez saisir un nombre inférieur à 3000',
                'personalid: required error': 'Veuillez saisir votre numéro d’identité',
                'customerid: required error': 'Veuillez saisir votre numéro de client',
                'storecredit: required error': 'Veuillez saisir vos détails de paiement',
                'special_exp': 'Fin promo',
                'special_exp_new': 'Fin promo',
                'close more delivery times': 'Fermer plus d’horaires de livraison',
                'en': 'fr',
                'categories': 'Catégories',
                'enter specials department': 'Accéder aux promotions',
                'order by': 'Trier par',
                'start shopping': 'Commencez vos achats',
                'choose an order': 'Choisir une commande',
                'load more': 'Charger plus',
                'restore': 'Retour au panier',
                'newsletter': 'Prospectus',
                'show more': 'Voir plus',
                'show less': 'Voir moins',
                'savings': 'Économie',
                'membership savings': 'Économie Adhérent',
                'potential membership savings': 'Vous auriez pu économiser avec le club',
                'select delivery date & time': 'Choix d’une date et heure de livraison',
                'select pickup date & time': 'Choix d’une date et heure de retrait ',
                'the expiration of these': 'La validité des',
                'sales will not be valid for this chosen time': 'réductions affichées expirera le jour de la livraison de la commande',
                'the special displayed below, will expire on the date you are supposed to receive your order': 'La réduction ci dessous expirera le jour de la livraison de votre commande',
                'these items will be charged at their regular price': 'Ces produits seront facturés au tarif normal sans promotion',
                'this product will be charged the regular price, promotion will not be applied': 'Ce produit sera facturé au tarif normal sans promotion',
                'price of updated cart (est.)': 'Prix du panier actualisé (estimé)',
                'price of updated service fee (est.)': 'Prix des frais de service (estimé)',
                'welcome': 'Bienvenue',
                'please enter a zip code': 'Veuillez saisir un code postal',
                'please enter a city': 'Veuillez saisir une ville de livraison',
                'please enter a delivery address': 'Veuillez saisir une adresse de livraison',
                'verify_1': 'Interdiction de vente',
                'verify_2': 'aux mineurs',
                'verify_3': 'La vente de ce produit aux moins de',
                'verify_4': '',
                'verify_5': 'est prohibée',
                'verify_6': '',
                'verify_7': 'J’ai',
                'verify_8': 'plus de',
                'verify_9': 'J’ai moins de',
                'years': 'ans',
                'sorry_2': 'Désolés',
                'you cannot purchase': 'Vous ne pouvez pas acheter',
                'this item': 'ce produit',
                'login_tab_title': 'Connexion au site',
                'register_tab_title': 'Inscription au site',
                'club member name': 'Nom d’adhérent au club',
                'our top picks': 'Nos recommandations',
                'got it': 'Commencer à acheter',
                'friend\'s email': 'Email d’un invité',
                'or share your promo code': 'ou partager le code promo',
                'copy': 'Copier',
                'send invitation': 'Envoyer une invitation',
                'invitation sent successfully': 'L’invitation a été envoyée',
                'congratulations': 'Félicitations',
                'shop now': 'Commencer à acheter',
                'shop': 'Acheter',
                'your order is being edited by another device': 'Votre commande a été modifiée sur un autre appareil',
                'main menu': 'Menu principal',
                'invalid cart id': 'No de panier non valide',
                'loyalty club member': 'Adhérents au club',
                'in this purchase': 'Dès cet achat',
                'save_1': 'économisez',
                'not a member yet? join now!': 'Pas encore membre du club ? Adhérez et économisez !',
                'continue without loyalty': 'Continuer sans le club',
                'please type in your zip code': 'Veuillez saisir votre code postal',
                'find address': 'Trouver une adresse',
                'you cannot edit this field before entering a zip code for your address': 'Vous ne pouvez pas modifier ce champ avant d\'avoir saisi un code postal pour votre adresse',
                'please choose your address': 'Veuillez choisir votre adresse',
                'you will be able to edit the address details': 'Vous pourrez modifier les coordonnées',
                'you cannot edit this field': 'Vous ne pouvez pas modifier ce champ',

                /*pele card errors*/
                'invalid card': 'Carte invalide',
                'permitted transaction.': 'Transaction autorisée.',
                'the card is blocked, confiscate it.': 'Carte bloquée. Confisquez-la.',
                'the card is stolen, confiscate it.': 'Carte volée. Confisquez-la.',
                'contact the credit company.': 'Contacter la compagnie de crédit.',
                'refusal by credit company.': 'Refus.',
                'the card is forged, confiscate it.': 'Carte fausse. Confisquez-la.',
                'incorrect cvv/id.': 'Le numéro d\'identité ou le CVV ne correspondent pas au numéro de carte.' /*"La société de crédit doit être appelée."*/,
                'an error occurred while building access key for blocked card files.': 'Impossible de créer une clé d\'accès au fichier des cartes bloquées.',
                'no communication. please try again or contact system administration': 'Impossible de communiquer. Veuillez essayer plus tard ou contacter un administrateur',
                'the program was stopped by user`s command (esc) or com port can\'t be open (windows)': 'Logiciel arrêté sur ordre de l\'opérateur (ESC) ou PORT COM non ouvrable (WINDOWS).',
                'the acquirer is not authorized for foreign currency transactions': 'Débit en devises étrangères non autorisé pour l’acheteur.',
                'this card is not permitted for foreign currency transactions': 'Débit en devises étrangères non autorisé pour cette carte.',
                'this card is not supported.': 'Carte non prise en charge.',
                'track 2 (magnetic) does not match the typed data.': 'Le numéro saisi et la bande magnétique ne correspondent pas.',
                'additional required data was entered/not entered as opposed to terminal settings (z field).': 'Les données supplémentaires ne sont pas/sont contraires aux paramètres du terminal.',
                'last 4 digits were not entered (w field).': '4 derniers chiffres non saisis.',
                'entry in int_in file is shorter than 16 characters.': 'Une entrée du fichier INT_IN a moins de 16 caractères.',
                'the input file (int_in) does not exist.': 'Le fichier d\'entrée (IN_INT) n\'existe pas.',
                'blocked cards file (neg) does not exist or has not been updated, transmit or request authorization for each transaction.': 'Le fichier des cartes bloquées (NEG) n\'existe pas ou est obsolète - Envoyez ou demandez une approbation pour chaque transaction.',
                'one of the parameter files/vectors does not exist.': 'L\'un des fichiers de paramètres ou de vecteurs n\'existe pas.',
                'date file (data) does not exist.': 'Le fichier de dates (DATA) n\'existe pas.',
                'format file (start) does not exist.': 'Le fichier de démarrage (START) n\'existe pas.',
                'the difference in days in the blocked cards input is too large, transmit or request authorization for each transaction.': 'L\'écart de jours dans l’entrée des cartes bloquées est trop grand - Envoyez ou demandez une approbation pour chaque transaction.',
                'the difference in generations in the blocked cards input is too large, transmit or request authorization for each transaction.': 'L\'écart de générations dans l’entrée des cartes bloquées est trop grand - Effectuez une diffusion ou demandez l\'approbation pour chaque transaction.',
                'when the magnetic strip is not completely entered, define the transaction as a telephone number or signature only.': 'Lorsque la bande magnétique n\'est pas entièrement entrée, définissez la transaction comme téléphonique ou par signature uniquement.',
                'the central terminal number was not entered into the defined main supplier terminal.': 'Le numéro du terminal central n\'a pas été saisi dans le terminal défini pour le travail comme fournisseur principal.',
                'the beneficiary number was not entered into the defined main beneficiary terminal.': 'Le numéro de bénéficiaire n\'a pas été saisi dans le terminal défini pour le travail comme bénéficiaire principal.',
                'the supplier/beneficiary number was entered, however the terminal was not updated as the main supplier/beneficiary.': 'Terminal non défini comme fournisseur/bénéficiaire principal, mais un numéro de fournisseur/bénéficiaire a été saisi.',
                'the beneficiary number was entered, however the terminal was updated as the main supplier': 'Terminal défini comme fournisseur principal et un numéro de fournisseur a aussi été saisi.',
                'old transactions, transmit or request authorization for each transaction.': 'Transactions anciennes - Transmettez ou demandez une autorisation pour chaque transaction.',
                'defective card.': 'Erreur de saisie de la carte de crédit, réessayez.' /*"Carte non prise en charge."*/,
                'this card is not permitted for this terminal or is not authorized for this type of transaction.': 'Cette carte n\'est pas autorisée sur ce terminal ou pour ce type de transaction.',
                'this card is not permitted for this transaction or type of credit.': 'Cette carte ne peut pas effectuer ce type de transaction.',
                'expired card.': 'Carte expirée.',
                'expired token': 'Token expiré',
                'installment error, the amount of transactions needs to be equal to: first installment plus fixed installments times number of installments.': 'Erreur de mensualités - Le montant de la transaction doit être égal au premier paiement + (paiement fixe X nombres de mensualités).',
                'unable to execute a debit transaction that is higher than the credit card`s ceiling.': 'Impossible d\'exécuter une transaction supérieure au plafond de la carte de débit.',
                'incorrect control number.': 'Erreur de saisie de la carte de crédit, réessayez.' /*"Numéro de contrôle incorrect."*/,
                'the beneficiary and supplier numbers were entered, however the terminal is defined as main.': 'Terminal défini comme bénéficiaire principal, un numéro de fournisseur a été saisi.',
                'the transaction`s amount exceeds the ceiling when the input file contains j1, j2 or j3 (contact prohibited).': 'Dépasse le plafond lorsque le fichier d\'entrée contient J1, J2 ou J3 (contact interdit).',
                'the card is blocked for the supplier where input file contains j1, j2 or j3 (contact prohibited).': 'Carte bloquée pour le fournisseur lorsque le fichier d\'entrée contient J1, J2 ou J3 (contact interdit).',
                'random where input file contains j1 (contact prohibited).': 'Aléatoire lorsque le fichier d\'entrée contient J1 (contact interdit).',
                'the terminal is prohibited from requesting authorization without transaction (j5).': 'Terminal non autorisé à demander une autorisation sans transaction (5J).',
                'the terminal is prohibited for supplier-initiated authorization request (j6).': 'Terminal non autorisé à demander une autorisation initiée par le commerce (6J).',
                'the terminal must request authorization where the input file contains j1, j2 or j3 (contact prohibited).': 'Terminal tenu de demander une autorisation lorsque le fichier d\'entrée contient J1, J2 ou J3 (contact interdit).',
                'secret code must be entered where input file contains j1, j2 or j3 (contact prohibited).': 'Le code secret doit être saisi lorsque le fichier d\'entrée contient J1, J2 ou J3 (contact interdit).',
                'incorrect vehicle number.': 'No de véhicule non valide',
                'the number of the distance meter was not entered.': 'Odomètre non saisi.',
                'the terminal is not defined as gas station (petrol card or incorrect transaction code was used).': 'Terminal non configuré comme une station-service (carte d’essence ou code de transaction utilisé non adapté).',
                'an id number is required (for israeli cards only) but was not entered.': 'Aucun numéro d’identité saisi',
                'cvv is required but was not entered.': 'Aucun CVV saisi',
                'cvv and id number are required (for israeli cards only) but were not entered.': 'Ni numéro d’identité ni CVV saisi',
                'abs attachment was not found at the beginning of the input data in memory.': 'Fichier joint ABS introuvable au début des données d’entrée en mémoire.',
                'the card number was either not found or found twice.': 'Numéro de carte introuvable ou trouvé deux fois.',
                'incorrect transaction type.': 'Type de transaction incorrect.',
                'incorrect transaction code.': 'Code de transaction incorrect.',
                'incorrect credit type.': 'Type de crédit incorrect.',
                'incorrect currency.': 'Devise incorrecte.',
                'the first installment and/or fixed payment are for non-installment type of credit.': 'Il y a un premier paiement ou paiement fixe pour un type de crédit autre que les mensualités.',
                'number of installments exist for the type of credit that does not require this.': 'Il y a un paiement fixe pour un type de crédit qui n’en a pas besoin.',
                'linkage to dollar or index is possible only for installment credit.': 'Impossible d’indexer au dollar/coût de la vie pour un type de crédit autre que les mensualités.',
                'the magnetic strip is too short.': 'La bande magnétique est trop courte.',
                'the pin code device is not defined.': 'Aucun périphérique défini pour saisir un code secret.',
                'must enter the pin code number.': 'Un code secret doit être saisi.',
                'smart card reader not available - use the magnetic reader.': 'Lecteur de cartes à puce indisponible - Utilisez le lecteur magnétique',
                'must use the smart card reader.': 'Le lecteur de cartes à puce doit être utilisé',
                'denied - locked card.': 'Refus - Carte verrouillée',
                'denied - smart card reader action didn\'t end in the correct time.': 'Refus - l\'action avec le lecteur de cartes à puce ne s’est pas terminée à temps',
                'denied - data from smart card reader not defined in system.': 'Refus - les données obtenues avec le lecteur de cartes à puce ne sont pas définies dans le système',
                'incorrect pin code.': 'Code secret saisi erroné.',
                'currency does not exist in vector 59.': 'La devise n\'existe pas dans le vecteur 59.',
                'the club code entered does not match the credit type.': 'Le "code du club" entré ne correspond pas au type de crédit.',
                'cannot cancel charge transaction. please create a credit transaction.': 'Transaction d\'annulation interdite sur la carte. Effectuez une transaction de de crédit.',
                'unable to read/write/open the tran file.': 'Impossible de lire/écrire/ouvrir le fichier TRAN.',
                'no authorization from credit company for clearance.': 'La carte saisie n\'est pas prise en charge par l\'entreprise.' /*"Pas d’autorisation de débiter de la compagnie de crédit."*/,
                'the terminal is not permitted to send queries for immediate debit cards.': 'Le terminal n\'est pas autorisé à demander un crédit de débit instantané.',
                'the transaction amount is too large, divide it into a number of transactions.': 'Montant de transaction trop grand - Divisez en plusieurs transactions.',
                'the terminal is not authorized to execute forced transactions.': 'Le terminal n\'est pas autorisé à effectuer des transactions forcées.',
                'the terminal is not authorized for cards with service code 587.': 'Le terminal n\'a pas d’autorisation pour une carte avec code de service 587.',
                'the terminal is not authorized for immediate debit cards.': 'Le terminal n\'a pas d’autorisation pour une carte de débit instantané.',
                'the terminal is not authorized for installment transactions.': 'Le terminal n\'a pas d’autorisation pour une transaction en plusieurs mensualités.',
                'the terminal is authorized for installment transactions only, not telephone/signature.': 'Le terminal n\'a pas d’autorisation pour une transaction par téléphone/signature seulement en plusieurs mensualités.',
                'the terminal is not authorized for telephone transactions.': 'Le terminal n\'a pas d’autorisation pour une transaction par téléphone.',
                'the terminal is not authorized for signature-only transactions.': 'Le terminal n\'a pas d’autorisation pour une transaction par téléphone/signature seulement.',
                'the terminal is not authorized for foreign currency transactions, or transaction is not authorized.': 'Le terminal n\'a pas d’autorisation pour une transaction en dollars.',
                'the terminal is not authorized for club transactions.': 'Le terminal n\'a pas d’autorisation pour une transaction de club.',
                'the terminal is not authorized for star/point/mile transactions.': 'Le terminal n\'a pas d’autorisation pour une transaction par étoiles/points/miles.',
                'the terminal is not authorized for isracredit credit.': 'Le terminal n\'a pas d’autorisation pour un crédit Isracredit.',
                'the terminal is not authorized for amex credit.': 'Le terminal n\'a pas d’autorisation pour un crédit Amex.',
                'the terminal is not authorized for dollar linkage.': 'Le terminal n\'a pas d’autorisation pour indexer au dollar.',
                'the terminal is not authorized for index linkage.': 'Le terminal n\'a pas d’autorisation pour indexer au coût de la vie.',
                'the terminal is not authorized for index linkage with foreign cards.': 'Le terminal n\'a pas d’autorisation pour indexer au coût de la vie les cartes étrangères.',
                'the terminal is not authorized for star/point/mile transactions for this type of credit.': 'Le terminal n\'a pas d’autorisation pour une transaction par étoiles/points/miles pour ce type de crédit.',
                'the terminal is not authorized for isra 36 credit.': 'Le terminal n\'a pas d’autorisation pour un crédit Isra 36.',
                'the terminal is not authorized for amex 36 credit.': 'Le terminal n\'a pas d’autorisation pour un crédit Amex 36.',
                'the terminal is not authorized for this club code.': 'Le terminal n\'a pas d’autorisation pour ce code de club.',
                'the terminal is not authorized for immediate debit transactions (except for immediate debit cards).': 'Le terminal n\'a pas d’autorisation pour une transaction de débit immédiat, sauf pour les cartes de débit immédiat.',
                'the terminal is not authorized to accept visa card staring with 3.': 'Le terminal n\'est pas autorisé à recevoir les cartes de crédit commençant par 3.',
                'the terminal is not authorized to execute credit transactions above the ceiling.': 'Le terminal n\'est pas autorisé à effectuer une transaction négative supérieure au plafond.',
                'the card is not permitted to execute club transactions.': 'Cette carte n\'est pas autorisée à effectuer des transactions de club.',
                'the card is not permitted to execute star/point/mile transactions.': 'Cette carte n\'est pas autorisée à effectuer des transactions par étoiles/points/miles.',
                'the card is not permitted to execute dollar transactions (regular or telephone).': 'Cette carte n\'est pas autorisée à effectuer des transactions en dollars (normales ou par téléphone).',
                'the card is not valid according to isracard`s list of valid cards.': 'Carte non valide d’après la liste des cartes valides d’Isracard.',
                'defective card according to system definitions (isracard vector1), error in the number of figures on the card.': 'Carte non valide d’après la configuration du système (VECTOR1 d\'Isracard) - Nombre de chiffres sur la carte incorrect.',
                'the card is not permitted to execute dollar transactions according to system definitions (isracard vector1).': 'Carte non autorisée à effectuer des transactions en dollars d’après la configuration du système (VECTOR1 d\'Isracard).',
                'the card belongs to a group that is not permitted to execute transactions according to system definitions (visa vector 20).': 'La carte appartient à un groupe de cartes non autorisées à effectuer des transactions d’après la configuration du système (VECTOR20 de Visa).',
                'the card`s prefix (7 figures) is invalid according to system definitions (diners vector21).': 'Le préfixe de la carte (7 chiffres) n\'est pas valide selon la configuration du système (VECTOR21 de Diners).',
                'the card is not permitted to carry out installment transactions according to isracard`s list of valid cards.': 'Carte non autorisée à effectuer des transactions en plusieurs mensualités d’après la liste des cartes valides d’Isracard.',
                'the number of installments is too large according to isracard`s list of valid cards.': 'Trop grand nombre de mensualités d’après la liste des cartes valides d’Isracard.',
                'visa and diners cards are not permitted for club installment transactions.': 'Les cartes Visa et Diners ne sont pas autorisées à effectuer des transactions de club en plusieurs mensualités.',
                'series of cards are not valid according to system definition (isracard vector5).': 'Série de cartes non valide selon la configuration du système (VECTOR5 d\'Isracard).',
                'invalid service code according to system definitions (isracard vector6).': 'Code de service non valide selon la configuration du système (VECTOR6 d\'Isracard).',
                'the card`s prefix (2 figures) is invalid according to system definitions (isracard vector7).': 'Le préfixe de la carte (2 chiffres) n\'est pas valide selon la configuration du système (VECTOR7 d’Isracard).',
                'invalid service code according to system definitions (visa vector12).': 'Code de service non valide selon la configuration du système (VECTOR12 de Visa).',
                'invalid service code according to system definitions (visa vector13).': 'Code de service non valide selon la configuration du système (VECTOR13 de Visa).',
                'immediate debit card is prohibited for executing credit transaction.': 'Une carte de débit immédiat n’est pas autorisée à effectuer une transaction de crédit.',
                'the card is not permitted to execute installment transactions according to alpha vector no. 31.': 'Carte non autorisée à effectuer des transactions en plusieurs mensualités selon Vector 31 de Leumi Card.',
                'the card is not permitted for telephone and signature-only transactions according to alpha vector no. 31.': 'Carte non autorisée à effectuer des transactions par téléphone et signature uniquement selon Vector 31 de Leumi Card.',
                'the card is not permitted for telephone transactions according to alpha vector no. 31.': 'Carte non autorisée à effectuer des transactions par téléphone selon Vector 31 de Leumi Card.',
                'credit is not approved for immediate debit cards.': 'Crédit non autorisé pour les cartes de débit immédiat.',
                'credit is not approved for foreign cards.': 'Crédit non autorisé pour les cartes étrangères.',
                'incorrect club code.': 'Code de club non valide.',
                'the card is not permitted to execute flexible credit transactions (adif/30+) according to system definitions (diners vector21).': 'Carte non autorisée à effectuer des transactions de crédit flexible (adif/30+) selon la configuration du système (VECTOR21 de Diners).',
                'the card is not permitted to execute immediate debit transactions according to system definitions (diners vector21).': 'Carte non autorisée à effectuer des transactions de débit immédiat selon la configuration du système (VECTOR21 de Diners).',
                'the payment amount is too low for credit transactions.': 'Montant du paiement trop faible pour une transaction de crédit.',
                'incorrect number of installments for credit transaction.': 'Nombre de mensualités erroné pour une transaction de crédit.',
                'zero ceiling for this type of card for regular credit or credit transaction.': 'Plafond 0 pour ce type de carte pour une transaction avec crédit normal ou crédit.',
                'zero ceiling for this type of card for immediate debit credit transaction.': 'Plafond 0 pour ce type de carte pour une transaction avec un crédit de débit immédiat.',
                'zero ceiling for this type of card for immediate debit in dollars.': 'Plafond 0 pour ce type de carte pour une transaction de débit immédiat en dollars.',
                'zero ceiling for this type of card for telephone transaction.': 'Plafond 0 pour ce type de carte pour une transaction par téléphone.',
                'zero ceiling for this type of card for credit transaction.': 'Plafond 0 pour ce type de carte pour une transaction négative.',
                'zero ceiling for this type of card for installment transaction.': 'Plafond 0 pour ce type de carte pour une transaction en plusieurs mensualités.',
                'american express card issued abroad not permitted for instalments transaction.': 'Une carte American Express émise à l\'étranger n’est pas autorisée à effectuer des transactions en plusieurs mensualités.',
                'jcb cards are only permitted to carry out regular credit transactions.': 'Les cartes JCB sont seulement autorisées à effectuer des transactions de crédit normal.',
                'the amount in stars/points/miles is higher than the transaction amount.': 'Le montant en étoiles/points/miles est supérieur au montant de la transaction.',
                'the club card is not within terminal range.': 'La carte adhérent n\'est pas dans la plage du terminal.',
                'star/point/mile transactions cannot be executed.': 'Impossible d’effectuer des transactions par étoiles/points/miles en dollars.',
                'dollar transactions cannot be executed for this type of card.': 'Le terminal n’a pas d’autorisation pour une transaction en dollars avec ce type de crédit.',
                'credit transactions cannot be executed with other than regular credit.': 'Impossible d’effectuer transaction négative avec un crédit différent de la normale.',
                'amount of discount on stars/points/miles is higher than the permitted.': 'Montant de la réduction en étoiles/points/miles supérieur à ce qui est autorisé.',
                'forced transactions cannot be executed with credit/immediate debit card.': 'Impossible d\'exécuter une transaction forcée avec une carte de crédit/débit immédiat.',
                'the previous transaction cannot be cancelled (credit transaction or card number are not identical).': 'La transaction précédente ne peut pas être annulée (transaction négative ou numéro de carte différent).',
                'double transaction.': 'Transaction double.',
                'the terminal is not permitted for index linkage of this type of credit.': 'Le terminal n\'a pas d’autorisation d’indexation au coût de la vie pour ce crédit.',
                'the terminal is not permitted for dollar linkage of this type of credit.': 'Le terminal n\'a pas d’autorisation d’indexation au dollar pour ce crédit.',
                'the card is invalid according to system definitions (isracard vector1).': 'Carte non valide selon la configuration du système (Vector 1 d’Isracard).',
                'unable to execute the self-service transaction if the gas station does not have self service.': 'Stations-service Impossible d’exécuter en "libre-service" mais plutôt en "libre-service dans les stations-service".',
                'credit transactions are forbidden with stars/points/miles.': 'Les étoiles/points/miles ne sont pas autorisés avec les transactions négatives',
                'dollar credit transactions are forbidden on tourist cards.': 'Les transactions négatives en dollars sont interdites sur les cartes de touriste.',
                'phone transactions are not permitted on club cards.': 'Les transactions par téléphone sont interdites avec les cartes adhérent.',
                'application error.': 'Erreur d\'application.',
                'transaction amount missing or zero.': 'Montant de la transaction manquant ou nul.',
                'no communication to pelecard.': 'Aucune communication avec pelecard.',
                'doubled transaction.': 'Transaction double.',
                'terminal number does not exist.': 'Numéro de terminal inexistant.',
                'terminal executes broadcast and/or updating data. please try again later.': 'Le terminal exécute la diffusion ou la mise à jour de données, réessayez ultérieurement.',
                'user name and/or password not correct. please call support team.': 'Nom d\'utilisateur ou mot de passe incorrects, contactez l\'assistance.',
                'user password has expired. please contact support team.': 'Mot de passe de l\'utilisateur expiré, contactez l\'assistance.',
                'locked user. please contact support team.': 'Utilisateur bloqué, contactez l\'assistance.',
                'blocked terminal. please contact account team.': 'Terminal bloqué, contactez la comptabilité.',
                'token number abnormal.': 'Numéro de jeton invalide.',
                'user is not authorized in this terminal.': 'Utilisateur non autorisé à effectuer des actions sur ce terminal.',
                'validity structure invalid. use mmyy structure only.': 'Structure de validité non valide, seule la structure MMAA doit être utilisée.',
                'ssl verifying access is blocked. please contact support team.': 'Accès au certificat SSL bloqué, contactez l\'assistance.',
                'data not exist.': 'Aucune donnée existante.',
                'general error. please contact support team.': 'Erreur générale, contactez l\'assistance.',
                'necessary values are blocked/wrong.': 'Valeurs nécessaires manquantes/erronées.',
                'general error. repeat action.': 'Erreur générale, contactez l\'assistance.',
                'necessary values missing to complete installments transaction': 'Valeurs nécessaires manquantes pour une transaction en mensualités.',
                /*end pele card errors*/

                'video': 'Vidéo',
                'user not found': 'Nom d\'utilisateur ou mot de passe incorrect',
                'in {name} dep.': 'dans le rayon {name}',
                'in specials': 'dans les promos',
                'coupons': 'coupons',
                'my coupons': 'Mes coupons',
                'no coupons available at this time. check again later or check our specials!': 'Aucun coupon disponible pour le moment.  Revenez plus tard ou consultez nos promos.',
                'giftcards.0: gift card must be up to 255 characters error': 'Un bon d’achat peut avoir jusqu’à 255 caractères',
                'your membership has expired': 'Votre adhésion au club est expirée',
                'hello': 'Bonjour',
                'your club membership has expired on': 'Votre adhésion au club est expirée le',
                'membership number': 'No d’adhérent',
                'renew you membership and enjoy exclusive benefits and discounts': 'Renouvelez votre adhésion au club et profitez des avantages et offres dès cet achat",',
                'please call customer service for renewal': 'Pour renouveler votre adhésion et profiter des avantages dans cet achat, appelez le service client.',
                'loyalty registration was not fully completed, registration voucher will be sent by mail': 'Le processus d\'enregistrement n\'est pas entièrement terminé. Le bon vous sera envoyé par la poste à la fin du processus.',
                'phone number does not match the existing phone number on the system': 'Le numéro de téléphone ne correspond pas à celui existant dans le système',
                'pay attention': 'Veuillez noter',
                'enter a valid number': 'Entrer un numéro valide',
                'paying with different payment method will': 'Les avantages adhérents sont valables seulement en payant avec la carte de crédit-fidélité.',
                'discard your loyalty club discounts': 'Le paiement par un autre mode de paiement annulera les avantages reçus',
                'do you want to continue': 'Voulez-vous continuer ?',
                'return and pay with loyalty credit card': 'Retourner et payer avec la carte de crédit-fidélité.',
                'no thanks, continue shopping': 'Continuer sans les avantages adhérents',
                'renew membership': 'Renouveler l’adhésion au club',
                'loyalty membership renewal': 'Renouveler l’adhésion au club',
                'your membership has been renewed successfully': 'Votre adhésion au club a été renouvelée',
                'you can enjoy from the club benefits in this order': 'Vous pouvez profiter des avantages adhérents dès cette commande',
                'continue': 'Continuer',
                'loyalty_club_continue': 'Continuer',
                'loyalty card id': 'No de carte-fidélité',
                'clip & add': 'Ajouter',
                'clip': 'Ajouter',
                'personal id': 'Numéro d’identité',
                'we are not able to register your membership club account': 'Impossible d’adhérer au club',
                'please make sure your details are correct or try again later': 'Vérifiez que les détails saisis sont corrects ou essayez plus tard',
                'new member': 'Adhésion au club',
                '{retailername} membership offers exclusive': 'L’adhésion au club {retailername} vous fait bénéficier d’',
                'promotions in-store and online': 'avantages, remises et offres uniques',
                'join our membership and enjoy exclusive benefits and promotions': 'Adhérez au club client et bénéficiez d\'avantages uniques',
                'the items in your shopping cart will be cleared if you': 'Les produits de votre panier seront effacés si vous',
                'choose to edit your previous order': 'choisissez de modifier votre précédente commande',
                'edit order & clear cart': 'Vider le panier et modifier la commande',
                'it seems you are editing your order on a different device/browser.': 'Il semble que vous êtes en train de modifier votre commande depuis un autre appareil/navigateur',
                'it seems you have finished editing your order on a different device/browser.': 'Il semble que vous ayez terminé de modifier votre commande depuis un autre appareil/navigateur',
                'do you want to add the product to this new order or to the order that is being updated?': 'Voulez-vous ajouter ce(s) produit(s) à une nouvelle commande ou à la précédente commande toujours en cours de modification ?',
                'note! you in the middle of editing order': 'Attention, vous êtes en pleine modification de commande',
                'you in the middle of editing order. changing store is impossible': 'Vous êtes en pleine modification de commande. Impossible de changer de magasin',
                'note! it seems you have finished editing your order on a different device/browser.': 'Attention, vous avez déjà fini de modifier la commande sur un autre appareil/navigateur',
                'add to new order': 'Ajouter à une nouvelle commande',
                'add to updated order': 'Ajouter à la commande en cours de modification',
                'join our membership club': 'Adhérer au club client',
                'join': 'Adhérer',
                'join the club': 'Adhérer au club',
                'join now': 'Adhérez maintenant',
                'join / connect to club': 'Identification/Adhésion au club',
                'connect / join to club': 'Connexion/Adhésion au club',
                'connect to club': 'Se connecter au club',
                'this email already exists': 'Adresse email déjà existante',
				'unable to create user': 'Impossible de créer l\'utilisateur',
                'delivery_type_1_times': 'Horaires de livraison',
                'delivery_type_2_times': 'En point retrait',
                'packed': 'Emballé',
                'frombranch': 'de',
                'special type': 'Type de promo',
                'select from variety': 'Faites votre sélection parmi le choix de produits',
                'forpackquantity': 'à',
                'pack': 'Emballage économique',
                'forbidden action': 'Action non autorisée',
                'internal server error': 'Erreur de serveur interne',
                'please leave the order outside the door if nobody\'s home': 'Laisser la livraison devant la maison si personne n’est là',
                'identify': 'Identification',
                'show full details in {clubname} club page': 'Voir tous les détails sur la page du club {clubName}',
                'enter your promo code': 'Saisir un code promo',
                'promo code unidentified': 'Code promo inconnu',
                'promocode: promo code unidentified error': 'Code promo inconnu',
                'promo code': 'Code promo',
                'for {clubname} full details': 'Pour tous les détails de {clubname}',
                'click here': 'Cliquez ici',
                'home phone number': 'No de téléphone du domicile',
                'work phone number': 'No de téléphone du travail',
                'mobile phone number': 'No de téléphone mobile',
                'special': 'Promo',
                'check all | clicking the action buttons above as "add to cart" refers to selected items': 'Tout cocher| Cliquer sur les boutons d\'action ci-dessus comme "Ajouter au panier" agira sur les articles sélectionnés',
                'back to previous orders': 'Retour aux commandes précédentes',
                'save changes before leaving page?': 'Enregistrer les modifications avant de quitter la page ?',
                'don\'t save': 'Ne pas enregistrer',
                'back to my shopping lists': 'Retour à mes listes de courses',
                'add file': 'Ajouter un fichier',
                'no attachments': 'Pas de fichiers joints',
                'buy': 'Acheter',
                'no purchases have been made yet previous orders will be saved on this screen': 'Aucun achat n’a encore été fait. Les commandes précédentes seront enregistrées sur cet écran',
                'no shopping list created yet': 'Aucune liste de courses n’a encore été créée',
                'all departments': 'Tous les rayons',
                '{retailer} club members can save time and upload their shopping cart from their last visit to the store': 'Les adhérents au club {retailer} peuvent gagner du temps et télécharger le panier d\'achats de leur dernière visite au magasin.',
                'upload my cart': 'Télécharger le panier',
                'no, thanks': 'Non, merci',
                'approve & register': 'J\'approuve et termine l\'inscription',
                'not approve': 'Je n\'approuve pas',
                'i agree to receive promotional content & offers from {company_name} and {hubname}': 'J\'accepte de recevoir du contenu promotionnel et des offres de la société {company_name}, qui gère le site du détaillant et de {hubname}',
                'unfortunately, your cart cannot be uploaded': 'Malheureusement, votre panier ne peut pas être téléchargé',
                'please try again later or continue shopping': 'Réessayez plus tard ou continuez vos achats',
                'areaquery: spautocompleterequirematch error': 'Sélectionnez une adresse dans la liste',
                'areaquery: required error': 'Sélectionnez une adresse dans la liste',
                'pickup address': 'Adresse de retrait magasin',
                'the pickup will be picked from': 'Le retrait sera effectué au magasin',
                'additional special\'s variety': 'Gamme de produits en promo',
                'password: invalid password error': 'Le nouveau mot de passe doit avoir entre 6 et 20 caractères',
                'customer service order': 'Commande du Service Client',
                'logo': 'Logo',
                'copy completed': 'Copie terminée',
                'edit comment': 'Modifier un commentaire',
                'filters': 'Icône des filtres',
                'close filters': 'Fermer les filtres',
                'cant find item image': 'Image du produit non trouvée',
                'plus': 'Plus',
                'minus': 'Moins',
                'open recipe': 'Ouvrir la recette',
                'banner': 'Bannière',
                'play video': 'Lire la vidéo',
                'remove from cart': 'Retirer du panier',
                'express delivery': 'Livraison express',
                'express pickup': 'Retrait express',
                'paypal logo': 'Logo PayPal',
                'masterpass logo': 'Logo MasterPass',
                'language': 'Langue',
                'account': 'Compte',
                'open store info': 'Ouvrir les détails du magasin',
                'close store info': 'Fermer les détails du magasin',
                'open/close cart': 'Ouvrir/Fermer le panier',
                'open ticket': 'Ouvrir la carte',
                'close ticket': 'Fermer la carte',
                'paypal account': 'Compte PayPal',
                'previous product': 'Produit précédent',
                'next product': 'Produit suivant',
                'disclaimer': 'Conditions de la promo',
                'the previous items': 'Articles précédents',
                'the next items': 'Articles suivants',
                'open filter options': 'Ouvrir les options de filtrage',
                'close filter options': 'Fermer les options de filtrage',
                'clear': 'Vider',
                'open product page': 'Ouvrir la page produit',
                'open': 'Ouverture',
                'close our top picks': 'Fermez les favoris',
                'clarification': 'Clarification',
                'show category': 'Afficher la catégorie',
                'show order': 'Afficher la commande',
                'close order': 'Fermer la commande',
                'on credit payment': 'Paiement à crédit',
                'please enter order confirmation number': 'Saisissez le numéro de confirmation de commande',
                'user not connected to organization': 'Utilisateur non connecté à une organisation',
                'no delivery time slots available': 'Aucun horaire de livraison disponible',
                'no pick up time slots available': 'Aucun horaire de retrait disponible',
                'invalid phone number': 'No de téléphone non valide',
                'phones - invalid phone number.': 'No de téléphone non valide',
                'invalid city': 'Ville non valide',
                'invalid address': 'Adresse non valide',
                'invalid street': 'Rue non valide',
                'invalid address 1': 'Rue et numéro non valides',
                'invalid address 2': 'Étage et appartement non valides',
                'invalid zip code': 'Code postal non valide',
                'shopping cart': 'Panier',
                'main manu': 'Menu principal',
                'home page banner': 'Bannière page d’accueil',
                'promoted specials': 'Meilleures promos',
                'filters & sorting': 'Filtrage et tri des produits',
                'product list': 'Liste de produits',
                'checkout footer': 'Pied de page checkout',
                'delivery method': 'Méthode de livraison',
                'delivery method & details': 'Méthode et détails de livraison',
                'navigation': 'Navigation',
                'main display': 'Affichage principal',
                'shortcuts': 'Raccourcis',
                'filters_region': 'Filtrage des produits',
                'languages': 'Langues',
                'settings': 'Paramètres système',
                'next slide': 'Image suivante',
                'home page link': 'Lien vers la page d’accueil',
                'newsletter bubble': 'Newsletter',
                'number of items': 'Nombre d’articles',
                'cart_total': 'Total',
                'items_in_cart': 'Articles',
                'review our last minute recommendations, just before you checkout': 'Avant de finaliser, jetez un coup d’oeil à nos recommandations',
                'thank you, you are now logged in': 'Merci, vous êtes maintenant connecté',
                'thank you, registration completed successfully': 'Merci, vous êtes maintenant inscrit à la Newsletter',
                'delivery method options': 'Options de méthodes de livraison',
                'substitute preference options': 'Options de remplacement de produits',
                'payment method options': 'Options de méthodes de paiement',
                'add coupon': 'Ajouter un coupon',
                'remove product': 'Retirer un produit',
                'remove coupon': 'Effacer un coupon',
                'all specials': 'Toutes les promotions',
                'sorry, we do not deliver to your area': 'Désolés, pour l’instant nous ne livrons pas dans votre secteur',
                'please type in your email and we will let you know when we expand to your location': 'Laissez-nous votre email. Nous vous informerons quand nous desservirons votre localisation',
                'email address': 'Adresse Email',
                'keep in touch': 'Informez-moi',
                'try a different location': 'Essayez une autre localisation',
                'try a different address': 'Essayez une autre adresse',
                'try a different city': 'Essayez une autre ville',
                'try a different zip code': 'Essayez un autre code postal',
                'please enter a more accurate address to continue': 'Veuillez saisir une adresse plus précise pour continuer',

				'express delivery is allowed only with products that contain express delivery tag': 'Livraison express possible seulement avec les produits marqués comme tels',
                'this service is available for express items only. removing non express items from your cart will enable using this option': 'Ce service n\'est disponible que pour les produits marqués en livraison express. Supprimer les produits non marqués permettra cette option',
                'express delivery products limit exceeded': 'Quantité de produits supérieure à la limite de livraison express',
                'express delivery is limited to': 'Livraison express limitée à',
                'items. to enable this service, remove items from your cart': 'articles seulement. Pour permettre cette option, supprimer des produits du papier',

                'apologies': 'Toutes nos excuses',

                'view all': 'Voir plus',

                'products limit': 'Limites de produits',
                'this delivery has a limit of {deliveryitemslimit} products': 'Cette livraison comporte une limite de {deliveryItemsLimit} produits',
                'limited to {deliveryitemslimit} products': 'Limité à {deliveryItemsLimit} produits',
                'has no items limit': 'Sans limite de produits',
                'choose the pickup location': 'Choisir le point de retrait',
                'if you wish to add another product, remove one from the cart first': 'Si vous souhaitez ajouter un produit de plus, retirez-en d\'abord un autre du panier',
                'or change the delivery method': 'ou changez de méthode de livraison',
                'change delivery method': 'Changer de méthode de livraison',
                'are you sure you want to change the delivery type': 'Etes vous sûr de vouloir changer de méthode de livraison ?',
                'changes in delivery times may apply': 'Les horaires de livraison peuvent varier',
                'changes in inventory between the stores may apply': 'Les stocks peuvent varier d’un magasin à l’autre',
                'yes, change delivery method': 'Oui, changer de méthode de livraison',
                'no, stay in current delivery': 'Non, rester dans cette livraison',
                'or select a store for pickup': 'Ou sélectionnez le retrait magasin',
                'select a store for pickup': 'Sélectionnez un point de retrait',
                'choose a convenient time & pickup your order at one of our stores': 'Retirez la commande dans l’un de nos magasins à l’heure qui vous convient',
                'choose': 'Sélectionner',
                'branch logo': 'Icône du magasin',
                'unfortunately we didn\'t find any stores in this area': 'Désolés, nous n’avons pas trouvé de magasin dans ce secteur',
                'try a different area name': 'Essayez un autre secteur',
                'please enter a valid address': 'Saisissez une adresse valide',
                'please add street and house number': 'Ajoutez une rue et un numéro',
                'delivery_type_1': 'Livraison',
                'delivery_type_2': 'Point retrait',
                'delivery_type_5': 'Livraison express',
                'see you soon': 'À bientôt au magasin',
                'the order will wait for you at the store': 'La commande vous attendra prête et emballée',
                'from branch': 'du magasin',
                'chametz': 'Hametz',
                'passoverkosher': 'Casher LePessah toute l’année',
                'passoverkoshertemporarily': 'Casher LePessah temporaire',
                'passoverkitniyotkosher': 'Casher LePessah toute l’année pour les Okhlei Kitnyot',
                'passoverkitniyotkoshertemporarily': 'Casher LePessah temporaire toute l’année pour les Okhlei Kitnyot',
                'meat': 'Viande casher',
                'milky': 'Lait casher',
                'pareve': 'Parvé',
                'israelmilk': 'Lait surveillé',
                'israelcooking': 'Bishoul Israël',
                'sabbathobservingfactory': 'Usine respectant Chabbat',
                'noshviitconcern': 'Sans risque de Cheviit',
                'noorlaconcern': 'Sans risque de Orla',
                'notevelconcern': 'Sans risque de Tével',
                'noovernightconcern': 'Sans risque de Leinat Layla',
                'nogeniza': 'N’a pas besoin de Gueniza',
                'recipes and allergies': 'Ingrédients et allergènes',
                'list of ingredients': 'Liste d’ingrédients',
                'allergies contained': 'Contient des allergènes',
                'possible allergies': 'Peut contenir des allergènes',
                'about the product': 'À propos du produit',
                'extra info and instructions': 'Infos additionnelles et instructions',
                'supervisions': 'Casheroutes',
                'usage warnings': 'Avertissements d’utilisation et de sécurité',
                'storage instructions': 'Instructions de stockage',
                'usage instructions': 'Instructions de préparation et utilisation du produit',
                'fat percentage': 'Taux de graisse',
                'cream percentage': 'Taux de crème',
                'fruit percentage': 'Taux de fruit',
                'alcohol percentage': 'Taux d’alcool',
                'ph': 'Potentiel hydrogène (pH)',
                'country of origin': 'Pays de production',
                'date taken into effect': 'Date d\'entrée en vigueur',
                'height': 'Hauteur',
                'depth': 'Profondeur',
                'width': 'Largeur',
                'net width': 'Poids net',
                'gross width': 'Poids brut',
                'general information': 'Détails généraux',
                'diet type': 'Type de régime',
                'the waiting period will not exceed two hours': 'Livraison dans les 2 heures de l’horaire choisi',
                'at_hour': 'À l’heure',
                'special_reminder_bubble_title': 'Économie !',
                'special_reminder_awarded_bubble_title': 'Vous économisez !',
                'special_reminder_button_title': 'Cliquez et économisez !',
                'add products to earn this promotion': 'Ajoutez des produits pour profiter de la promo',
                'done': 'Fin',
                'select your benefit': 'Choisissez votre avantage',
                'more specials': 'Plus de promos',
                'show more specials': 'Voir plus de promos',
                'show less specials': 'Voir moins de promos',
                'coupon': 'Coupon',
                'coupon_requirements_error': 'Conditions du coupon non remplies',
                'remaining credit': 'Crédit restant',
                'cart amount higher': 'Montant du panier plus élevé',
                'than your credit': 'du restant de votre crédit',
                'edit cart': 'Modifier le panier',
                'cart total': 'Total du panier',
                'to complete the purchase, remove products from the cart valued at': 'Pour terminer l\'achat, retirez des produits du panier d\'une valeur de',
                'payment by credit': 'Facturation du client à crédit',
                'this user is not set as credit customer': 'Client non défini comme client à crédit',
                'cart amount higher than your credit. to proceed please remove at least': 'Montant du panier supérieur au crédit du client. Pour terminer, vous devez retirer des produits pour au moins',
                'valid until': 'Expiré le',
                'please verify that you are not a robot': 'Cochez Je ne suis pas un robot',
                'attachment.size - invalid attachment, file bigger then 2mb': 'Erreur : Poids de la pièce jointe supérieur à 2 Mo',
                'attachment.originalname - invalid attachment name': 'Erreur : Nom de pièce jointe non valide',
                'attachment.mimetype - invalid attachment, file type not allowed': 'Erreur : Nom de pièce jointe non valide',
                'all conditions must be marked in order to complete the order': 'Toutes les conditions doivent être cochées afin de compléter la commande',
                'employee discount': 'Réduction d’employé',
                'card id': 'No de carte',
                'discount': 'Réduction',
                'remaining obligo': 'Solde du budget',
                'monthly obligo': 'Budget mensuel',
                'club name': 'Nom du club',
                'obligo type': 'Type de budget',
                'monthly discount obligo': 'Budget de réduction mensuel',
                'cardid: required error': 'Saisissez un numéro de carte',
                'pin: required error': 'Saisissez le code secret',
                'employee discount card holder?': 'Vous avez droit à une réduction d’employé ?',
                'employee discount card data': 'Détails du compte de réduction d’employé',
                'employee discount account not found': 'Compte de réduction d’employé non trouvé',
                'estimated employee savings': 'Réduction d’employé estimée',
                'getting data...': 'Réception des données...',
                'error': 'Erreur',
                'what\'s your lifestyle?': 'Quel est votre mode de vie ?',
                'filter entire store': 'Filtrez tout le site',
                'filter the entire store according to your preferred lifestyle or diet!': 'Filtrez tout le site selon votre mode de vie ou votre régime préféré !',
                'clear all filters': 'Effacer tous les filtres',
                'all filters': 'Tous les filtres',
                'filtering store': 'Filtre magasin',
                'the entire store is now filtered by': 'Site entièrement filtré par',
                'store is filtered': 'Site filtré',
                'back to filters': 'Retour aux filtres',
                'we couldn\'t find any products': 'Aucun produit trouvé',
                'we couldn\'t find any specials': 'Aucune promo trouvée',
                'clear_all_filters_2': 'Effacer tous les filtres',
                'try editing your filters': 'Essayez de modifier les filtres',
                'permanent': 'Permanent',
                'permanent_filters_legal_text': 'Lors de l\'utilisation des différentes options de filtrage proposées sur le site, la Société ne garantit pas l’absence de dysfonctionnement ou d\'erreur sur le site. La Société ne s\'engage pas à ce que la classification des produits en catégories soit exacte et exclut les produits ne satisfaisant pas les critères requis. Vous êtes seul responsable de l’utilisation des informations obtenues, notamment en matière de dommages causés en utilisant les informations ou en s’y fiant. Chaque produit doit être testé individuellement avec tous ses ingrédients. Vous êtes responsable de vérifier toutes les informations face aux données du fabricant pour ce produit. En cas de divergence entre les informations présentées sur le site et celles présentées par le fabricant, ces dernières prévalent. Ce qui précède ne saurait engager la responsabilité de la Société pour des dommages, indirects ou directs, liés aux informations affichées par le fabricant d\'un quelconque autre produit.',
				'required fields are marked with *': '* Obligatoire',

                'verify account': 'Vérifier le compte',
                'there was a problem verifying your account': 'Un problème est survenu lors de la vérification de votre compte',
                'please contact customer service': 'Veuillez contacter le service client',
                'you are now registered': 'Inscription réussie',
                'please verify your account': 'Vérifiez votre compte',
                'before your first purchase, a verification code will be sent to your mobile phone': 'Avant votre premier achat, un code de vérification sera envoyé sur votre téléphone mobile',
                'send sms': 'Envoyer le sms',
                'enter the code that was sent to your phone': 'Saisissez le code de vérification envoyé sur votre téléphone mobile',
                'change number': 'Changer de numéro',
                'verify': 'Vérification',
                'i didn\'t receive the code (resend)': 'Je n’ai pas reçu de code de vérification (renvoyez-le)',
                'the code is invalid or expired': 'Code de vérification erroné ou expiré',
                'your account is verified': 'Votre compte est maintenant vérifié !',
                'invalid verification code': 'Code de vérification erroné',
                'you have successfully registered': 'Vous êtes bien inscrit',
                'for the customer club': 'au club client',
                'for the customer club and the website': 'au club client et au site en ligne',
                'loyalty club details': 'Détails du compte adhérent',
                'loyalty_terms_confirm': 'Je confirme avoir lu',
                'loyalty terms': 'le règlement du club',
                'website registration': 'Inscription au site',
                'join club': 'Adhésion au club',
                'loyaltypolicyapproval: required error': 'Acceptez le règlement et les conditions du club',
                'personalid: minlength error': 'Saisissez un numéro d\'identité de 9 chiffres,',
                'personalid: maxlength error': 'Saisissez un numéro \'identité de 9 chiffres,',
                'personalid: number error': 'Saisissez seulement des chiffres',
                'please let me know about promotions on site and club': 'Je souhaite être informé par email des promotions',
                'phonenum: required error': 'Saisissez un téléphone portable',
                'phonenum: pattern error': 'No de téléphone non valide',
                'organization': 'Organisation',
                'order_organization_confirm: required error': 'Saisissez le no de confirmation de commande',
                'organization management': 'Gestion d’organisation',
                'detach branch': 'Gestion de magasin',
                'go to organization': 'Accès à l’organisation',
                'user added to organization': 'Utilisateur ajouté à l’organisation',
                'user removed from organization': 'Utilisateur retiré de l’organisation',
                'organization created': 'Organisation créée',
                'organization data changed': 'Détails de l’organisation modifiés',
                'organization activated': 'Organisation activée',
                'organization deactivated': 'Organisation désactivée',
                'organization obligo changed': 'Budget de l’organisation modifié',
                'role': 'Rôle',
                'remaining budget': 'Budget restant',
                'select branch': 'Choisir un magasin',
                'users': 'Utilisateurs',
                'admin': 'Administrateur',
                'max length of phone number is 10': 'Le numéro de téléphone ne peut pas contenir plus de 10 chiffres',
                'payment by organization credit': 'Facturation du budget institutionnel',
                'sorry, your organization account is inactive': 'Désolés, compte d’organisation inactif',
                'sorry, there are no branches assigned to this organization': 'Désolés, aucun magasin associé à cette organisation',
                'please contact your organization admin': 'Contactez le responsable de l’organisation',
                'total assigned': 'Total alloué',
                'credit cards can not be added. you are assigned to an organization': 'Impossible d’ajouter une carte de crédit. Vous êtes associé à une organisation',
                'associated branch': 'Association à un magasin',
                'budget history': 'Historique du budget',
                'organization budget': 'Budget institutionnel',
                'remaining organization budget': 'Solde du budget institutionnel',
                'overall budget for users': 'Total attribué aux utilisateurs',
                'user': 'Utilisateur',
                'changed by user': 'Actualisé par l’utilisateur',
                'action': 'Action',
                'action sum': 'Montant d’action',
                'remaining user credit': 'Solde du crédit utilisateur',
                'remaining organization credit': 'Solde du crédit organisation',
                'user credit update': 'Actualisation du crédit utilisateur',
                'credit update': 'Actualisation du crédit',
                'data update': 'Actualisation des données',
                'set as credit customer': 'Marqué comme client à crédit',
                'unset credit customer': 'Rétiré du client à crédit',
                'order canceled in backend': 'Commande annulée dans le backend',
                'order changed in backend': 'Commande changée dans le backend',
                'order renewed in backend': 'Commande renouvelée dans le backend',
                'user checkout': 'Paiement utilisateur',
                'user order edit': 'Modifier la commande',
                'collection app edit': 'Modifier la commande dans l’application',
                'collection app canceled': 'Commande annulée dans l’application',
                'collection app finished': 'Commande préparée',
                'changed by pos': 'MAJ par la caisse',
                'order refunded': 'Commande remboursée',
                'remove selection': 'Effacer la sélection',

                'cancel order': 'Annuler la commande',
                'are you sure you want to cancel this order': 'Êtes-vous sûr de vouloir annuler la commande ?',
                'order #': 'Numéro de commande',
                'keep the order': 'Garder la commande',
                'your order was cancelled': 'Commande annulée',
                'this order will not be charged': 'Cette commande ne sera pas facturée',
                'a confirmation email was sent to {email}': 'Vous recevrez un email de confirmation à {email}',
                'no available deliveries in the upcoming days': 'Il n’y a plus de livraisons libres dans les prochains jours',

                'this user may already be on the system': 'Cet utilisateur est déjà existant',
                'please try to login': 'Vous pouvez essayer de vous connecter.',
                'your account has not yet been verified via email - please verify by clicking on the link sent to your email inbox': 'Votre compte n’a pas encore été vérifié, veuillez le vérifier en cliquant sur le lien envoyé par mail.',
				'send again': 'Envoyer à nouveau',
				'didn\'t receive an email': 'Email non reçu',
				'a verification email will be sent to your email box. the link is valid for {link_ttl} minutes': 'Un lien de vérification vous a été envoyé par mail. Le lien est valide {link_ttl} minutes',
				'your account requires activation, email with activation link have been sent to your email. the link is valid for {link_ttl} minutes': 'Un lien de validation vous a été envoyé par email, veuillez cliquer dessus pour activer votre compte. le lien est valide pendant {link_ttl} minutes',
                'your account requires activation.': 'Votre compte doit être activé.',
                'email with activation link have been sent to your email.': 'Un lien d’activation vient d’être envoyé sur votre e-mail.',
                'the link is valid for {link_ttl} minutes': 'Le lien est valide durant {link_ttl} minutes',
				'website login': 'Me connecter',
				'send a new link': 'Nouveau lien',
				'sorry, this link has expired': 'Le lien a expiré',
				'a new link can be sent': 'Un nouveau lien peut-être envoyé.',
				'you have successfully registered for the site': 'Votre compte a bien été créé',

				'we use cookies to improve your experience': 'Nous utilisons les cookies pour améliorer votre expérience',
				'we use cookies or other trackers to facilitate the use of the site, improve the performance and security of the site, personalize the content offered, including personalized advertising content, and compile traffic and navigation statistics. some cookies, other than functional and security cookies, require your consent to be placed.': 'Nous utilisons des cookies ou autres pisteurs pour faciliter l’utilisation du site, améliorer les performances et la sécurité du site, personnaliser le contenu proposé, y compris un contenu publicitaire personnalisé et accumuler des statistiques sur le trafic et la navigation. Certains cookies, autres que des cookies de fonctionnement et de sécurité, exigent votre consentement pour être placés.',
				'accept': 'Accepter',
				'accept all cookies': 'Accepter tous les cookies',
				'setup my cookies': 'Configurer mes cookies',
				'managing cookies': 'Cookies de gestion',
				'these cookies allow you to benefit from the services offered by the site, to optimize its use and personalization (in particular personalization of offers and advertisements) depending on your profile.': 'Ces cookies vous permettent de bénéficier des services proposés par le site, d’optimiser son utilisation et sa personnalisation (en particulier la personnalisation d’offres et de publicités) en fonction de votre profil.',
				'marketing': 'Marketing',
				'marketing cookies are used to improve the relevance of the ads you see and to make sure that you do not see the same ad multiple times.': 'Les cookies de marketing sont utilisés pour améliorer la pertinence des annonces publicitaires que vous voyez et pour s’assurer que vous ne voyez pas la même publicité plusieurs fois.',
				'additional features': 'Autres fonctionnalités',
				'these cookies make it possible to activate additional features on the site, for example to facilitate your purchases.': 'Ces cookies permettent d\'activer d\'autres fonctionnalités sur le site, par exemple pour faciliter vos achats.',
				'audience measurement': 'Mesure d\'audience',
				'these cookies allow us to obtain navigation statistics on our site. we use this data to identify possible malfunctions of the site, to improve the site, for marketing purposes, and in order to personalize your experience on the site.': 'Ces cookies nous permettent d’obtenir des statistiques de navigation sur notre site. Nous utilisons ces données pour identifier des dysfonctionnements éventuels du site, à des fins de marketing et pour personnaliser votre expérience sur le site.',
				'google analytics': 'Google Analytics',
				'these cookies allow us to obtain statistics on the number of visits to our site (e.g., number of visits, most viewed pages, etc.). we use this data to understand our users better, check the performance of our content, get insights, and identify possible malfunctions of the site to make improvements.': 'Ces cookies nous permettent d’obtenir des statistiques sur le nombre de visites de notre site (par ex. nombre de visites, pages les plus consultées, etc.). Nous utilisons ces données pour mieux cerner nos utilisateurs, vérifier les performances de notre contenu, recueillir des informations et identifier des dysfonctionnements éventuels du site pour procéder à des améliorations.',
				'functional (not configurable)': 'Fonctionnels (non configurables)',
				'these cookies allow you to remember your choices and preferences on your account and to provide features that facilitate the use of the site. they are essential for the proper functioning of the site and are strictly necessary for the provision of a service that you have expressly requested.': 'Ces cookies vous permettent de vous souvenir de vos choix et préférences sur votre compte et de délivrer des fonctionnalités facilitant l’utilisation du site. Ils sont essentiels pour le bon fonctionnement du site et sont nécessaires uniquement pour vous délivrer un service que vous avez expressément demandé.',

				'user products list not found': 'La liste des produits utilisateur introuvable',
                'user products list is disabled for this retailer': 'La liste des produits utilisateur est désactivée pour ce détaillant',
                'my lists': 'Mes commandes et listes',
                'my products': 'Mes produits',
                'my products list': 'Ma liste de produits',
                'the products i usually purchase': 'Ma liste de produits',
                'your time is precious': 'Votre temps est précieux',
                'we have prepared a list of your personal products': 'Nous avons préparé une liste de vos produits habituels',
                'we have prepared a list of products you usually purchase': 'Nous avons préparé une liste de vos produits habituels',
                'add products from the list': 'Ajouter des produits de la liste',
                'in cart': 'Dans le chariot',
                'sitemap': 'Plan du site',
                'pages': 'Pages',
                'the site is active only for organization users': 'Le site est ouvert uniquement aux membres de l\'entreprise',
                'please use your e-mail address that was approved by the organization': 'Veuillez utiliser une adresse mail appartenant à l\'entreprise',
                'sorry, this website is for organization users only': 'Désolé, le site est ouvert uniquement aux membres de l\'entreprise',
                'you user is not assigned to an organization': 'Votre compte utilisateur ne fait pas parti de l\'entreprise',
                'payment information': 'Infos paiement',
                'replaced with': 'Remplacement',
                'products marked with': 'Produits marqués de',
                'limited quantity': 'Quantité limitée',
                'tag are limited to': 'balise sont limités à',
                'items only.': 'articles seulement.',
                'the {product_count} products listed below will be': 'Les {product_count} produits ci-dessous seront',
                'removed from your cart': 'Supprimée de votre panier',
                'out of stock products': 'Produits en rupture de stock',
                'the items below will be removed from your cart': 'Les éléments suivants seront supprimé de votre panier',
                'back to cart': 'Retour au panier',
                'confirm': 'Confirmer',
                'expired':'expiré',
                '{product_count} out of stock products will not be supplied': '{product_count} des produits en rupture de stock ne seront pas fournis',
                '{coupons_count} expired coupons cannot be redeemed': '{coupons_count} coupons expirés ne peuvent pas être utilisés',
                'are you sure you want to remove all products from your shopping cart?': 'Voulez-vous vraiment supprimer tous les produits de votre panier?',
                'yes, clear cart': 'Oui, vider le panier',
                'clear cart?': 'Vider le panier?',
                'registration attempt has been blocked, please contact support': 'La tentative d\'enregistrement a été bloquée, veuillez contacter le support',
                'account has been suspended, please contact support': 'Compte suspendu, veuillez contacter l\'assistance',
                'delivery-fee-explain-default': 'Le montant final des frais de livraison est susceptible d’être mis à jour lors du paiement et d’être sujet à modification en raison de la zone/heure de livraison ou de toute remise supplémentaire. ',
                'delivery_type_1_fee': 'Frais livraison',
                'delivery_type_2_fee': 'Frais enlèvement',
                'includes_delivery_type_1_fee': 'Inclut les frais livraison',
                'includes_delivery_type_2_fee': 'Inclut les frais enlèvement',
                'not_includes_delivery_fee': '(n\'inclut pas les frais de livraison)',

                'your points': 'Vos points',
				'buy with points': 'Acheter avec des points',
				'almost expired': 'Presque Expiré',
				'coupon clipped': 'Coupon découpé',
                'add product': 'Ajouter un produit',
                'product in cart': 'Produit dans le panier',
                'clip coupon': 'Couper le coupon',
				'clipped externally': 'Clipsé en externe',
				'personal coupons': 'Coupons personnels',
				'may interest you': 'Susceptible de vous intéresser',
				'clip for {x} points': 'Epingler pour {x} points',
				'not enough points': 'Pas assez de points',
				'coupon expires today': 'Le coupon expire aujourd\'hui',
				'coupon expires in {x} days': 'Coupon expire dans {x} jours',
				'clip for': 'Epingler pour',
				'valid': 'Valable',
				'can be redeemed once': 'Peut être échangé une fois',
				'can be redeemed {x} times': 'Peut être échangé {x} fois',

                'last_minute_recommendations_headline': 'nous avons des recommandations sélectionnées spécialement pour vous.',
                'last_minute_recommendations_title_coupons': 'Des coupons qui peuvent vous faire économiser de l\'argent',
                'last_minute_recommendations_title_products_usually': 'Peut-être avez-vous oublié ces produits?',
                'last_minute_recommendations_title_products_complementary': 'Vous pourriez aussi aimer...',
                'last_minute_recommendations_title_specials_offers': 'Promotions à ne pas manquer',
                'last_minute_recommendations_title_products_offers': 'Vous ne voudrez pas manquer ces produits',

                'add_units_special': 'Ajoutez {remaining} unités pour profiter de la promo',
                'add_currency_special': 'Ajoutez {remaining} pour profiter de la promo',
                'add_measure_special': 'Ajoutez {remaining} {unit} pour profiter de la promo',
                'there are no available items for this promotion': 'Il n\'y a pas d\'articles disponibles pour cette promotion',
                'no_coupons_for_filter': 'Désolé<br>Nous n\'avons trouvé aucun coupon "{coupon_filter}" ',
                'search coupon': 'Buscar cupón',

                'add_units_coupon': 'Ajouter {remaining} articles pour obtenir la rduction',
                'add_currency_coupon': 'Ajouter {remaining} articles pour obtenir la rduction',
                'add_measure_coupon': 'Ajouter {remaining} {unit} articles pour obtenir la rduction',
                'add_units_cart_coupon': 'Ajouter {remaining} articles',
                'add_unit_cart_coupon': 'Ajouter un article',
                'add_currency_cart_coupon': 'Ajouter {remaining}',
                'add_measure_cart_coupon': 'Ajouter {remaining} {unit}',
                'add_units_special_purchase': 'Ajouter {remaining} articles supplémentaires',
                'buy_units_total': 'Acheter {total} produits',
                'buy_unit_total': 'Acheter {total} produits',
                'buy_currency_total': 'Acheter pour {total}',
                'buy_measure_total': 'Acheter {total} {unit}',

                'time slot unavailable': 'Créneau de livraison indisponible',
                'we\'re sorry, this time slot is no longer available. please select a different time': 'Nous sommes désolés, ce créneau de livraison n\'est plus disponible. Veuillez choisir un',
                'return to schedule': 'créneau différent',

                'suggestions_dialog_header_1': 'Recommandation relative aux remplacements',
                'suggestions_dialog_header_2': 'veuillez choisir un autre article parmi notre gamme de produits similaires',
                'suggestions_dialog_header_3': 'En rupture de stock',
                'suggestions_dialog_header_4': 'Recommandation relative aux remplacements',
                'ok, continue' : 'Ok, CONTINUER',
                'change chosen time': 'modifier la date de livraison',
                'hover description for cvv': 'Le numéro CVV (""Card Verification Value"") figurant sur les cartes de crédit ou de débit est un numéro à trois chiffres inscrit sur les cartes de crédit et de débit des marques VISA, Mastercard et Discover.  Les cartes de crédit ou de débit de la marque American Express comportent un code numérique à 4 chiffres.',
                'enter cvv number': '',
                'select pickup time': 'sélectionner l\'heure de prise en charge',
                'select delivery time': 'sélectionner le délai de livraison',
				'please notice, the expiration of these {count} {type} will not be valid for this chosen time': 'Veuillez noter que l\'expiration de ces {count} {type} ne sera pas valide pour l\'instant choisi',
				'the coupon displayed below, will not be valid for this chosen time': 'Le coupon affiché ci-dessous ne sera pas valide pour l\'instant choisi',
				'these {count} coupons will not be valid for this chosen time': 'Ces {count} coupons ne seront pas valides pour l\'instant choisi',
                'please notice, the coupon displayed below, will not be valid for this chosen time': 'Veuillez noter que le coupon affiché ci-dessous ne sera pas valide pour l\'instant choisi',
				'please notice, the special displayed below, will not be valid for this chosen time': 'Veuillez noter que la promotion spéciale affichée ci-dessous ne sera pas valide pour l\'instant choisi',
                'price does not include bottle deposit': 'La consigne n\'est pas incluse dans le prix',
                'price includes bottle deposit': 'La consigne est incluse dans le prix',
                'gift_card_error_message': 'Impossible de traiter votre CARTE-CADEAU, veuillez réessayer ou essayer une autre carte',
                'wrong_gift_card_amount_error': 'Le montant dépasse la valeur du panier',
                'amount_exceeds_balance': 'Montant supérieur au solde',
                "sorry, currently we don't deliver to this address for further inquiries call to the customer service.": "Désolés, nous ne livrons pas à cette adresse actuellement. Pour plus d'informations, veuillez vous adresser au service à la clientèle.",
                'gg_map_dialog_title': 'Choisissez une adresse',
                'gg_map_dialog_hint': 'Nous avons du mal à déterminer votre position exacte. Veillez à bien positionner la punaise avec une grande précision. Cela nous aidera à déterminer votre adresse exacte',
                'gg_map_alert_need_location': "Il est nécessaire d'activer l'autorisation d'accès à votre localisation afin de pouvoir continuer à sélectionner Google Maps",
                'gg_map_alert_error': "Il y a quelques problèmes lors de l'affichage de Google Maps.",
                'gg_map_alert_unsupport': "La géolocalisation n'est pas prise en charge par ce navigateur.",
                'autocomplete_adrs_didnt_find': 'Vous n\'avez pas trouvé votre adresse?',
                'autocomplete_adrs_choose_location': 'Choisissez votre localisation sur la carte',
                'sorry, your city was not found. please select an address from auto-complete suggestions or pin your address using the google maps option.': "Désolé, votre ville n'a pas été trouvée. Veuillez sélectionner une adresse parmi les suggestions de saisie automatique ou épinglez votre adresse à l'aide de l'option Google Maps.",
                'sorry, your address was not found. please select an address from auto-complete suggestions or pin your address using the google maps option.': "Désolé, votre adresse n'a pas été trouvée. Veuillez sélectionner une adresse parmi les suggestions de saisie automatique ou épinglez votre adresse à l'aide de l'option Google Maps.",
                'sorry, your zip code was not found. please select an address from auto-complete suggestions or pin your address using the google maps option.': 'Désolé, votre code ZIP est introuvable. Veuillez sélectionner une adresse à partir des suggestions de l\'auto-complétion ou épingler votre adresse en utilisant la fonctionnalité Google Maps.',
                'country_of_origin_title': 'Pays d’origine',
                'origin_country_legal_text': 'Veuillez noter que bien que le pays d\'origine indiqué soit exact au moment de la commande, le véritable pays d\'origine du produit livré peut varier en fonction du stock disponible au point de collecte lors de la livraison',
                'after package deduction': 'Après déduction du colis',
                'country: required error': 'Veuillez entrer un pays',
                'january': 'Janvier',
                'february': 'Février',
                'march': 'Mars',
                'april': 'Avril',
                'may': 'Peut',
                'june': 'Juin',
                'july': 'Juillet',
                'august': 'Août',
                'september': 'Septembre',
                'october': 'Octobre',
                'november': 'Novembre',
                'december': 'Décembre',
                'redeemed': 'utilisé',
                'points gained': 'Points gagnés',
                'source': 'Source',
                'when': 'Date',
                'retrieving data. wait a few seconds and try to cut or copy again': "Cette adresse est-elle correcte ? Vous pouvez la modifier et l'améliorer",
                'is this address correct? you can now edit and refine it': "Cette adresse est-elle correcte ? Vous pouvez la modifier et l'améliorer",
                'edit your address': "Modifier l'adresse",
                'searchtext_storename_placeholder': '{Nom du magasin}',
                'contact_us': 'Nous contacter',
                'how_can_we_help_you_today': 'Que pouvons nous faire pour vous aujourd’hui?',
                'leave_us_a_message': 'Laissez-nous votre message et nous vous répondrons dans les plus brefs délais.',
                'fullname': 'Nom complet',
                'phone': 'Numéro de téléphone',
                'email': 'E-mail',
                'message': 'Que pouvons-nous faire pour vous ?',
                'send': 'Envoyer',
                'your_message_has_been_sent': 'Votre message a bien été envoyé.',
                'thank_you_for_contact_us': 'Merci de nous contacter',
                'balance to be paid': 'Solde à payer',
                'redeem': 'Rembourser',
                'please save the voucher or gift card until you receive your order and are charged': "Veuillez conserver le bon ou la carte cadeau jusqu'à ce que vous receviez votre commande et que celle-ci soit facturée",
                'balance': 'Solde',
                'choose gift card/s': 'Sélectionnez une ou des CARTES CADEAUX',
                'add gift card': 'Ajouter une carte cadeau',
                'redeem gift card & discount cards': 'Pour échanger une CARTE CADEAU',
                'card number': 'numéro de carte',
                'please enter the card number to check the balance': 'Veuillez saisir le numéro de la carte pour vérifier le solde',
	            'customer support phone number': 'Numéro de téléphone du service clientèle',
                'complete_and_proceed_to_checkout': 'Compléter et passer à la caisse',
                'please notice, the following products are not available on this chosen time': 'Veuillez noter que les produits suivants ne sont pas disponibles à la date sélectionnée',
                'the following products are not available on this chosen time': 'Veuillez noter que les produits suivants ne sont pas disponibles à la date sélectionnée',
                'if you continue, they will be removed from your cart': 'Si vous cliquez sur le bouton "Continuer", ils seront retirés de votre panier',
                'verification challenge expired. check the checkbox again.': 'La validation a expiré. Cochez à nouveau la case.',
                'result for “{title}”': 'Résultat de “{title}”',
                'find a product': 'Trouver un produit',
                'brand Results:': 'Résultats selon la marque :',
				'please enter cvv to continue': 'Veuillez saisir le CVV pour continuer',
                'checkout is opened on another screen': 'La commande est ouverte sur un autre écran',
				'please wait, we are loading the products': 'Veuillez patienter pendant que nous chargeons les produits',
				'loading coupons, please wait...': 'Chargement des coupons, veuillez patienter...',
                'receipt': 'Reçu',
                'address_form_field_label_city': 'Ville',
                'address_form_field_label_address': 'Adresse',
                'address_form_field_label_zip_code': 'Code ZIP',
                'address_form_field_label_postal_code': 'Code Postal',
                'address_form_field_label_entrance': 'Entrée',
                'address_form_field_label_apartment_number': 'Numéro de chambre',
                'address_form_field_label_building_code': 'Code du bâtiment',
                'address_form_field_label_state': 'État',
                'address_form_field_label_comments': 'Commentaires',
                'address_form_field_label_apartment_suite_unit': 'Appartement / Suite / Unité',
                'address_form_field_label_providence': 'Providence',
                'address_form_field_label_more_details': 'Plus de détails',
                
                'address_drop_pin_message_1': 'Emplacement enregistré avec succès. Modifiez si nécessaire.',
                'address_drop_pin_message_cancel': 'Supprimer l\'adresse épinglée',

                'autocomplete_city_not_found_non_droppin': 'Désolé, votre ville est introuvable. Veuillez sélectionner une adresse dans la liste ou contacter le service d\'assistance pour obtenir de l\'aide.',
                'autocomplete_address_not_found_non_droppin': 'Désolé, votre adresse est introuvable. Veuillez sélectionner une adresse dans la liste ou contacter le service d\'assistance pour obtenir de l\'aide.',
                'autocomplete_zip_code_not_found_non_droppin': 'Désolé, votre code ZIP est introuvable. Veuillez sélectionner une adresse dans la liste ou contacter le service d\'assistance pour obtenir de l\'aide.',    

                'address_form_field_error_city: required error': 'Veuillez entrer un Ville valide',
                'address_form_field_error_address: required error': 'Veuillez entrer un Adresse valide',
                'address_form_field_error_zip_code: required error': 'Veuillez entrer un Code ZIP valide',
                'address_form_field_error_postal_code: required error': 'Veuillez entrer un Code Postal valide',
                'address_form_field_error_entrance: required error': 'Veuillez entrer un Entrée valide',
                'address_form_field_error_apartment_number: required error': 'Veuillez entrer un Numéro de chambre valide',
                'address_form_field_error_building_code: required error': 'Veuillez entrer un Code du bâtiment valide',
                'address_form_field_error_state: required error': 'Veuillez entrer un État valide',
                'address_form_field_error_comments: required error': 'Veuillez entrer un Commentaires valide',
                'address_form_field_error_apartment_suite_unit: required error': 'Veuillez entrer un Appartement / Suite / Unité valide',
                'address_form_field_error_providence: required error': 'Veuillez entrer un Providence valide',
                'address_form_field_error_more_details: required error': 'Veuillez entrer un Plus de détails valide',
                
                'account_details_form_personal_details_title': 'Détails personnels',
                'account_details_form_personal_details_save_btn': 'Sauvegarder les données personnelles',
                'account_details_form_address_title': 'Adresse',
                'account_details_form_address_save_btn': 'Enregistrer l\'adresse',

                'api_error_message': 'Une erreur est survenue{error_message} (erreur {error_code}). veuillez réessayer dans quelques minutes. Si le problème persiste, veuillez contacter le service clientèle.',
                'payment error': 'Erreur de paiement',
                'error received': 'Erreur détectée',
                'please check you credit card details or contact your credit card provider': 'Veuillez vérifier les détails de votre carte de crédit ou contacter votre banque.',

                'pickup time:': 'Heure de retrait :',
                'pickup address:': 'Adresse de retrait :',
                'delivery time:': 'Délai de livraison :',
                'delivery address:': 'Adresse de livraison :',
                'on_day': '',
                'wish_to_change_delivery':'Vous souhaitez modifier le délai de livraison ?',
                'wish_to_change_pickup':'Vous souhaitez modifier le délai de livraison ?',
                'select date & time':'Choisir la date et l\'heure',
                'save & change delivery time':'Enregistrer et Changer la date de livraison',
                'save & change pickup time':'Enregistrer et Changer l’heure de retrait',
                'back to home page':'Retour à la page d\'accueil',
                'your order has been updated':'Votre commande a été mise à jour',

                'checkout_finish_last_update_time_delivery': 'Vous pouvez mettre à jour le délai de livraison de votre commande jusqu\'à',
				'checkout_finish_last_update_time_pickup': 'Vous pouvez mettre à jour l’heure de retrait de votre commande jusqu\'à',
				'forgot_sth_want_to_change_delivery': 'Vous souhaitez modifier le délai de livraison ?',
				'forgot_sth_want_to_change_pickup': 'Vous souhaitez modifier la date de retrait ?',

                'update_order_popup_title_def': 'Vous pouvez mettre à jour les articles de votre commande ou la date de {delivery method}.',
                'update_order_popup_order_item_def': 'mettre à jour les articles de la commande',
                'update_order_popup_delivery_time_def': 'mettre à jour le délai de livraison',
                'update_order_popup_pickup_time_def': 'mettre à jour l\'heure de retrait',
                'update_order_popup_cancel_def': 'Envie d\'annuler votre commande ? Cliquez ici pour l\'annuler  {cancel order}',
                'update_order_popup_lastest_time_def': 'Vous pouvez mettre à jour votre commande {delivery method} jusqu\'à {time calculate}.',
                'update_order_popup_err_cant_update_time': 'La mise à jour de votre {delivery method} date n\'est pas disponible',

                'irre_sales_collect_one': 'Ce {saleType} ne sera pas valable pour cette période de mise à jour et sera facturé à son prix normal',
                'irre_sales_collect_many': 'Ces {count} {saleType} ne seront pas acceptés pour cette nouvelle période et seront facturés au tarif habituel',
                'irre_sales_checkout_one': 'En mettant à jour cette commande, le {saleType} suivant ne sera plus valide et l\'article sera facturé à son prix normal',
                'irre_sales_checkout_many': 'Lors de la mise à jour de cette commande, les {count} {saleType} suivants ne seront plus valables et les articles seront facturés à leur prix normal',
                'irre_sales_oos_one': 'Ces produits sont actuellement indisponibles et seront retirés de votre panier',
                'irre_sales_oos_many': 'Ces produits sont actuellement indisponibles et seront retirés de votre panier',
                'irre_sales_btn_continue': 'ok, mise à jour',
                'irre_sales_btn_change_slot': 'changer {delivery method} la date',
                'irre_sales_btn_cancel_update': 'Annuler la modification',
                'thank you for your order!': 'Merci de votre commande !',

                "add your credit card": "Ajoutez votre carte de crédit",
                "payment methods": "Modes de paiement",
                "your credit cards": "Vos cartes de crédit",
                "add a credit card": "Ajouter une carte de crédit",
                "gift cards": "Cartes-cadeaux",
                "add gift cards": "Ajouter des cartes-cadeaux",
                "choose gift card/s": "Choisir une ou des CARTES-CADEAUX",
                "please enter the card number to check balance": "Veuillez saisir le numéro de la carte pour vérifier le solde",
                "{gift card name} details": "Détails de {nom de la carte cadeau}",
                "on the next screen, you can select your payment amount": "Sélectionnez le montant de votre paiement sur l'écran suivant.",
                "balance:": "Solde :",
                "please enter amount to pay": "Veuillez saisir le montant à payer",
                "amount exceeds balance": "Montant supérieur au solde",
                "please save the voucher or gift card until you receive your order and are charged.": "Veuillez conserver le bon ou la carte cadeau jusqu'à ce que vous receviez votre commande et que vous soyez débité.",
                "remove card": "Retirer la carte",
                "see more": "afficher plus",
                "show less": "Afficher moins",
                "amount exceeds shopping cart value": "Le montant dépasse la valeur du panier",
                'delivery_disclaimer_default_text':"La fenêtre de livraison ne peut être sélectionnée qu'au moment du paiement.",
                'missing premise or street number': 'No de maison obligatoire',
                'amount to pay': 'Montant à payer',
            };
        }]);

})(angular, app);
